import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { HiSpeakerWave } from "react-icons/hi2";

const ScrollingMessage = ({ linkHref }) => {
  const user_role = localStorage.getItem("user_role");
  const scrollingMessages = useSelector((state) => state.scrollingMessage.message);
  // console.log("scrolling message - " , scrollingMessages)

  // Check if there are no messages or user_role is "1"
  if (user_role === "1" || !scrollingMessages || scrollingMessages.length === 0) {
    return null; // Return null if user_role is "1", no scrolling message, or an empty array
  }

  const today = new Date();

  // Function to get the message color based on type and business_id
  const getMessageColor = (msg) => {
    // console.log(`Message: ${msg.message}, Type: ${msg.mesg_type}, Business ID: ${msg.business_id}`);
    if (msg.mesg_type === "Release") {
      return msg.business_id === "0" ? "#e11969" : "#e11969";
    } else if (msg.mesg_type === "Info") {
      return msg.business_id === "0" ? "black" : "red";
    }
    return "black"; // Default color
  };

  return (
    <>
      {scrollingMessages.map((msg) => {
        const startDate = new Date(msg.start_date);
        const endDate = new Date(msg.end_date);
        endDate.setHours(23, 59, 59, 999);

        // Conditionally render based on the date
        if (today < startDate || today > endDate) {
          return null; // Skip rendering if today is not between start and end dates
        }

        return (
          <div
            key={msg.id}
            className="w-[93%] mb-5 mt-5 bg-white text-black border-l-4 border-pink-500 overflow-hidden shadow-lg py-3 mr-2.5 relative rounded-lg"
            style={{ boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)" }}
          >
            <div className="absolute top-0 left-0 bottom-0 border-l-4 border-pink-500 -ml-1 rounded-l-lg"></div>
            <div className="flex items-center whitespace-nowrap">
              <span className="mr-2 ml-3 text-2xl text-pink-500">
                <HiSpeakerWave />
              </span>
              <marquee
                className="scrolling-message flex-grow"
                behavior="scroll"
                direction="left"
                scrollamount="5"
                onMouseOver={(e) => { e.currentTarget.stop(); }}
                onMouseOut={(e) => { e.currentTarget.start(); }}
              >
                <b>
                  {`Alert (${msg.mesg_type}) : `}
                  <a
                    href={linkHref}
                    className="hover:underline"
                    style={{ color: getMessageColor(msg) }}
                  >
                    {msg.message}
                  </a>
                </b>
              </marquee>
            </div>
          </div>
        );
      })}
    </>
  );
};

ScrollingMessage.propTypes = {
  linkHref: PropTypes.string.isRequired,
};

export default ScrollingMessage;
