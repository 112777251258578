import React, { useState } from "react";
import { routes } from "../components/constants/routes.constants";
import { HiGift, HiOutlineGift, HiOutlineSquares2X2 } from "react-icons/hi2";
import PropTypes from "prop-types";
import { Navbar } from "flowbite-react";
import {  Menu,  MenuHandler,  MenuList,  MenuItem,  Avatar,  Typography,} from "@material-tailwind/react";
import { FaMoneyBillAlt, FaProductHunt, FaThLarge, FaUser, FaUserSecret } from "react-icons/fa";
import { CgGift } from "react-icons/cg";
import NotificationBell from "./pages/Notification";
import { IoBookOutline, IoBookSharp, IoSettings, IoSettingsOutline, IoSettingsSharp } from "react-icons/io5";
import { BiSolidUser } from "react-icons/bi";
import { AiFillWindows, AiOutlineWindows } from "react-icons/ai";
import { Link, useNavigate , useLocation, Routes, useParams} from "react-router-dom";
import { BsBell, BsFillBellFill } from "react-icons/bs";
import { useEffect } from "react";
import { BiSolidBusiness } from "react-icons/bi";
import { matchPath } from 'react-router-dom';
import axios from "axios";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
import { useUser } from "./pages/UserContext";
import { RiSortAsc } from "react-icons/ri";
import { ImPriceTags } from "react-icons/im";
import ReduceCapacityOutlinedIcon from '@mui/icons-material/ReduceCapacityOutlined';
import { RiTicketLine, RiTicketFill } from "react-icons/ri";  // Ticket icons (outline and fill)
import { IoCloudUploadOutline, IoCloudUpload } from "react-icons/io5";   // Substitute release icons (outline and fill)
import { RiLinksLine, RiLinksFill } from "react-icons/ri";       // Link icons (outline and fill)
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Assessment, InsertDriveFile } from '@mui/icons-material';
import { Assignment } from '@mui/icons-material';
import spyicon from '../../src/assets/images/spyicon.png'
import spyiconfill from '../../src/assets/images/spyiconfill.png'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import {

  MdPersonOutline,
} from "react-icons/md";

import config  from "../config/config"

function capitalizeFirstLetter(email) {
  return email.charAt(0).toUpperCase() + email.slice(1).toLowerCase();
}

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
function Header({ toggleSidebar , handleSignOut }) {
  // console.log('Traf name:', user.contact_name);
  // console.log('Traf email:', user.contact_email);
  const contact_name = localStorage.getItem("userName")
  const contact_email = localStorage.getItem("userEmail")
  // console.log("userName :" , contact_name , "userEmail:" , contact_email);
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const [isBookHovered, setIsBookHovered] = useState(false);
  const [isTicketHovered, setIsTicketHovered] = useState(false);
  const [isReleaseHovered, setIsReleaseHovered] = useState(false);
  const [isLinkHovered, setIsLinkHovered] = useState(false);

  const [isGiftHovered, setIsGiftHovered] = useState(false);
  const [isWindowsHovered, setIsWindowsHovered] = useState(false);
  const [isBellHovered , setIsBellHovered] = useState(false);
  const [isSpyHovered , setIsSpyHovered] = useState(false);
  const [isSpy , setIsSpy] = useState(false);


  const [selectedRows, setSelectedRows] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [businessType , setBusinessType] = useState("");
  const [userRole, setUserRole] = useState('');
  const [scrollingMessage ,setScrollingMessage] = useState('')

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const isCurrentRoute = (route) => location.pathname === route;
  const handleSettingIconClick = () => {
    setShowMenu(true);
  };

  const handleMenuItemClick = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const storedUserRole = localStorage.getItem("user_role");
    setUserRole(storedUserRole); 
  }, []); 

  // console.log("user_role" ,userRole);

  const handleMouseEnter = (e) => {
    e.stopPropagation(); 
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterSetting = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveSetting = () => {
    setIsHovered(false);
  };

  const handleBookMouseEnter =()=>{
    setIsBookHovered(true)
  }
  const handleBookMouseLeave =()=>{
    setIsBookHovered(false)
  }
  
  const handleTicketMouseEnter =()=>{
    setIsTicketHovered(true)
    setIsReleaseHovered(false)
    setIsLinkHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
    setIsBellHovered(false)
  }
  const handleTicketMouseLeave =()=>{
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsLinkHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
    setIsBellHovered(false)
  }
  const handleReleaseMouseEnter =()=>{
    setIsReleaseHovered(true)
    setIsTicketHovered(false)
    setIsLinkHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
    setIsBellHovered(false)
  }
  const handleReleaseMouseLeave =()=>{
    setIsReleaseHovered(false)
    setIsTicketHovered(false)
    setIsLinkHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
    setIsBellHovered(false)
  }
  const handleLinkMouseEnter =()=>{
    setIsLinkHovered(true)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
    setIsBellHovered(false)
  }
  const handleLinkMouseLeave =()=>{
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
    setIsBellHovered(false)
  }
  const handleGiftMouseEnter =()=>{
    setIsGiftHovered(true)
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsBellHovered(false)
  }
  const handleGiftMouseLeave =()=>{
    setIsGiftHovered(false)
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsBellHovered(false)
  }

  const handleWindowsMouseEnter=()=>{
    setIsWindowsHovered(true)
  }
  const handleWindowsMouseLeave=()=>{
    setIsWindowsHovered(false)
  }
  const handleBellMouseEnter =()=>{
    setIsBellHovered(true);
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
  }
  const handleBellMouseLeave=()=>{
    setIsBellHovered(false);
    setIsLinkHovered(false)
    setIsTicketHovered(false)
    setIsReleaseHovered(false)
    setIsBookHovered(false)
    setIsGiftHovered(false)
  }
  const handlespyMouseEnter = () => setIsSpyHovered(true);
  const handlespyMouseLeave = () => setIsSpyHovered(false);

  // const {businessId}  = useParams()
  // console.log(businessId);

  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  // console.log("user role is" , user_role);
  // console.log("bus id is " , business_id);
  // console.log("user id is " , user_id);
  
  const [businessData , setBusinessData] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
    .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        // console.log("response " , response);
        // console.log("Business Data:", response.data.business_data);
        // console.log("Business type:", response.data.business_data[0].business_type);
        // console.log("Business Data length :" , response.data.business_data.length);
        setBusinessData(response.data.business_data);
        setBusinessType(response.data.business_data[0].business_type)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);
       ;
      });
  }, []);

  // useEffect(()=> {
  //   const fetchData = async()=>{
  //     try {
  //       const response  = await axios.get(`${baseURL}${btoa("scrollingmsg/fetchscrollmsgforbusinesses")}/${btoa(business_id)}`)
  //       setScrollingMessage(response.data.scrollmsg_data[0].message)
  //       console.log("scrolling message" , response);
  //     } catch (error) {
  //       console.log("error fetching scrolling messages" , error);
  //     }   
  //   }
  //   fetchData()
  // },[])
  
  const businessDataLength = businessData.length
  // console.log("length is :" , businessDataLength);
 
  useEffect(() => {
    setIsSetting(location.pathname === "/products" || location.pathname === "/items" || location.pathname === "/business"
    || location.pathname === "/products/productform" || location.pathname === "/items/itemform" || location.pathname === "/business"
    || location.pathname === "/businessdashboard"  || location.pathname === "/viewbusinessusers" ||
    location.pathname === "/itemprice" || location.pathname === "/viewBusiness" || location.pathname === "/setup/viewitem" ||
    location.pathname.includes("/customers") || location.pathname.includes("/vendors") || location.pathname.includes("/viewvendor")
    // || location.pathname === `/setup/viewproduct/${productId}`
     ||  location.pathname.startsWith("/setup/viewitem/") 
    );
  }, [location.pathname]);
 useEffect(()=> {
if(location.pathname === "/AuditLog" ){ 
  
  setIsSpy(true)

} else{
  setIsSpy(false)
};
 }, [location.pathname])

  // function formatEmail(email) {
  //   email = email.replace(',', '.');
  //   const [localPart, domain] = email.split('@');
  //   if (!localPart || !domain) { return email; }
  //   const firstChar = localPart.charAt(0);
  //   return `${firstChar}**@${domain}`;
  // }
  const formatEmail = (email) => {
    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + "*****";
    const domainTLD = domain.slice(domain.lastIndexOf('.'));
    return `${maskedLocalPart}${domainTLD}`;
  };

  return (
    <>
      <header className="bg-[white] shadow h-14 flex  items-center justify-between  px-4 sticky top-0 z-50">
        <div>
          <HiOutlineSquares2X2
            className="w-6 h-6 cursor-pointer hover:text-[#3479E0]"
            onClick={toggleSidebar}
          />
        </div>

        <div className="flex items-center space-x-2 mr-4">
            <div
              onMouseEnter={handleTicketMouseEnter}
              onMouseLeave={handleTicketMouseLeave}
              style={{
                backgroundColor: isTicketHovered ? "#cddbfe" : "transparent",
                color: isTicketHovered ? "#5850ec" : "gray",
                display: "inline-block",
                padding: "10px",
                borderRadius: "50%",
                transition: "all 0.3s ease",
              
              }}
            >
              {isTicketHovered ? (
                <RiTicketFill size={20} />
              ) : (
                <RiTicketLine size={20} />
              )}
            </div>
            <div
              onMouseEnter={handleReleaseMouseEnter}
              onMouseLeave={handleReleaseMouseLeave}
              style={{
                backgroundColor: isReleaseHovered ? "#cddbfe" : "transparent",
                color: isReleaseHovered ? "#00BFFF" : "gray",
                display: "inline-block",
                padding: "10px",
                borderRadius: "50%",
                transition: "all 0.3s ease",
              
              }}
            >
              {isReleaseHovered ? (
                <IoCloudUpload size={20}/>
              ) : (
                <IoCloudUploadOutline size={20} />
              )}
            </div> 
            <div
              onMouseEnter={handleLinkMouseEnter}
              onMouseLeave={handleLinkMouseLeave}
              style={{
                backgroundColor: isLinkHovered ? "#cddbfe" : "transparent",
                color: isLinkHovered ? "#5850ec" : "gray",
                display: "inline-block",
                padding: "10px",
                borderRadius: "50%",
                transition: "all 0.3s ease",
              
              }}
            >
              {isLinkHovered ? (
                <RiLinksLine size={20} />
              ) : (
                <RiLinksFill size={20} />
              )}
            </div>
            <div
              onMouseEnter={handleBookMouseEnter}
              onMouseLeave={handleBookMouseLeave}
              style={{
                backgroundColor: isBookHovered ? "#cddbfe" : "transparent",
                color: isBookHovered ? "#5850ec" : "gray",
                display: "inline-block",
                padding: "10px",
                borderRadius: "50%",
                transition: "all 0.3s ease",
              
              }}
            >
              {isBookHovered ? (
                <IoBookSharp size={20} />
              ) : (
                <IoBookOutline size={20} />
              )}
            </div>
          {/* <CgGift
            className="text-gray-400 hover:text-purple-600 hover:bg-purple-200 rounded-full p-2 text-blue-600"
            size={37}
          /> */}

            <div
                  onMouseEnter={handleGiftMouseEnter}
                  onMouseLeave={handleGiftMouseLeave}
                  style={{
                    backgroundColor: isGiftHovered ? "#dcd7fe" : "transparent",
                    color: isGiftHovered ? "#7e3af2" : "gray",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                
                  }}
                >
                  {isGiftHovered ? (
                    <HiGift  size={20} />
                  ) : (
                    <HiOutlineGift size={20} />
                  )}
            </div>

            <Menu>
              <MenuHandler>
                <div>
                  <div
                    onClick={handleSettingIconClick}
                    onMouseEnter={handleMouseEnterSetting}
                    onMouseLeave={handleMouseLeaveSetting}
                    style={{
                      backgroundColor: (isHovered || isSetting) ? "#C3DDFD" : "transparent",
                      color: (isHovered || isSetting) ? "#1C64F2" : "gray",
                      display: "inline-block",
                      padding: "10px",
                      borderRadius: "50%",
                      transition: "all 0.3s ease",
                      marginTop: "6px"
                    }}
                  >
                    {isHovered || isSetting ? <IoSettings size={20} /> : <IoSettingsOutline size={20} />}
                  </div>
                </div>
              </MenuHandler>

              <div
                  onMouseEnter={handlespyMouseEnter}
                  onMouseLeave={handlespyMouseLeave}
                  onClick={() => navigate('/AuditLog')}

                  style={{
                    backgroundColor: isSpyHovered || isSpy ? "#dcd7fe" : "transparent",
                    color: isSpyHovered || isSpy ? "#7e3af2" : "gray",
                    display: "inline-block",
                    padding: "8px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                
                  }}
                >
                  {isSpyHovered || isSpy ? (
                    <FactCheckIcon  size={20} />
                  ) : (
                    <FactCheckOutlinedIcon size={20} />
                  )}
            </div>
              {userRole !== '1' && user_role !== '5' && (
                <MenuList className={`z-50 ${showMenu ? 'visible' : 'hidden'}`}>
                  {/* <MenuItem
                    className={`flex items-center gap-2 ${isCurrentRoute('/viewBusiness') || (businessData.length > 0 && userRole === '3' && isCurrentRoute('/viewbusinessusers')) ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                    onClick={() => {
                      if (userRole === '2') {
                        navigate("/viewBusiness");
                      } else if (businessData.length > 0 && userRole === '3') {
                        navigate("/viewbusinessusers");
                      } else {
                        navigate("/business");
                      }
                      handleMenuItemClick();
                    }}
                    style={{
                      backgroundColor: isCurrentRoute('/viewBusiness') || (businessData.length > 0 && userRole === '3' && isCurrentRoute('/viewbusinessusers')) ? '#E5E7EB' : 'transparent',
                      color: 'inherit'
                    }}
                  >
                    <MdOutlineBusinessCenter size={23} />
                    <Typography variant="small" className="font-normal">
                      Business
                    </Typography>
                  </MenuItem> */}

                  {userRole !== '2' && (
                    <>
                      {businessType === "1" && (
                        <>
                          <MenuItem
                            className={`flex items-center gap-2 ${isCurrentRoute('/products') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                            onClick={() => { navigate(`/products`); handleMenuItemClick(); }}
                            style={{
                              backgroundColor: isCurrentRoute('/products') ? '#E5E7EB' : 'transparent',
                              color: 'inherit'
                            }}
                          >
                            <FaProductHunt size={20} />
                            <Typography variant="small" className="font-normal">
                              Products
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            className={`flex items-center gap-2 ${isCurrentRoute('/itemsdashboard') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                            onClick={() => { navigate(`/itemsdashboard`); handleMenuItemClick(); }}
                            style={{
                              backgroundColor: isCurrentRoute('/itemsdashboard') ? '#E5E7EB' : 'transparent',
                              color: 'inherit'
                            }}
                          >
                            <FaMoneyBillAlt size={20} />
                            <Typography variant="small" className="font-normal">
                              Items
                            </Typography>
                          </MenuItem>
                        </>
                      )}

                      {businessType === "2" && (
                        <MenuItem
                          className={`flex items-center gap-2 ${isCurrentRoute('/vendors') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                          onClick={() => { navigate(`/vendors`); handleMenuItemClick(); }}
                          style={{
                            backgroundColor: isCurrentRoute('/vendors') ? '#E5E7EB' : 'transparent',
                            color: 'inherit'
                          }}
                        >
                          <ReduceCapacityOutlinedIcon size={20} />
                          <Typography variant="small" className="font-normal">
                            Vendors
                          </Typography>
                        </MenuItem>
                      )}

                      <MenuItem
                        className={`flex items-center gap-2 ${isCurrentRoute('/customers') ? 'bg-gray-200' : ''} hover:bg-gray-200`}
                        onClick={() => { navigate(`/customers`); handleMenuItemClick(); }}
                        style={{
                          backgroundColor: isCurrentRoute('/customers') ? '#E5E7EB' : 'transparent',
                          color: 'inherit'
                        }}
                      >
                        <HiUserGroup size={20} />
                        <Typography variant="small" className="font-normal">
                          Customers
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              )}
            </Menu>

          
          
            {/* <div
                  onMouseEnter={handleWindowsMouseEnter}
                  onMouseLeave={handleWindowsMouseLeave}
                  style={{
                    backgroundColor: isWindowsHovered ? "#e0f7fa" : "transparent",
                    color: isWindowsHovered ? "#00acc1" : "gray",
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                  
                  }}
                >
                  {isWindowsHovered ? (
                    <AiFillWindows  size={20} />
                  ) : (
                    <AiOutlineWindows size={20} />
                  )}
            </div> */}


      
          {/* <FaUserSecret
            className="text-gray-400 hover:text-orange-600  hover:bg-orange-200 rounded-full  p-2"
            size={37}
            onClick={()=> {navigate('/AuditLog')}}
          /> */}
          {/* <i class="fa-thin fa-user-secret" style={{ textShadow: '0 0 3px white', textStroke: '1px black'}} 
           onClick={() => navigate('/AuditLog')}></i> */}
           {/* {isSpyHovered ||isSpy  ?
          <FactCheckIcon 
  className="text-black hover:bg-gray-300 hover:text-black rounded-full p-2"
  size={37}
  onClick={() => navigate('/AuditLog')}
  // onMouseEnter={handlespyMouseEnter} 
  onMouseLeave={handlespyMouseLeave}
/> :

      <FactCheckOutlinedIcon 
      onMouseEnter={handlespyMouseEnter} 
      size={37}
      // onMouseLeave={handlespyMouseLeave}
      class="text-black hover:bg-gray-300 hover:text-black rounded-full p-2" onClick={()=> {navigate('/AuditLog')}}/>
    } */}

      {/* <img src={spyiconfill} alt="SMS Icon" style={{ width: '18px', height: '19px', marginLeft: '8px' }} onClick={()=> {navigate('/AuditLog')}}/> */}




       {user_role !== "1" && (   
          <div
            onMouseEnter={handleBellMouseEnter}
            onMouseLeave={handleBellMouseLeave}
            style={{
              backgroundColor: isBellHovered ? "#f8d1e8" : "transparent",
              color: isBellHovered ? "#d61f69" : "gray",
              display: "inline-block",
              padding: "10px",
              borderRadius: "50%",
              transition: "all 0.3s ease",
            }}
          >
            {isBellHovered ? (
              <BsFillBellFill size={20} />
            ) : (
              <BsBell size={20} />
            )}
          </div> )}


          <Menu>
            <MenuHandler>
              <div className="cursor-pointer">
                <BiSolidUser
                  className="text-green-500  hover:bg-green-100 rounded-full  p-2"
                  size={39}
                />
              </div>
            </MenuHandler>
            <MenuList className="z-50 w-64">
            <MenuItem className="flex items-center gap-2 py-1 px-4 rounded-md bg-balck-100 dark:hover:bg-gray-800 transition-colors">
  {/* Icon */}
  <MdPersonOutline className="text-gray-700 dark:text-gray-300 w-6 h-6" />

  {/* Contact Info */}
  <div className="text-gray-900 dark:text-white">
    <div className="text-sm font-medium">{contact_name}</div>
    {/* Uncomment to display email */}
    {/* <div className="text-xs font-light text-gray-500 truncate">{capitalizeFirstLetter(formatEmail(contact_email))}</div> */}
  </div>
</MenuItem>


              <hr className="my-2 border-blue-gray-50" />
              {userRole !== '1' && user_role !== '5' && (
              <MenuItem className="flex items-center gap-3 py-2 px-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors"
                 onClick={() => {
                  if (userRole === '2') {
                    navigate("/viewBusiness");
                  } else if (businessData.length > 0 && userRole === '3') {
                    navigate("/viewbusinessusers");
                  } 
                  
                  else {
                    navigate("/business");
                  }
                  handleMenuItemClick();
                }}
                style={{
                  // backgroundColor: isCurrentRoute('/viewBusiness') || (businessData.length > 0 && userRole === '3' && isCurrentRoute('/viewbusinessusers')) ? '#E5E7EB' : '',
                  // color: 'inherit'
                }}>
  {/* User Icon */}
  <MdPersonOutline className="text-gray-700 dark:text-gray-300 w-6 h-6" />

  {/* Typography with User Profile Text */}
  <Typography variant="small" className="font-normal text-gray-900 dark:text-white">
    User Profile
    <br />
    <span style={{ fontSize: '12px', color: '#6b7280' }}>User Details and Credentials</span>
  </Typography>
</MenuItem>
              )}

            

            </MenuList>
          </Menu>
        </div>
      </header>
        
        {/* {userRole !== "1" && (
            <marquee
                    behavior="scroll"
                    direction="left"
                    scrollamount="5"
                    onMouseOver={(e) => e.target.stop()}
                    onMouseOut={(e) => e.target.start()}
                    style={{ color: 'red', whiteSpace: 'nowrap' }}
                >
            {scrollingMessage}
          </marquee> )} */}

      {/* <header className="bg-white h-14 mt-1 flex items-center justify-between px-4 sticky top-0 z-50">
          <marquee
              behavior="scroll"
              direction="left"
              scrollamount="5"
              onMouseOver={(e) => e.target.stop()}
              onMouseOut={(e) => e.target.start()}
              style={{ color: 'red', whiteSpace: 'nowrap' }}
          >
            {scrollingMessage}
          </marquee>
    </header> */}
    </>
  );
}

export default Header;
