import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // Purchase icon
import DescriptionIcon from '@mui/icons-material/Description'; // invoice icon

import StoreIcon from '@mui/icons-material/Store'; // Sales icon
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Price icon
import ReceiptIcon from '@mui/icons-material/Receipt'; // Accounting icon
import PercentIcon from '@mui/icons-material/Percent'; // Sales tax icon

import ScrollingMessage from "./ScrollingMessage";
import RocketIcon from '@mui/icons-material/Rocket'; // Using a rocket icon from Material UI
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PaidIcon from '@mui/icons-material/Paid';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Reports = () => {
  const navigate = useNavigate();

  return (
    <>
    <div className='ml-5 pageHeight' >
  <ScrollingMessage/>

  <div 
  className="flex justify-between items-center w-full  pt-4 mt-4 mb-5" 
  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '20px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}
>
  <h1 className="font-bold text-xl mb-4"> Report Dashboard</h1>
</div>
  

    <div className="container mb-5" style={{margin: '0px', marginBottom: '150px'}} >
    
      <Grid container spacing={4} justifyContent="start">
        {/* Purchase Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
       
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',
              }
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <ShoppingCartIcon sx={{ fontSize: 50, color: '#000000' }} />
                <Typography sx={{ color: '#000000', fontSize: '20px' }}>Purchases</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
       
       <Card
         sx={{
           borderRadius: '10px',
           boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
           backgroundColor: '#33adff',

           transition: 'transform 0.3s ease',
           '&:hover': {
             transform: 'scale(1.08)',
           }
         }}
         className="cursor-pointer bg-white rectangular-box p-3"
       >
         <CardContent>
           <Box sx={{ textAlign: 'center' }}>
             <PaidIcon sx={{ fontSize: 50, color: '#ffffff' }} />
             <Typography sx={{ color: '#ffffff', fontSize: '20px' }}>Expenses</Typography>
           </Box>
         </CardContent>
       </Card>
     </Grid>
        {/* Sales Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            onClick={() => navigate('/SalesReport')}
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <TrendingUpIcon sx={{ fontSize: 50, color: '#000000' }} />
                <Typography sx={{ color: '#000000', fontSize: '20px' }}>Sales</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              backgroundColor: '#33adff',

              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
            onClick={() => navigate('/invoiceReport')}

          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <ReceiptLongIcon sx={{ fontSize: 50, color: '#ffffff' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '20px' }}>Invoice</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
              {/* Accounting Card */}
              <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <ReceiptIcon sx={{ fontSize: 50, color: '#000000' }} />
                <Typography sx={{ color: '#000000', fontSize: '20px' }}>PriceLog</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
            
        {/* Price Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              backgroundColor: '#33adff',

              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <AttachMoneyIcon sx={{ fontSize: 50, color: '#ffffff' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '20px' }}>Billing</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
       
       <Card
         sx={{
           borderRadius: '10px',
           boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
           transition: 'transform 0.3s ease',
           '&:hover': {
             transform: 'scale(1.08)',
           }
         }}
         className="cursor-pointer bg-white rectangular-box p-3"
       >
         <CardContent>
           <Box sx={{ textAlign: 'center' }}>
             <TrendingDownIcon sx={{ fontSize: 50, color: '#000000' }} />
             <Typography sx={{ color: '#000000', fontSize: '20px' }}>Losses</Typography>
           </Box>
         </CardContent>
       </Card>
     </Grid>
     

          {/* <Grid item xs={12} sm={6} md={4} lg={2}>
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: '#33adff', // light blue color
      }}
    >
      <CardContent sx={{ textAlign: 'center', marginTop: '2px' }}>
        <ShoppingCartIcon sx={{ fontSize: 50, color: '#ffffff' }} />
      </CardContent>
      {/* Header with background color */}
      {/* <Box
        sx={{
          backgroundColor: '#ffffff', // darker blue
          padding: '20px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          textAlign: 'center',
          zIndex: 1,
        }}
      >
        <Typography variant="h6" sx={{ color: '#33adff' }}>
          TBD
        </Typography>
      </Box>
    </Card>
  </Grid> 
  */} 

  
      </Grid>
    </div>
    </div>

    </>
  );
};

export default Reports;
