
import React, { useState, useRef  } from "react";
import SideBar from "./components/pages/Sidebar";
import Header from "./components/Headerside";
import AppRoutes from "./AppRoutes";
import Footer from "./components/footer/Footer";
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TabsProvider } from "./components/pages/TabContext";
import LoginPage from "./components/pages/LoginPage";
import axios from "axios";
import { useEffect } from "react";
import SignUpPage from "./components/pages/SignUpPage";
import OtpPage from "./components/pages/OtpPage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate , useLocation } from "react-router-dom";
import ForgotPassword from "./components/pages/ForgotPassword";
import UserLoginPage from "./components/pages/UserLoginPage";
import config  from "../src/config/config"
import { useDispatch } from "react-redux";
import { setScrollingMessage } from "./store/scrollingMessageSlice";
import DeploymentPage from "./components/pages/DeploymentPage";
import CircularProgress from '@mui/material/CircularProgress';
import Toast from "./components/toasts/Toast";
import CustomerPortalPage from './components/CustomerPortal/CustomerPortalPage'
import CustomerOtpPage from './components/CustomerPortal/CustomerOtpPage'
// import useHideUrl from "./useHideUrl";

function App() {

  // useHideUrl();
  const dispatch = useDispatch()
  const [isCustomer, setIsCustomer] = useState(false); // State to manage customer or user

  const businessId = localStorage.getItem("business_id")
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [isOpen, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  const [userEmail , setUserEmail] = useState("")

  const [showOTP, setShowOTP] = useState(false);
   const [dummyOtp , setDummyOtp] = useState("")

  const [showSignUp , setShowSignUp] = useState(false)

  const [deploymentStatus, setDeploymentStatus] = useState(false);
  const [fromDateTime, setFromDateTime] = useState("");
  const [toDateTime, setToDateTime] = useState("");
  // const [scrollingMessage ,setScrollingMessage] = useState('')
  
  const navigate = useNavigate()
  const location = useLocation()

  const handleShowOTP = (status, isCust = false) => {
    setShowOTP(status);
    console.log("otp status : ",status);
    setIsCustomer(isCust)
  };

 
  const handleSignUpClick =()=>{
    setShowSignUp(!showSignUp)
  }

  const handleCancelClick=()=>{
    setShowSignUp(false)
   
  }
  const toggleSidebar = () => {
    setOpen(!isOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL}${btoa("deployment/fetchdeploymentime")}`);
        // console.log("deployment status", response);

        if (response.data.deployment_status) {
          const { from_dandt, to_dandt } = response.data.deployment_time[0];
          setDeploymentStatus(true);
          setFromDateTime(from_dandt);
          setToDateTime(to_dandt);
        } else {
          setDeploymentStatus(false);
        }
      } catch (error) {
        console.log("error fetching Deployment status", error);
        setDeploymentStatus(false); // Fallback to main content in case of error
      } finally {
        setLoading(false); // Set loading to false after fetch is complete
      }
    };

    fetchData();
  }, []);

 



  useEffect(()=> {
    const fetchData = async()=>{
      try {
        const response  = await axios.get(`${baseURL}${btoa("scrollingmsg/fetchscrollmsgforbusinesses")}/${btoa(businessId)}`)
        // setScrollingMessage(response.data.scrollmsg_data[0].message)
        console.log("scrolling message" , response)
        dispatch(setScrollingMessage(response.data));
        console.log("scrolling message for particular business" , response);
      } catch (error) { 
        console.log("error fetching scrolling messages" , error);
      }   
    }
    fetchData()
  },[])


  // useEffect(() => {
  //   if(process.env.NODE_ENV === "development"){
  //     window.history.replaceState({}, document.title, "/");
  //   }
  // }, [location]);

  // useEffect(()=>{
  //     alert(process.env.NODE_ENV)
  // },[])


  // useEffect(() => {

  //   if(window.location.hostname === "localhost"){
  //     window.location.hostname = "invoicefile.com"
  //   }
  // }, []);

  // useEffect(() => {
  //   const savedRoute = localStorage.getItem('currentRoute');
  //   if (savedRoute && savedRoute !== location.pathname) {
  //     navigate(savedRoute, { replace: true });
  //   }
  // }, [location.pathname, navigate]);

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.setItem('currentRoute', location.pathname);
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [location.pathname]);


  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     if (window.location.pathname === "/adminlogin") {
  //       navigate("/"); 
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && window.location.pathname === "/adminlogin") {
        navigate("/"); 
    } else if (!token && window.location.pathname !== "/adminlogin" && window.location.pathname !== "/") {
        navigate("/adminlogin"); 
    }
}, [navigate]);

  
  
  const [userInfo, setUserInfo] = useState({ email: '', name: '' });
  // console.log("userInfo :" , userInfo);

  const [showForgotPassword , setShowForgotPassword] = useState(false)

  const handleLogin = async  (email, password ) => { 

    console.log("Emailid" , email , "Passwordd" ,password);
    setUserEmail(email)
    try{
        const response = await axios.post(`${baseURL}/${btoa("login/managerlogin")}` , {
          submit : "true" ,
           email : email ,
           password : password
        })
        console.log(response);
        const userData = response.data
      
        console.log(userData);
        setDummyOtp(userData.otp)

        // const { contact_email, contact_name } = userData.loggedin[0];
        // console.log("Contact Email:", contact_email);
        // console.log("Contact Name:", contact_name);
        // setUserInfo({email : contact_email , name : contact_name})

       
        if(userData.otpSentStatus === true){
          // alert("true")
         
          // alert("set successfull")  // to pass the email to userEmail and to recieve in  otp page
          handleShowOTP(true);
          // localStorage.setItem("token", userData.token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
          // setLoggedIn(true);
          localStorage.setItem("status",true)
          

        }
      }
      catch(error){
        console.log("error posting details" , error);
      }
     
  };

  // console.log("userEmail : ", userEmail);


  useEffect(() => {
    const cleanupStorage = () => {
      localStorage.removeItem('hasToastBeenShown');
    };
    window.addEventListener('beforeunload', cleanupStorage);
    return () => {
      window.removeEventListener('beforeunload', cleanupStorage);
    };
  }, []);


  const [shouldShowToast, setShouldShowToast] = useState(true);

  useEffect(() => {
    const hasToastBeenShown = localStorage.getItem('hasToastBeenShown');
    const isLoggedIn = localStorage.getItem("status");
    if (!hasToastBeenShown && isLoggedIn) {
      toast.success('Login Success', { autoClose: 2000 });
      localStorage.setItem('hasToastBeenShown', 'true');
    }
    localStorage.removeItem("status");
  }, []);
  
  
  const user_role = localStorage.getItem("user_role");
  const handleSignOut = ()=>{ 
    if (user_role === "1") {
      navigate("/adminlogin");
    } else if (user_role === '5'){
      navigate('/customerportal')
    } else {
      navigate("/");
    }

    // localStorage.clear();
    localStorage.clear();
    setShouldShowToast(false);

    localStorage.setItem('signOutToken', Date.now().toString());

    // const signOutChannel = new BroadcastChannel('sign-out-channel');
    // signOutChannel.postMessage({ signOut: true });
    // signOutChannel.close();
    
    // delete axios.defaults.headers.common['Authorization'];
    // window.location.reload()
  }

  window.addEventListener('storage', (event) => {
    if (event.key === 'signOutToken') {
      setShouldShowToast(false); // or perform any other actions
    }
  });
  
  useEffect(() => {
    const signOutChannel = new BroadcastChannel('sign-out-channel');  // NEW USEEFFECT FOR CLEARING TOKEN WHEN USER CLOSES THE BROWSER
    signOutChannel.addEventListener('message', (event) => {
      if (event.data.signOut) {
        window.location.reload();
      }
    });
    window.addEventListener('beforeunload', () => {
      signOutChannel.close();
    });
    return () => {
      signOutChannel.close();
    };
  }, []);

  


  const signOutChannel = new BroadcastChannel('sign-out-channel');

      signOutChannel.addEventListener('message', (event) => {
        if (event.data.signOut) {
          window.location.reload();
        }
      });

      window.addEventListener('beforeunload', () => {
        signOutChannel.close();
      });

      const openForgotPassword = () => {
        setShowForgotPassword(true);
      };

      const handleForgotCancelClick=() => {
        setShowForgotPassword(false)
      }
    

      if (loading) {
        return <div className="flex justify-center items-center w-screen h-screen absolute top-0 left-0">
        <React.Fragment>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
      </div>
      }

    

  return (

    deploymentStatus ? (
      <DeploymentPage fromDateTime={fromDateTime} toDateTime={toDateTime} />
    )  
      : 

        <div className="flex bg-white">
        {showOTP ? (
          <>
            {isCustomer ?
            <CustomerOtpPage  handleSignOut={handleSignOut} userEmail={userEmail} dummyOtp={dummyOtp}  />

          :
            <OtpPage  handleSignOut={handleSignOut} userEmail={userEmail} dummyOtp={dummyOtp}  />}
            </>
        ) : (
          <>
            {localStorage.getItem("token") ? (
              // If logged in, display the main content
              <>
                <TabsProvider>
                  <SideBar isOpen={isOpen} handleSignOut= {handleSignOut} />
                  <div className="flex flex-col w-full">
                    <Header
                      toggleSidebar={toggleSidebar}
                      isLoggedIn={localStorage.getItem("status")}
                      handleSignOut={handleSignOut}
                      userInfo={userInfo}
                    />
                    <ToastContainer autoClose={false} closeButton={false} transition={Zoom} />
                    <Toast />
                    <AppRoutes />
                    <Footer />
                  </div>
                </TabsProvider>
              </>
            ) : (
              showSignUp ? (
                <SignUpPage handleCancelClick={handleCancelClick} />
              ) : (
                showForgotPassword ? (
                  <ForgotPassword handleForgotCancelClick={handleForgotCancelClick} />
                ) : (
                  (location.pathname === '/adminlogin') ? (   
        
                    <LoginPage                                                  // admin login page
                      onLogin={handleLogin}
                      handleSignUpClick={handleSignUpClick}
                      handleShowOTP={handleShowOTP}
                      openForgotPassword={openForgotPassword}
                    />   
                  ) : location.pathname === '/customerportal' ? (
                    <CustomerPortalPage 
                      onLogin={handleLogin} 
                      handleSignUpClick={handleSignUpClick} 
                      handleShowOTP={handleShowOTP}
                      setIsCustomer={setIsCustomer} 
                      openForgotPassword={openForgotPassword}

                    />
                  )
                  
                  
                  : (
                    <UserLoginPage                                             // user/manager or owner login page
                      onLogin={handleLogin}
                      handleSignUpClick={handleSignUpClick}
                      handleShowOTP={handleShowOTP}
                      openForgotPassword={openForgotPassword}
                    />
                  )
                )
              )
            )}
          </>
        )}
        </div>
  );
}

export default App;








