import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTabs } from "../pages/TabContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import * as Yup from "yup";
import {
  MdOutlineLocalShipping,
  MdOutlinePayment,
  MdOutlinePhoneInTalk,
  MdPersonOutline,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import CustomTooltip from "./CustomeTooltip";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import config  from "../../config/config"
import ReactDOM from 'react-dom';
import { showToastForDeleteCustomer, showToastForUpdateCustomer } from "../toasts/toastForCustomer";
import smsIcon from '../../assets/images/smsIcon.png'
import { Tooltip } from "react-tippy";


const ViewCustomer = () => {
  const { changeTab } = useTabs();
  const handleCancel = () => {
    // Change tab to 'items'
    navigate(`/customers`); // Navigate to the 'items' route
  };

  const { customerId } = useParams();
  console.log(customerId);
  const [isFormModified, setIsFormModified] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const CustomTooltip1 = ({ content, show, marginLeft }) => {
    return (
      <div
        style={{
          display: show ? "block" : "none",
          position: "absolute",
          backgroundColor: "#ffffff",
          color: "#fff",
          padding: "5px",
          borderRadius: "4px",
          border: "1px solid red",
          marginTop: "-35px",
          marginLeft: `${marginLeft}px`, // Use the provided marginLeft prop
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            marginLeft: "-10px",
            width: "0",
            height: "0",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            borderTop: "7px solid #f98080",
          }}
        ></div>
        <p className="text-xs text-red-500">{content}</p>
      </div>
    );
  };

  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [customerEditMode, setCustomerEditMode] = useState(false);
  const [billingEditMode, setBillingEditMode] = useState(false);
  const [shippingEditMode, setShippingEditMode] = useState(false);
  const [paymentEditMode, setPaymentEditMode] = useState(false);
  const [zipCodeError, setZipCodeError] = useState("");
  const [billingZipCodeError, setBillingZipCodeError] = useState("");
  const [shippingZipCodeError, setShippingZipCodeError] = useState("");
  const [cardZipCodeError, setCardZipCodeError] = useState("");
  const [bankAccountError, setBankAccountError] = useState("");
  const [businessAccountError, setBusinessAccountError] = useState("");
  const [inputPhoneError, setInputPhoneError] = useState("");
  const [bankRoutingError, setBankRoutingError] = useState("");
  const [cardError, setCardError] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [expirationDateError, setExpirationDateError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isBusinessInfoModified, setIsBusinessInfoModified] = useState(false);
  const [isContactInfoModified, setIsContactInfoModified] = useState(false);
  const [isBillingInfoModified, setIsBillingInfoModified] = useState(false);
  const [isShippingInfoModified, setIsShippingInfoModified] = useState(false);
  const [isPaymentInfoModified, setIsPaymentInfoModified] = useState(false);
  const [busNameError, setbusNameError] = useState("");
  const [busAddNameError, setBusAddNameError] = useState("");
  const [busCityNameError, setbusCityNameError] = useState("");
  const [contactNameError, setContactNameError] = useState("");

 

  const [invalidBusinessZipCode , setInvalidBusinessZipCode] = useState("")
  const [invalidPhoneNumber , setInvalidPhoneNumber] = useState("");
  const [invalidBillingZipCode , setInvalidBillingZipCode] = useState("")
  const [invalidShippingPhoneNumber , setInvalidShippingPhoneNumber] = useState("");
  const [invalidShippingZipCode , setInvalidShippingZipCode] = useState("")
  const [invalidBankRoutingNumber , setInvalidBankRoutingNumber] = useState("")
  const [invalidBankAccountNumber , setInvalidBankAccountNumber] = useState("")
  const [invalidCardNumber , setInvalidCardNumber] = useState("")
  const [invalidCardCvv , setInvalidCardCvv] = useState("")
  const [invalidCardZipCode , setInvalidCardZipCode] = useState("")

  const [billingAddNameError, setBillingAddNameError] = useState("");
  const [billingCityNameError, setBillingCityNameError] = useState("");
  const [shippingToNameError, setshippingToNameError] = useState("");
  const [shippingAddNameError, setShippingAddNameError] = useState("");
  const [shippingCityError, setshippingCityError] = useState("");
  const [shippingDIError, setShippingDTError] = useState("");
  const [cardNameError, setCardNameError] = useState("");
  const [isSameAsAboveSelected, setIsSameAsAboveSelected] = useState(false);
  const [customerNotification, setCustomerNotification] = useState(false);
  const [contactNotification, setContactNotification] = useState(false);
  const [billingNotification, setBillingNotification] = useState(false);
  const [shippingNotification, setShippingNotification] = useState(false);
  const [paymentNotification, setPaymentNotification] = useState(false);

  const handlebusNameChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^0-9a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setbusNameError("Name must be at least 3 letters");
    } else {
      setbusNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };
  const handlebusAddChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s\-\/#]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBusAddNameError("Address must be at least 3 letters");
    } else {
      setBusAddNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true);
    }
  };

  const handlebusCityChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9\s]/g, "");
    setFormData({
      ...formData,
      [name]: capitalizeWords(sanitizedValue),
    });
    if (sanitizedValue.replace(/\s/g, "").length > 0 && sanitizedValue.length < 3) {
      setbusCityNameError(" City Name must be at least 3 letters");
    } else {
      setbusCityNameError("");
    }
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };
  const handleContactNameChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); 
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setContactNameError(" Name must be at least 3 letters");
    } else {
      setContactNameError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      // setIsContactInfoModified(isContactModified);
      setIsBusinessInfoModified(!isContactModified);
      setIsContactInfoModified(isContactModified);
      setIsBillingInfoModified(!isContactModified);
      setIsShippingInfoModified(!isContactModified);
      setIsPaymentInfoModified(!isContactModified);
      setCustomerNotification(true)
    }
  };
  const handleBillingAddChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s\-\/#]/g, "");

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBillingAddNameError("Address must be at least 3 letters");
    } else {
      setBillingAddNameError("");
    }
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      // setIsBillingInfoModified(isBillingModified);
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsBillingInfoModified(isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setBillingNotification(true)
    }
  };
  const handleBillingCityChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setBillingCityNameError(" City Name must be at least 3 letters");
    } else {
      setBillingCityNameError("");
    }
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsBillingInfoModified(isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setBillingNotification(true)

    }
  };
  const handlecardChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z\s]/g, "");
    let inputValue = sanitizedValue.replace(/[^a-zA-Z\s]/g, ""); 
    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setCardNameError(" Name on card must be at least 3 letters");
    } else {
      setCardNameError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      // setIsPaymentInfoModified(isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsBillingInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleShippingToChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setShippingInfo({
      ...shippingInfo,
       contactPersonName : capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingToNameError(" Shipping Details must be at least 3 letters");
    } else {
      setshippingToNameError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      // setIsShippingInfoModified(isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setCustomerNotification(true)
    }
  };

  const handleShippingToChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFormData({
      ...formData,
       shippingTo : capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingToNameError(" Shipping Details must be at least 3 letters");
    } else {
      setshippingToNameError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);  
      setShippingNotification(true)
    }
  };


  const handleShippingAddChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setShippingAddNameError(" Address must be at least 3 letters");
    } else {
      setShippingAddNameError("");
    }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified); 
      setShippingNotification(true)   }
  };

  const handleShippingCityChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setShippingInfo({
      ...shippingInfo,
     billingCity : capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingCityError(" City Name must be at least 3 letters");
    } else {
      setshippingCityError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingInfoModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingInfoModified);
      setIsContactInfoModified(!isShippingInfoModified);
      setIsBillingInfoModified(!isShippingInfoModified);
      setIsShippingInfoModified(isShippingInfoModified);
      setIsPaymentInfoModified(!isShippingInfoModified);
      setShippingNotification(true)    }
  };

  const handleShippingCityChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setFormData({
      ...formData,
     shippingCity : capitalizeWords(inputValue),
    });
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setshippingCityError(" City Name must be at least 3 letters");
    } else {
      setshippingCityError("");
    }
    // const isPaymentModified = value !== customerDetails[name];
    // if (paymentInfoFields.includes(name)) {
    //   setIsPaymentInfoModified(isPaymentModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)    }
  };

  const handleShippingDIChange = (e) => {
    const { name, value } = e.target;

    // Validate item name to contain only alphabets and spaces
    let inputValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters except spaces

    setFormData({
      ...formData,
      [name]: capitalizeWords(inputValue),
    });

    // Set error message if item name is not empty and less than 3 characters
    if (inputValue.replace(/\s/g, "").length > 0 && inputValue.length < 3) {
      setShippingDTError(" Instructions must be at least 3 letters");
    } else {
      setShippingDTError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsPaymentInfoModified(isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsBillingInfoModified(!isPaymentModified);
      setIsShippingInfoModified(isPaymentModified);
      setPaymentNotification(true) 
    }
  };

  const [shippingInfo, setShippingInfo] = useState({
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhoneNumber: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZipCode: '',
    shippingCounty : '',
  });

  const [formData, setFormData] = useState({
    businessName: "",
    businessAccount: "",
    businessAddress: "",
    businessCity: "",
    businessState: "",
    businessZipCode: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonPhoneNumber: "",
    currency: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingZipCode: "",
    shippingTo: shippingInfo.contactPersonName,
    shippingAddress: shippingInfo.billingAddress,
    shippingCity: shippingInfo.billingCity,
    shippingState: shippingInfo.billingState,
    shippingZipCode: shippingInfo.billingZipCode,
    shippingCounty: shippingInfo.shippingCounty,
    shippingContactNumber: shippingInfo.contactPersonPhoneNumber,
    shippingDeliveryInstructions: "",
    bankCurrency: "",
    bankRouting: "",
    bankAccount: "",
    cardNumber: "",
    cardExpiryDate: new Date(),
    cardCVV: "",
    cardZipCode: "",
    nameOnCard: "",
    paymentType: "",
    creditCardName: "",
    cardPaymentCurrency: "",
  });
  
  const [isCardSelected, setIsCardSelected] = useState(false);

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.slice(0, 10);
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ')', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const validateEmail = (email) => {
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    let { name, value } = e.target;
     value = e.target.value;
    if (value.length > 1) {
      value = value[0] + value.slice(1).toLowerCase();
    }
    const isValidEmail = validateEmail(value);
    setFormData({
      ...formData,
      [name]: value,
    });

    // Set error message if email is not in the expected format
    if (value.length > 0 && !isValidEmail) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      // setIsContactInfoModified(isContactModified);
      setIsBusinessInfoModified(!isContactModified);
      setIsContactInfoModified(isContactModified);
      setIsBillingInfoModified(!isContactModified);
      setIsShippingInfoModified(!isContactModified);
      setIsPaymentInfoModified(!isContactModified);
      setContactNotification(true)
    }
  };


 


  useEffect(() => {
    const encodedId = btoa(customerId)
    axios
      .get(`${baseURL}Y3VzdG9tZXIvZWRpdA==/${encodedId}`)               // http://localhost/InvoiceFlow/customer/edit/{customerId}
      // .get(`${baseURL}/customer/edit/${customerId}`)      
      .then((response) => {
        const customerData = response.data.customers_data[0];
        console.log(customerData);
        setCustomerDetails(customerData);
        setFormData({
          businessName: customerData.business_name,
          businessAccount: customerData.business_account,
          businessAddress: customerData.business_address,
          businessCity: customerData.business_city,
          businessState: customerData.business_state,
          businessZipCode: customerData.business_zip_code,
          contactPersonName: customerData.contact_name,
          contactPersonEmail: customerData.contact_email,
          contactPersonPhoneNumber: customerData.contact_no,
          billingAddress: customerData.billing_address,
          billingCity: customerData.billing_city,
          billingState: customerData.billing_state,
          billingZipCode: customerData.billing_zip_code,
          shippingTo: customerData.shipping_to,
          shippingAddress: customerData.shipping_address,
          shippingCity: customerData.shipping_city,
          shippingState: customerData.shipping_state,
          shippingZipCode: customerData.shipping_zip_code,
          shippingCounty: customerData.shipping_county,
          shippingContactNumber: customerData.shipping_contact_number,
          shippingDeliveryInstructions:  customerData.shipping_delivery_instructions,

          bankCurrency: customerData.payment_currency,
          bankRouting: customerData.bank_routing,
          bankAccount: customerData.bank_account,
          cardNumber: customerData.card_number,
          nameOnCard: customerData.name_on_card,
          cardExpiryDate: customerData.card_expiry_date,
          cardZipCode: customerData.card_zip_code, // Assuming it's in the correct format, otherwise, you might need to format it appropriately
          cardCVV: customerData.card_cvv,
          paymentType: customerData.payment_type,
          creditCardName: customerData.credit_card_name,
          cardPaymentCurrency: customerData.card_payment_currency,
        });
     
        setShippingInfo({
          contactPersonName: customerData.contact_name,
          contactPersonEmail: customerData.contact_email,
          contactPersonPhoneNumber: customerData.contact_no,
          billingAddress: customerData.billing_address,
          billingCity: customerData.billing_city,
          billingState: customerData.billing_state,
          billingZipCode: customerData.shipping_zip_code,
        })
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        setLoading(false);
      });
  }, [customerId]);

  const handleAddressToggle = () => {
    setIsSameAsAboveSelected((prev) => !prev);

    // If "Same as above" is selected, copy values from the above section
    if (!isSameAsAboveSelected) {
      setShippingInfo({
        contactPersonName: formData.contactPersonName,
        contactPersonEmail: formData.contactPersonEmail ,
        contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
        billingAddress: formData.billingAddress,
        billingCity: formData.billingCity,
        billingState: formData.billingState,
        billingZipCode: formData.billingZipCode,
        
      });
    }
  };


  const handleInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3,6)}-${inputValue.slice(6, 10)}`;
    }
    const formattedValue = formatPhoneNumber(e.target.value);
    const containsNonZero = /[1-9]/.test(formattedValue);
    if(containsNonZero){
      setInvalidPhoneNumber("valid")
    }else{
      setInvalidPhoneNumber("Invalid number")
    }

    if(formattedValue === ""){
      setInvalidPhoneNumber("")
    }

    if (inputValue.length > 0 && !/^\(\d{3}\) \d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
    setFormData({
      ...formData,
      [name]: inputValue,
    });
    const isContactModified = value !== customerDetails[name];
    if (contactInfoFields.includes(name)) {
      // setIsContactInfoModified(isContactModified);
      setIsBusinessInfoModified(!isContactModified);
      setIsContactInfoModified(isContactModified);
      setIsBillingInfoModified(!isContactModified);
      setIsShippingInfoModified(!isContactModified);
      setIsPaymentInfoModified(!isContactModified);
      setContactNotification(true)
    }
    // const isShippingModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsShippingInfoModified(isShippingModified);
    // }
  };

  const handleShippingInputPhoneChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }
    const containsNonZero = /[1-9]/.test(e.target.value);
                  if(containsNonZero){
                    setInvalidShippingPhoneNumber("valid")
                  }else if(e.target.value.length > 0 && e.target.value.length < 10){
                    setInvalidShippingPhoneNumber("Invalid number")
                  }
                  else{
                    setInvalidShippingPhoneNumber("Invalid number")
                  }
                  if(e.target.value=== ""){
                    setInvalidShippingPhoneNumber("")
                  }
    setShippingInfo({
      ...shippingInfo,
      contactPersonPhoneNumber : inputValue,
    });
    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
    // const isContactModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsContactInfoModified(isContactModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingInputPhoneChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 10);
    if (inputValue.length === 10) {
      inputValue = `(${inputValue.slice(0, 3)})${inputValue.slice(
        3,
        6
      )}-${inputValue.slice(6, 10)}`;
    }
    const containsNonZero = /[1-9]/.test(e.target.value);
    
                  if(containsNonZero){
                    setInvalidShippingPhoneNumber("valid")
                  }else if(e.target.value.length > 0 && e.target.value.length < 10){
                    setInvalidShippingPhoneNumber("Invalid number")
                  }
                  else{
                    setInvalidShippingPhoneNumber("Invalid number")
                  }
                  if(e.target.value=== ""){
                    setInvalidShippingPhoneNumber("")
                  }
    setFormData({
      ...formData,
      shippingContactNumber : inputValue,
    });
    if (inputValue.length > 0 && !/^\(\d{3}\)\d{3}-\d{4}$/.test(inputValue)) {
      setInputPhoneError("Please enter a valid 10-digit phone number");
    } else {
      setInputPhoneError("");
    }
    // const isContactModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsContactInfoModified(isContactModified);
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsBillingInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };


  const handleInputChange = (e, val) => {
    const { name, value } = e.target;

    // Check if the field is one of the excluded fields
    const transformedValue = [
      "businessState",
      "billingState",
      "shippingState",
      "bankCurrency",
      "cardPaymentCurrency",
    ].includes(name)
      ? value
      : capitalizeWords(value);

    setFormData({
      ...formData,
      [name]: transformedValue,
    });
    if(val==="businessState"){
    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  }
    // const isContactModified = value !== customerDetails[name];
    // if (contactInfoFields.includes(name)) {
    //   setIsContactInfoModified(isContactModified);
    // }
    if(val === "billingState"){
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      setIsBillingInfoModified(isBillingModified);
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setBillingNotification(true)
    }
  }
    // const isShippingModified = value !== customerDetails[name];
    // if (shippingInfoFields.includes(name)) {
    //   setIsShippingInfoModified(isShippingModified);
    // }
    if(val === "cardPaymentCurrency" ||"creditCardName" || "bankCurrency"){
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  }
  };

  const handleBankRoutingChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 9); // Limit to 17 digits

    const containsNonZero = /[1-9]/.test(inputValue);
    
    if(containsNonZero){
      setInvalidBankRoutingNumber("valid")
    }else{
      setInvalidBankRoutingNumber("Invalid number")
    }
    if(inputValue === ""){
      setInvalidBankRoutingNumber("")
    }

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if account number is not empty and exceeds 17 digits
    if (inputValue.length > 0 && inputValue.length < 9) {
      setBankRoutingError("Routing Number must be of 9 digits");
    } else {
      setBankRoutingError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
      
    }
  };

  const processBusinessAccountInput = (value) => {
    const inputValue = value.replace(/[^a-zA-Z0-9]/g, "");
    const maxLength = 20;
    const truncatedValue = inputValue.slice(0, maxLength);
    const processedValue = truncatedValue.charAt(0).toUpperCase() + truncatedValue.slice(1);
    return processedValue;
  };

  const handleBusinessAccountChange = (e) => {
    const { name, value } = e.target;
    const processedValue = processBusinessAccountInput(value);
    setFormData({
      ...formData,
      [name]: processedValue,
    });
    if (processedValue.length >=0 && processedValue.length <3) {
      setBusinessAccountError("Business account must be at least 3 to 20 characters");
    } else {
      setBusinessAccountError("");
    }
    const isModified = processedValue !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsBillingInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
  };

  const handleBankAccountChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 17); 
    const containsNonZero = /[1-9]/.test(inputValue);
    if(containsNonZero){
      setInvalidBankAccountNumber("valid")
    }else{
      setInvalidBankAccountNumber("Invalid number")
    }
    if(inputValue === ""){
      setInvalidBankAccountNumber("")
    }
    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if account number is not empty and exceeds 17 digits
    if (inputValue.length > 0 && inputValue.length < 17) {
      setBankAccountError("Account Number must be of 17 digits");
    } else {
      setBankAccountError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleInputZipChange = (e) => {
    const { name, value } = e.target;
    const containsNonZero = /[1-9]/.test(value);
    if (containsNonZero) {
        setInvalidBusinessZipCode("valid")
    } else {
        setInvalidBusinessZipCode("Invalid number")
    }

    if (e.target.value === "") {
        setInvalidBusinessZipCode("")
    }
    
    let inputValue = value;
    inputValue = inputValue.slice(0, 5); // Trimming to first 5 characters
    setFormData({
        ...formData,
        [name]: inputValue  // Using trimmed value here
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
        setZipCodeError("Zip code must be at most 5 digits");
    } else {
        setZipCodeError("");
    }

    const isModified = value !== customerDetails[name];
    if (businessInfoFields.includes(name)) {
      setIsBillingInfoModified(!isModified);
      setIsBusinessInfoModified(isModified);
      setIsContactInfoModified(!isModified);
      setIsShippingInfoModified(!isModified);
      setIsPaymentInfoModified(!isModified);
      setCustomerNotification(true)
    }
};



  const handleBillingZipChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 5); // Limit to 5 digits

    const containsNonZero = /[1-9]/.test(e.target.value);
    if(containsNonZero){
      setInvalidBillingZipCode("valid")
    }else{
      setInvalidBillingZipCode("Invalid number")
    }

    if(e.target.value === ""){
      setInvalidBillingZipCode("")
    }

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setBillingZipCodeError("Zip code must be at most 5 digits");
    } else {
      setBillingZipCodeError("");
    }
    const isBillingModified = value !== customerDetails[name];
    if (billingInfoFields.includes(name)) {
      setIsBillingInfoModified(isBillingModified);
      setIsBusinessInfoModified(!isBillingModified);
      setIsContactInfoModified(!isBillingModified);
      setIsShippingInfoModified(!isBillingModified);
      setIsPaymentInfoModified(!isBillingModified);
      setContactNotification(true)
    }
  };
  const expirationDatePattern = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;

  const validateExpirationDate = (date) => {
    return expirationDatePattern.test(date);
  };

  const handleExpirationDateChange = (e) => {
    const { name, value } = e.target;
    const isBackspace = e.nativeEvent && e.nativeEvent.inputType === "deleteContentBackward";
    const cleanedValue = value.replace(/\D/g, '');
    const truncatedValue = cleanedValue.slice(0, 4);
    const formattedValue = truncatedValue.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
  
    if (isBackspace && truncatedValue.length === 2) {
      const slicedValue = truncatedValue.slice(0, 1);
      return setFormData({
        ...formData,
        [name]: slicedValue,
      });
    }
  
    const isValidExpirationDate = validateExpirationDate(formattedValue);
  
    setFormData({
      ...formData,
      [name]: formattedValue,
    });

    // Set error message if expiration date is not in the expected format
    // if (value.length > 0 && !isValidExpirationDate) {
    //   setExpirationDateError("Invalid expiration date format (MM/YY)");
    // } else {
    //   setExpirationDateError("");
    // }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const handleShippingZipChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, ""); 
    inputValue = inputValue.slice(0, 5);

    const containsNonZero = /[1-9]/.test(inputValue);
    
    if(containsNonZero){
      setInvalidShippingZipCode("valid")
    }else{
      setInvalidShippingZipCode("Invalid number")
    }
    if(inputValue === ""){
      setInvalidShippingZipCode("")
    }
    
    setShippingInfo({
      ...shippingInfo,
      billingZipCode : inputValue,
    });

    if (inputValue.length > 0 && inputValue.length < 5) {
      setShippingZipCodeError("Zip code must be at most 5 digits");
    } else {
      setShippingZipCodeError("");
    }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBillingInfoModified(!isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingZipChangeSecond = (e) => {
    const { name, value } = e.target;
    let inputValue = value.replace(/\D/g, ""); 
    inputValue = inputValue.slice(0, 5);

    const containsNonZero = /[1-9]/.test(inputValue);
    
    if(containsNonZero){
      setInvalidShippingZipCode("valid")
    }else{
      setInvalidShippingZipCode("Invalid number")
    }
    if(inputValue === ""){
      setInvalidShippingZipCode("")
    }

    setFormData({
      ...formData,
      shippingZipCode : inputValue,
    });

    if (inputValue.length > 0 && inputValue.length < 5) {
      setShippingZipCodeError("Zip code must be at most 5 digits");
    } else {
      setShippingZipCodeError("");
    }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBillingInfoModified(!isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };

  const handleShippingCountyChange = (e) => {
    setIsShippingInfoModified(true);
    setIsBusinessInfoModified(false)
    setIsContactInfoModified(false)
    setIsBillingInfoModified(false)
    setIsPaymentInfoModified(false)
    setShippingNotification(true)
    const { name, value } = e.target;
    // let inputValue = value.replace(/\D/g, ""); 
    // inputValue = inputValue.slice(0, 5);
    // const containsNonZero = /[1-9]/.test(inputValue);
    
    // if(containsNonZero){
    //   setInvalidShippingZipCode("valid")
    // }else{
    //   setInvalidShippingZipCode("Invalid number")
    // }
    // if(inputValue === ""){
    //   setInvalidShippingZipCode("")
    // }
    setFormData({
      ...formData,
      [name] : e.target.value,
    });
    // if (inputValue.length > 0 && inputValue.length < 5) {
    //   setShippingZipCodeError("Zip code must be at most 5 digits");
    // } else {
    //   setShippingZipCodeError("");
    // }
    const isShippingModified = value !== customerDetails[name];
    if (shippingInfoFields.includes(name)) {
      setIsBillingInfoModified(!isShippingModified);
      setIsBusinessInfoModified(!isShippingModified);
      setIsContactInfoModified(!isShippingModified);
      setIsShippingInfoModified(isShippingModified);
      setIsPaymentInfoModified(!isShippingModified);
      setShippingNotification(true)
    }
  };


  const validateCardNumber = (cardNumber) => {
    const cleanedCardNumber = cardNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedCardNumber = cleanedCardNumber.slice(0, 19); // Limit to 19 digits

    return formattedCardNumber;
  };

  const handleCardNumberChange = (e) => {
    const { name, value } = e.target;

    // Validate and format card number
    const formattedCardNumber = validateCardNumber(value);
    const containsNonZero = /[1-9]/.test(formattedCardNumber);
    
    if(containsNonZero){
      setInvalidCardNumber("valid")
    }else{
      setInvalidCardNumber("Invalid number")
    }

    if(formattedCardNumber === ""){
      setInvalidCardNumber("")
    }
    setFormData({
      ...formData,
      [name]: formattedCardNumber,
    });
    
    if (value.length > 0 && formattedCardNumber.length < 10) {
      setCardError("Card number must be between 10 and 19 digits");
    } else {
      setCardError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };
  const validateCardCVV = (cardNumber) => {
    const cleanedCardNumber = cardNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedCardNumber = cleanedCardNumber.slice(0, 4); // Limit to 19 digits

    return formattedCardNumber;
  };
  const handleCardCvvChange = (e) => {
    const { name, value } = e.target;

    // Validate and format card number
    const formattedCardNumber = validateCardCVV(value);
    const containsNonZero = /[1-9]/.test(formattedCardNumber);
    if(containsNonZero){
      setInvalidCardCvv("valid")
    }else{
      setInvalidCardCvv("Invalid number")
    }
    if(formattedCardNumber === ""){
      setInvalidCardCvv("")
    }

    setFormData({
      ...formData,
      [name]: formattedCardNumber,
    });

    // Set error message if card number is not empty and not in the expected format
    if (value.length > 0 && formattedCardNumber.length < 3) {
      setCardCvv("CardCvv must be between 3 to 19 digits");
    } else {
      setCardCvv("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleCardZipChange = (e) => {
    const { name, value } = e.target;

    // Validate card zip code to be at most 5 digits
    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    inputValue = inputValue.slice(0, 5);
    
    const containsNonZero = /[1-9]/.test(e.target.value);
    if(containsNonZero){
      setInvalidCardZipCode("valid")
    }else{
      setInvalidCardZipCode("Invalid number")
    }

    if(e.target.value === ""){
      setInvalidCardZipCode("")
    } // Limit to 5 digits

    setFormData({
      ...formData,
      [name]: inputValue,
    });

    // Set error message if zip code is not empty and exceeds 5 digits
    if (inputValue.length > 0 && inputValue.length < 5) {
      setCardZipCodeError("Zip code must be at most 5 digits");
    } else {
      setCardZipCodeError("");
    }
    const isPaymentModified = value !== customerDetails[name];
    if (paymentInfoFields.includes(name)) {
      setIsBillingInfoModified(!isPaymentModified);
      setIsBusinessInfoModified(!isPaymentModified);
      setIsContactInfoModified(!isPaymentModified);
      setIsShippingInfoModified(!isPaymentModified);
      setIsPaymentInfoModified(isPaymentModified);
      setPaymentNotification(true)
    }
  };

  const handleEditClick = async (e) => {
    if (
      zipCodeError ||
      billingZipCodeError ||
      shippingZipCodeError ||
      cardZipCodeError ||
      bankAccountError ||
      businessAccountError ||
      inputPhoneError
    ) {
      // Display an error message or take appropriate action
      return;
    }

    

    const updatedData = {
      businessId : localStorage.getItem("business_id"),
      managerId : localStorage.getItem("user_id"),
      edit_customerId: customerId,
      edit_businessName: formData.businessName,
      edit_businessAccount: formData.businessAccount,
      edit_businessAddress: formData.businessAddress,
      edit_businessCity: formData.businessCity,
      edit_businessState: formData.businessState,
      edit_businessZipCode: formData.businessZipCode,
      edit_contactPersonName: formData.contactPersonName,
      edit_contactPersonEmail: formData.contactPersonEmail,
      edit_contactPersonPhoneNumber: formData.contactPersonPhoneNumber,
      edit_billingAddress: formData.billingAddress,
      edit_billingCity: formData.billingCity,
      edit_billingState: formData.billingState,
      edit_billingZipCode: formData.billingZipCode,
      edit_shippingTo: shippingInfo.contactPersonName,
      edit_shippingAddress: shippingInfo.billingAddress,
      edit_shippingCity: shippingInfo.billingCity,
      edit_shippingState: shippingInfo.billingState,
      edit_shippingZipCode: shippingInfo.billingZipCode,
      edit_shippingCounty: formData.shippingCounty,
      edit_shippingContactNumber: shippingInfo.contactPersonPhoneNumber,
      edit_currency: formData.bankCurrency,
      edit_bankRouting: formData.bankRouting,
      edit_bankAccount: formData.bankAccount,
      edit_shippingDeliveryInstructions: formData.shippingDeliveryInstructions,
      edit_cardNumber: formData.cardNumber,
      edit_nameOnCard: formData.nameOnCard,
      edit_cardExpiryDate: formData.cardExpiryDate,
      edit_cardCVV: formData.cardCVV,
      edit_cardZipCode: formData.cardZipCode,
      edit_paymentType: formData.paymentType,
      edit_creditCardName: formData.creditCardName,
      edit_cardPaymentCurrency: formData.cardPaymentCurrency,
    };
    // ... (Rest of your code)
    
    try {
      const response = await axios.put(
        `${baseURL}/Y3VzdG9tZXIvdXBkYXRl`,                           //    `${baseURL}/customer/update`
        updatedData
      );
      console.log("Update data:", updatedData);
      console.log("Update Response:", response); // Add this log to check the response

      if (response.status === 200) {
        const responseData = await response.data;

        if (responseData.dataUpdatingStatus === true) {
          const filledFrames = [];

          // Check each frame and add its name to the array
          if (customerNotification) filledFrames.push("Customer Info");
          if (contactNotification) filledFrames.push("Contact Info");
          if (billingNotification) filledFrames.push("Billing Info");
          if (shippingNotification) filledFrames.push("Shipping Info");
          if (paymentNotification) filledFrames.push("Payment Info");

          // Construct success message based on filled frames
          let successMessage = ` ${formData.businessName},`;

          if (filledFrames.length > 0) {
            successMessage += ` ${filledFrames.join(
              ", "
            )} `;
          }
          // const filledFrames = [];

          // Check each frame and add its name to the array
          // if (formData.businessName) filledFrames.push("Info");
          // if (formData.contactPersonName) filledFrames.push("Contact Info");
          // if (formData.billingAddress) filledFrames.push("Billing Info");
          // if (formData.shippingTo) filledFrames.push("Shipping Info");
          // if (formData.bankCurrency) filledFrames.push("Payment Info");
          showToastForUpdateCustomer(`${successMessage} updated successfully` , "success");
        } else {
          showToastForDeleteCustomer(`Unable to update ${formData.businessName} customer data`, "error" );
        }
      } else {
        console.log("Request failed with status: " + response.status);
        response.data.then((data) => {
          console.log(data);
        });
        showToastForDeleteCustomer("Unable to update customer, please try again", "error");
      }
    } catch (error) {
      console.error("Update Error:", error);
      showToastForDeleteCustomer("Unable to update customer, please try again", "error");
    }
    window.scrollTo(0, 0);
    navigate(`/customers`);
  };

  console.log("Form Data:", formData);
  const handleToggle = () => {
    setIsCardSelected(!isCardSelected);
  };

  const capitalizeWords = (input) => {
    return input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Usage example
  const businessInfoFields = [
    "businessName",
    "businessAccount",
    "businessAddress",
    "businessCity",
    "businessState",
    "businessZipCode",
  ];
  const contactInfoFields = [
    "contactPersonName",
    "contactPersonEmail",
    "contactPersonPhoneNumber",
  ];
  const billingInfoFields = [
    "billingAddress",
    "billingCity",
    "billingState",
    "billingZipCode",
  ];
  const shippingInfoFields = [
    "shippingTo",
    "shippingAddress",
    "shippingCity",
    "shippingState",
    "shippingZipCode",
    "shippingContactNumber",
    "shippingDeliveryInstructions",
  ];
  const paymentInfoFields = [
    "bankCurrency",
    "bankRouting",
    "bankAccount",
    "cardNumber",
    "cardExpiryDate",
    "cardCVV",
    "cardZipCode",
    "nameOnCard",
    "creditCardName",
    "cardPaymentCurrency",
  ];
  return (
    <div>
      {loading ? (
        <p>Loading customer details...</p>
      ) : (
        <div className="pt-20 pb-20">
          <h1 className="mx-8 font-bold mb-14 text-gray-400 text-xl mt-[-25px]">
            Customers {">>"} Edit Customer
          </h1>

          <div className="w-auto  bg-white rounded-box mt-24  mx-8 rounded-xl">
            <div className="flex items-center">
              <MdPersonOutline className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
             Customer Info
              </h2>
            </div>
            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="businessAccount"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Account #{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={businessAccountError}
                  show={!!businessAccountError}
                  marginLeft={80}
                />

                <input
                  type="text"
                  id="businessAccount"
                  name="businessAccount"
                  value={formData.businessAccount}
                  onChange={handleBusinessAccountChange}
                  required
                  class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Account No"
                />
                {/* {businessAccountError && (
                  <div className="text-red-500 text-base mt-1">
                    {businessAccountError}
                  </div>
                )} */}
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessName"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Customer Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>

                <CustomTooltip1 content={busNameError} show={!!busNameError}   marginLeft={140}/>

                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handlebusNameChange}
                  required
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Customer Name"
                />
              </div>

              <div class="w-full">
                <label
                  htmlFor="businessAddress"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Customer Address{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={busAddNameError}
                  show={!!busAddNameError}
                  marginLeft={130}
                />

                <input
                  type="text"
                  id="businessAddress"
                  name="businessAddress"
                  value={formData.businessAddress}
                  onChange={handlebusAddChange}
                  required
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Customer Address"
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessCity"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  City{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={busCityNameError}
                  show={!!busCityNameError}
                  marginLeft={155}
                />

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter City"
                  type="text"
                  id="businessCity"
                  name="businessCity"
                  value={formData.businessCity}
                  onChange={handlebusCityChange}
                  required
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessState"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  State{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="businessState"
                  name="businessState"
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  value={formData.businessState}
                  onChange={(e)=>{handleInputChange(e, "businessState")}}
                  // onChange={handleChange}
                  required
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
              <div class="w-full">
                <label
                  htmlFor="businessZipCode"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={zipCodeError} show={!!zipCodeError}      marginLeft={167}/>
                {invalidBusinessZipCode === "Invalid number" && !zipCodeError &&  (
                <CustomTooltip1
                  content={"Invalid ZipCode"}
                  show={"Invalid ZipCode"}
                  marginLeft={163}
                />
              )} 
                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zipcode"
                  type="text"
                  id="businessZipCode"
                  name="businessZipCode"
                  value={formData.businessZipCode}
                  onChange={handleInputZipChange}
                  required
                />
              
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              {isBusinessInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 ||
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== "" || cardError !== "" || cardNameError !== "" || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                       || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                       invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                      
                      
                    }
                    
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Done
                  </button>
                </>
              ) : null}
            </div>
          </div>

          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <MdOutlinePhoneInTalk className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Contact Info
                
              </h2>
            </div>
            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="contactPersonName"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Contact Person Name{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={contactNameError}
                  show={!!contactNameError}
                  marginLeft={120}
                />

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Name"
                  type="text"
                  id="contactPersonName"
                  name="contactPersonName"
                  value={formData.contactPersonName}
                  onChange={handleContactNameChange}
                  required
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="contactPersonEmail"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Email{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1 content={emailError} show={!!emailError}   marginLeft={206}/>

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Email"
                  type="email"
                  id="contactPersonEmail"
                  name="contactPersonEmail"
                  value={formData.contactPersonEmail}
                  onChange={handleEmailChange}
                  required
                />
                {/* {emailError && (
                  <div className="text-red-500 text-base mt-1">{emailError}</div>
                )} */}
              </div>
              <div class="w-full">
                <label
                  htmlFor="contactPersonPhoneNumber"
                  class="block mb-2 text-base font-medium text-gray-900  "
                >
                    <div className="flex items-center">
      <span>Phone Number{" "}</span>
      <span className="text-red-500 text-xl font-extrabold ml-1">*</span>
      <Tooltip title="SMS will be sent" position="top" trigger="mouseenter">

      <img src={smsIcon} alt="SMS Icon" style={{ width: '30px', height: '20px', marginLeft: '8px' }} />
      </Tooltip>
    </div>
                </label>
                <CustomTooltip1
                  content={inputPhoneError}
                  show={!!inputPhoneError}
                  marginLeft={163}
                />
                 {invalidPhoneNumber === "Invalid number" && !inputPhoneError &&  (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                  />
                )}

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Phone Number"
                  type="text"
                  id="contactPersonPhoneNumber"
                  name="contactPersonPhoneNumber"
                  value={formData.contactPersonPhoneNumber}
                  onChange={handleInputPhoneChange}
                  required
                />
                {/* {inputPhoneError && (
                  <div className="text-red-500 text-base mt-1">
                    {inputPhoneError}
                  </div>
                )} */}
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              {isContactInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 ||
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== ""  || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    }
                    
                  >
                    Done
                  </button>
                </>
              ) : null}
            </div>
          </div>

          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <TbFileInvoice className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Billing Info
              </h2>
            </div>

            <div class="grid gap-4 sm:grid-cols-4 sm:gap-6">
              <div class="w-full">
                <label
                  htmlFor="billingAddress"
                  class="block mb-2 text-base font-medium text-gray-900"
                >
                  Billing Address{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={billingAddNameError}
                  show={!!billingAddNameError}
                  marginLeft={40}
                />

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Billing Address"
                  type="text"
                  id="billingAddress"
                  name="billingAddress"
                  value={formData.billingAddress}
                  // onChange={(e) => {
                  //   const inputValue = e.target.value;
                  //   // const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');
                  //   setFormData((prevData) => ({
                  //     ...prevData,
                  //     billingAddress: inputValue,
                  //   }));
                  // }}
                  onChange={handleBillingAddChange}
                  required
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="billingAddress"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  City{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <CustomTooltip1
                  content={billingCityNameError}
                  show={!!billingCityNameError}
                  marginLeft={65}
                />

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter City"
                  type="text"
                  id="billingCity"
                  name="billingCity"
                  value={formData.billingCity}
                  onChange={handleBillingCityChange}
                  required
                />
              </div>
              <div class="w-full">
                <label
                  htmlFor="billingState"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  State{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <select
                  id="billingState"
                  name="billingState"
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  value={formData.billingState}
                  // onChange={handleChange}
                  onChange={(e)=>{handleInputChange(e, 'billingState')}}
                  required
                >
                  <option value="">Select State</option>
                  <option value="AL">Alabama (AL)</option>
                  <option value="AK">Alaska (AK)</option>
                  <option value="AZ">Arizona (AZ)</option>
                  <option value="AR">Arkansas (AR)</option>
                  <option value="CA">California (CA)</option>
                  <option value="CO">Colorado (CO)</option>
                  <option value="CT">Connecticut (CT)</option>
                  <option value="DE">Delaware (DE)</option>
                  <option value="FL">Florida (FL)</option>
                  <option value="GA">Georgia (GA)</option>
                  <option value="HI">Hawaii (HI)</option>
                  <option value="ID">Idaho (ID)</option>
                  <option value="IL">Illinois (IL)</option>
                  <option value="IN">Indiana (IN)</option>
                  <option value="IA">Iowa (IA)</option>
                  <option value="KS">Kansas (KS)</option>
                  <option value="KY">Kentucky (KY)</option>
                  <option value="LA">Louisiana (LA)</option>
                  <option value="ME">Maine (ME)</option>
                  <option value="MD">Maryland (MD)</option>
                  <option value="MA">Massachusetts (MA)</option>
                  <option value="MI">Michigan (MI)</option>
                  <option value="MN">Minnesota (MN)</option>
                  <option value="MS">Mississippi (MS)</option>
                  <option value="MO">Missouri (MO)</option>
                  <option value="MT">Montana (MT)</option>
                  <option value="NE">Nebraska (NE)</option>
                  <option value="NV">Nevada (NV)</option>
                  <option value="NH">New Hampshire (NH)</option>
                  <option value="NJ">New Jersey (NJ)</option>
                  <option value="NM">New Mexico (NM)</option>
                  <option value="NY">New York (NY)</option>
                  <option value="NC">North Carolina (NC)</option>
                  <option value="ND">North Dakota (ND)</option>
                  <option value="OH">Ohio (OH)</option>
                  <option value="OK">Oklahoma (OK)</option>
                  <option value="OR">Oregon (OR)</option>
                  <option value="PA">Pennsylvania (PA)</option>
                  <option value="RI">Rhode Island (RI)</option>
                  <option value="SC">South Carolina (SC)</option>
                  <option value="SD">South Dakota (SD)</option>
                  <option value="TN">Tennessee (TN)</option>
                  <option value="TX">Texas (TX)</option>
                  <option value="UT">Utah (UT)</option>
                  <option value="VT">Vermont (VT)</option>
                  <option value="VA">Virginia (VA)</option>
                  <option value="WA">Washington (WA)</option>
                  <option value="WV">West Virginia (WV)</option>
                  <option value="WI">Wisconsin (WI)</option>
                  <option value="WY">Wyoming (WY)</option>
                </select>
              </div>
              <div class="w-full">
                <label
                  htmlFor="billingZipCode"
                  class="block mb-2 text-base font-medium text-gray-900 "
                >
                  Zip Code{" "}
                  <span className="text-red-500 text-xl font-extrabold">*</span>
                </label>
                <div>
                {invalidBillingZipCode === "Invalid number" && !billingZipCodeError &&  (
                    <CustomTooltip1
                      content={"Invalid ZipCode"}
                      show={"Invalid ZipCode"}
                      marginLeft={163}
                    />
                )} 
                <CustomTooltip1
                  content={billingZipCodeError}
                  show={!!billingZipCodeError}
                  marginLeft={80}
                />
                </div>
                 

                <input
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Zipcode"
                  type="text"
                  id="billingZipCode"
                  name="billingZipCode"
                  value={formData.billingZipCode}
                  onChange={handleBillingZipChange}
                  required
                />
                {/* {billingZipCodeError && (
                  <div className="text-red-500 text-base mt-1">
                    {billingZipCodeError}
                  </div>
                )} */}
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              {isBillingInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 || 
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== ""  || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    }
                    
                  >
                    Done
                  </button>
                </>
              ) : null}
            </div>
          </div>

          <div className="w-auto  bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex items-center">
              <MdOutlineLocalShipping className="text-gray-700 w-6 h-6 mr-2 mb-10" />
              <h2 class="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                Shipping Info
              </h2>
            </div>

            <div className="flex mt-[-65px] mb-10 justify-end items-end">
              <span className="text-gray-700 font-semibold">Manual</span>
              <label className="switch-toggle ml-4">
                
                <input
                  type="checkbox"
                  onChange={handleAddressToggle}
                  checked={isSameAsAboveSelected}
                  id="sameAsAbove"
                  name="sameAsAbove"
                />
                <span className="slider-toggle round"></span>
              </label>
              <span className="ml-4 font-semibold">Same as above</span>
            </div>

            <div class="grid gap-4 sm:grid-cols-3 sm:gap-6">
                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingTo"
                        class="block mb-2 text-base font-medium text-gray-900 "
                      >
                        Shipping To
                      </label>
                      <CustomTooltip1
                        content={shippingToNameError}
                        show={!!shippingToNameError}
                        marginLeft={308}
                        />
                      

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter contact person name who will receive the delivery"
                        type="text"
                        id="shippingTo"
                        name="shippingTo"
                        value={isSameAsAboveSelected ? shippingInfo.contactPersonName : formData.shippingTo }
                        onChange={isSameAsAboveSelected ? handleShippingToChange : handleShippingToChangeSecond }
                        required
                      />
                    </div>

                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingContactNumber"
                        class="block mb-2 text-base font-medium text-gray-900 "
                      >
                        Contact Number
                      </label>
                      <CustomTooltip1
                        content={inputPhoneError}
                        show={!!inputPhoneError}
                        marginLeft={344}
                      />
                      {invalidShippingPhoneNumber === "Invalid number" && !inputPhoneError &&  (
                        <CustomTooltip1
                          content={"Invalid Number"}
                          show={"Invalid Number"}
                          marginLeft={163}
                        />
                          )} 

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter Contact Number"
                        type="text"
                        id="shippingContactNumber"
                        name="shippingContactNumber"
                        value={isSameAsAboveSelected ? shippingInfo.contactPersonPhoneNumber : formData.shippingContactNumber }
                        onChange={isSameAsAboveSelected ? handleShippingInputPhoneChange : handleShippingInputPhoneChangeSecond}
                        required
                      />
                    </div>

                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingAddress"
                        class="block mb-2 text-base font-medium text-gray-900"
                      >
                        Shipping Address
                      </label>
                      <CustomTooltip1
                        content={shippingAddNameError}
                        show={!!shippingAddNameError}
                        marginLeft={43}
                      />

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter Shipping Address"
                        type="text"
                        id="shippingAddress"
                        name="shippingAddress"
                        value={isSameAsAboveSelected ? shippingInfo.billingAddress : formData.shippingAddress }
                        onChange={(e) => {
                          setIsShippingInfoModified(true);
                          setIsBusinessInfoModified(false)
                          setIsContactInfoModified(false)
                          setIsBillingInfoModified(false)
                          setIsPaymentInfoModified(false)
                          setShippingNotification(true)
                          const inputValue = e.target.value;
                      
                          // Allow only commas, numbers, and text
                          const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');

                          isSameAsAboveSelected 
                          ? setShippingInfo((prevShippingInfo) => ({
                              ...prevShippingInfo,
                              billingAddress: sanitizedValue
                            }))
                          : setFormData((prevFormData) => ({
                              ...prevFormData,
                              shippingAddress: sanitizedValue
                            }));
                            
                      }}
                        required
                      />
                    </div>
              </div>

              <div class="grid gap-4 sm:grid-cols-4 sm:gap-6 mt-6">
                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingCity"
                        class="block mb-2 text-base font-medium text-gray-900 "
                      >
                        City
                      </label>
                      <CustomTooltip1
                        content={shippingCityError}
                        show={!!shippingCityError}
                        marginLeft={67}
                      />

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter City"
                        type="text"
                        id="shippingCity"
                        name="shippingCity"
                        value={isSameAsAboveSelected ? shippingInfo.billingCity : formData.shippingCity}
                        onChange={isSameAsAboveSelected ? handleShippingCityChange : handleShippingCityChangeSecond}
                        required
                      />
                    </div>

                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingState"
                        class="block mb-2 text-base font-medium text-gray-900 "
                      >
                        State
                      </label>
                      <select
                        id="shippingState"
                        name="shippingState"
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        value={isSameAsAboveSelected ? shippingInfo.billingState : formData.shippingState}
                        onChange={(e) => {
                          setIsShippingInfoModified(true);
                          setIsBusinessInfoModified(false)
                          setIsContactInfoModified(false)
                          setIsBillingInfoModified(false)
                          setIsPaymentInfoModified(false)
                          setShippingNotification(true)
                          const inputValue = e.target.value;
                          isSameAsAboveSelected 
                          ? setShippingInfo((prevShippingInfo) => ({
                              ...prevShippingInfo,
                              billingState : inputValue
                            }))
                          : setFormData((prevFormData) => ({
                              ...prevFormData,
                              shippingState : inputValue
                            }));
                      }}
                        // onChange={handleChange}
                        required
                      >
                        <option value="">Select State</option>
                        <option value="AL">Alabama (AL)</option>
                        <option value="AK">Alaska (AK)</option>
                        <option value="AZ">Arizona (AZ)</option>
                        <option value="AR">Arkansas (AR)</option>
                        <option value="CA">California (CA)</option>
                        <option value="CO">Colorado (CO)</option>
                        <option value="CT">Connecticut (CT)</option>
                        <option value="DE">Delaware (DE)</option>
                        <option value="FL">Florida (FL)</option>
                        <option value="GA">Georgia (GA)</option>
                        <option value="HI">Hawaii (HI)</option>
                        <option value="ID">Idaho (ID)</option>
                        <option value="IL">Illinois (IL)</option>
                        <option value="IN">Indiana (IN)</option>
                        <option value="IA">Iowa (IA)</option>
                        <option value="KS">Kansas (KS)</option>
                        <option value="KY">Kentucky (KY)</option>
                        <option value="LA">Louisiana (LA)</option>
                        <option value="ME">Maine (ME)</option>
                        <option value="MD">Maryland (MD)</option>
                        <option value="MA">Massachusetts (MA)</option>
                        <option value="MI">Michigan (MI)</option>
                        <option value="MN">Minnesota (MN)</option>
                        <option value="MS">Mississippi (MS)</option>
                        <option value="MO">Missouri (MO)</option>
                        <option value="MT">Montana (MT)</option>
                        <option value="NE">Nebraska (NE)</option>
                        <option value="NV">Nevada (NV)</option>
                        <option value="NH">New Hampshire (NH)</option>
                        <option value="NJ">New Jersey (NJ)</option>
                        <option value="NM">New Mexico (NM)</option>
                        <option value="NY">New York (NY)</option>
                        <option value="NC">North Carolina (NC)</option>
                        <option value="ND">North Dakota (ND)</option>
                        <option value="OH">Ohio (OH)</option>
                        <option value="OK">Oklahoma (OK)</option>
                        <option value="OR">Oregon (OR)</option>
                        <option value="PA">Pennsylvania (PA)</option>
                        <option value="RI">Rhode Island (RI)</option>
                        <option value="SC">South Carolina (SC)</option>
                        <option value="SD">South Dakota (SD)</option>
                        <option value="TN">Tennessee (TN)</option>
                        <option value="TX">Texas (TX)</option>
                        <option value="UT">Utah (UT)</option>
                        <option value="VT">Vermont (VT)</option>
                        <option value="VA">Virginia (VA)</option>
                        <option value="WA">Washington (WA)</option>
                        <option value="WV">West Virginia (WV)</option>
                        <option value="WI">Wisconsin (WI)</option>
                        <option value="WY">Wyoming (WY)</option>
                      </select>
                    </div>
                    
                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingZipCode"
                        class="block mb-2 text-base font-medium text-gray-900 "
                      >
                        Zip Code
                      </label>
                      <CustomTooltip1
                        content={shippingZipCodeError}
                        show={!!shippingZipCodeError}
                        marginLeft={79}
                      />
                      {invalidShippingZipCode === "Invalid number" && !shippingZipCodeError &&  (
                      <CustomTooltip1
                        content={"Invalid ZipCode"}
                        show={"Invalid ZipCode"}
                        marginLeft={163}
                      />
                      )} 

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter Zipcode"
                        type="text"
                        id="shippingZipCode"
                        name="shippingZipCode"
                        value={isSameAsAboveSelected ? shippingInfo.billingZipCode : formData.shippingZipCode}
                        onChange={isSameAsAboveSelected ? handleShippingZipChange : handleShippingZipChangeSecond}
                        required
                      />
                      {/* {shippingZipCodeError && (
                        <div className="text-red-500 text-base mt-1">
                          {shippingZipCodeError}
                        </div>
                      )} */}
                    </div>

                    <div class="w-full sm:grid-cols-span-3">
                      <label
                        htmlFor="shippingCounty"
                        class="block mb-2 text-base font-medium text-gray-900 "
                      >
                        Country
                      </label>
                      {/* <CustomTooltip1
                        content={shippingZipCodeError}
                        show={!!shippingZipCodeError}
                        marginLeft={79}
                      />
                      {invalidShippingZipCode === "Invalid number" && !shippingZipCodeError &&  (
                      <CustomTooltip1
                        content={"Invalid ZipCode"}
                        show={"Invalid ZipCode"}
                        marginLeft={163}
                      />
                      )}  */}

                      <input
                        className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder="Enter County"
                        type="text"
                        id="shippingCounty"
                        name="shippingCounty"
                        value={formData.shippingCounty}
                        onChange={(e)=>{handleShippingCountyChange(e)}}
                        required
                      />
                      {/* {shippingZipCodeError && (
                        <div className="text-red-500 text-base mt-1">
                          {shippingZipCodeError}
                        </div>
                      )} */}
                    </div>
              </div>


              <div className="sm:col-span-4 mb-8 mt-6">
                <label
                  htmlFor="shippingDeliveryInstructions"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Shipping Instructions
                </label>
                <CustomTooltip1
                  content={shippingDIError}
                  show={!!shippingDIError}
                  marginLeft={824}
                />

                <textarea
                  rows="4"
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter Shipping Instructions if any..."
                  id="shippingDeliveryInstructions"
                  name="shippingDeliveryInstructions"
                  value={formData.shippingDeliveryInstructions}
                  onChange={(e) => {
                    console.log('nameeeeeeeeee', e.target.value);
                    setIsShippingInfoModified(true);
                    setIsBusinessInfoModified(false)
                    setIsContactInfoModified(false)
                    setIsBillingInfoModified(false)
                    setIsPaymentInfoModified(false)
                    setShippingNotification(true)
                    const inputValue = e.target.value;
                
                    // Allow only commas, numbers, and text
                    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9, ]/g, '');
                
                    // Update the state with the sanitized value
                    setFormData((prevData) => ({
                      ...prevData,
                      shippingDeliveryInstructions: sanitizedValue,
                    }));
                    // setFormData({
                    //   ...formData,
                    //   shippingDeliveryInstructions: sanitizedValue,
                    // });
                  }}
                ></textarea>
              </div>

           

            <div className="flex justify-end space-x-3">
              {isShippingInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 ||
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== "" || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    } 
                    
                  >
                    Done
                  </button>
                </>
              ) : null}
            </div>
          </div>

          <div className="w-auto bg-white rounded-box  mx-8 rounded-xl mt-16">
            <div className="flex justify-between">
              <div className="flex items-center">
                <MdOutlinePayment className="text-gray-700 w-6 h-6 mr-2 mb-10" />
                <h2 className="mb-10 text-xl font-bold text-gray-900 underline underline-offset-8 decoration-gray-300">
                  Payment Info
                </h2>
              </div>
              <div className="flex items-center mb-6">
                <span className="text-gray-700 font-semibold">Bank</span>
                <label className="switch-toggle ml-4">
                  <input
                    type="checkbox"
                    onChange={handleToggle}
                    checked={isCardSelected}
                    id="paymentType"
                    name="paymentType"
                  />
                  <span className="slider-toggle round"></span>
                </label>
                <span className="ml-4 font-semibold">Card</span>
              </div>
            </div>

            {isCardSelected ? (
              <div class="grid gap-4 sm:grid-cols-4 sm:gap-6">
                <div class="w-full sm:col-span-1">
                  <label
                    htmlFor="cardPaymentCurrency"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Currency
                  </label>

                  <select
                    id="cardPaymentCurrency"
                    name="cardPaymentCurrency"
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    value={formData.cardPaymentCurrency}
                    onChange={(e)=>{handleInputChange(e, 'cardPaymentCurrency')}}
                    >
                    <option value="">Select Currency</option>
                    <option value="USD">USD ($) - United States dollar</option>
                    <option value="CAD">CAD ($) Canadian dollar</option>
                  </select>
                </div>
                <div class="w-full sm:col-span-1">
                  <label
                    htmlFor="creditCardName"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Credit Card
                  </label>

                  <select
                    id="creditCardName"
                    name="creditCardName"
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                    value={formData.creditCardName}
                    onChange={(e)=>{handleInputChange(e, 'creditCardName')}}
                    >
                    <option value="">Select Card Type</option>
                    <option value="Americal Express">American Express</option>
                    <option value="Bank of America">Bank of America</option>
                    <option value="Barclays">Barclays</option>
                    <option value="Capital One">Capital One</option>
                    <option value="Chase">Chase</option>
                    <option value="Citi">Citi</option>
                    <option value="Discover">Discover</option>
                    <option value="Synchrony">Synchrony</option>
                    <option value="US Bank">U.S. Bank</option>
                    <option value="Wells Fargo">Wells Fargo</option>
                  </select>
                </div>
                <div className="flex sm:col-span-2 gap-3">
                  <div class="w-full">
                    <label
                      htmlfor="nameOnCard"
                      class="block mb-2 text-base font-medium text-gray-900 "
                    >
                      Name on the Card
                    </label>
                    <CustomTooltip1
                      content={cardNameError}
                      show={!!cardNameError}
                      marginLeft={281}
                    />

                    <input
                      type="text"
                      id="nameOnCard"
                      name="nameOnCard"
                      value={formData.nameOnCard}
                      onChange={handlecardChange}
                      class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                      placeholder="Enter exact name shown on the card"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="cardNumber"
                    class="block mb-2 text-base font-medium text-gray-900"
                  >
                    Card #
                  </label>
                  <CustomTooltip1 content={cardError} show={!!cardError}    marginLeft={46}/>
                      {invalidCardNumber === "Invalid number" && !cardError &&  (
                    <CustomTooltip1
                    content={"Invalid Card Number"}
                    show={"Invalid Card Number"}
                    marginLeft={163}
                        />
                    )} 

                  <input
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleCardNumberChange}
                    required
                    className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Card Number"
                  />
                  {/* {cardError && (
                    <div className="text-red-500 text-base mt-1">{cardError}</div>
                  )} */}
                </div>

                <div>
                      <label
                        htmlFor="cardExpiryDate"
                        className="block mb-2 text-base font-medium text-gray-900"
                      >
                        Exp Date
                      </label>
                      <CustomTooltip1
                        content={expirationDateError}
                        show={!!expirationDateError}
                        marginLeft={80}
                      />

                      <input
                          type="text"
                          id="cardExpiryDate"
                          name="cardExpiryDate"
                          value={formData.cardExpiryDate}
                          onChange={handleExpirationDateChange}
                          onFocus={() => setCalendarOpen(true)} // Open calendar on focus
                          // Remove onBlur to avoid closing the calendar when selecting a date
                          className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                          placeholder="MM/YY"
                          required
                        />

                    {calendarOpen && (
                      <Calendar
                        onChange={(date) => {
                          if (date instanceof Date && !isNaN(date)) {
                            const formattedDate = date.toLocaleDateString("en-US", {
                              year: "2-digit",
                              month: "2-digit",
                            });
                            handleExpirationDateChange({ target: { name: "cardExpiryDate", value: formattedDate } });
                          }
                        }}
                        value={isValidDate(formData.cardExpiryDate) ? new Date(formData.cardExpiryDate) : null}
                        showYearDropdown
                        showMonthYearDropdown
                        yearDropdownItemNumber={10}
                        minDate={new Date()} // Set the minimum date to the current date
                        onClickDay={() => setCalendarOpen(false)} // Close calendar when a day is clicked
                      />
                    )}

                    </div>

                <div>
                  <label
                    htmlFor="cardCVV"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    CVV #
                  </label>
                  <CustomTooltip1 content={cardCvv} show={!!cardCvv}    marginLeft={90}/>
                  {invalidCardCvv === "Invalid number" && !cardCvv &&  (
                    <CustomTooltip1
                      content={"Invalid Cvv"}
                      show={"Invalid Cvv"}
                      marginLeft={163}
                    />
                    )} 

                  <input
                    type="text"
                    id="cardCVV"
                    name="cardCVV"
                    value={formData.cardCVV}
                    onChange={handleCardCvvChange}
                    className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter CVV"
                    required
                  />
                  {/* {cardCvv && (
                    <div className="text-red-500 text-base mt-1">{cardCvv}</div>
                  )} */}
                </div>

                <div>
                  <label
                    htmlFor="cardZipCode"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Zip Code
                  </label>
                  <CustomTooltip1
                    content={cardZipCodeError}
                    show={!!cardZipCodeError}
                    marginLeft={80}
                  />
                   {invalidCardZipCode === "Invalid number" && !cardZipCodeError &&  (
                      <CustomTooltip1
                        content={"Invalid Number"}
                        show={"Invalid Number"}
                        marginLeft={163}
                      />
                  )} 

                  <input
                    type="text"
                    id="cardZipCode"
                    name="cardZipCode"
                    required
                    value={formData.cardZipCode}
                    onChange={handleCardZipChange}
                    class="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Zip Code"
                  />
                  {/* {cardZipCodeError && (
                    <div className="text-red-500 text-base mt-1">
                      {cardZipCodeError}
                    </div>
                  )} */}
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-6">
                <div class="w-full sm:col-span-2">
                  <label
                    htmlFor="bankCurrency"
                    className="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Currency
                  </label>

                  <select
                    id="bankCurrency"
                    name="bankCurrency"
                    className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    value={formData.bankCurrency}
                    onChange={(e)=>{handleInputChange(e, 'bankCurrency')}}
                    >
                    <option value="USD">USD ($) - United States dollar</option>
                    <option value="CAD">CAD ($) Canadian dollar</option>
                  </select>
                </div>
                <div class="w-full">
                  <label
                    htmlFor="bankRouting"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Bank Routing #
                  </label>
                  <CustomTooltip1
                    content={bankRoutingError}
                    show={!!bankRoutingError}
                    marginLeft={334}
                  />
                   {invalidBankRoutingNumber === "Invalid number" && !bankRoutingError &&  (
                      <CustomTooltip1
                        content={"Invalid Number"}
                        show={"Invalid Number"}
                        marginLeft={163}
                      />
                  )} 

                  <input
                    className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Routing Number"
                    type="text"
                    id="bankRouting"
                    name="bankRouting"
                    value={formData.bankRouting}
                    onChange={handleBankRoutingChange}
                    required
                  />
                  {/* {bankRoutingError && (
                    <div className="text-red-500 text-base mt-1">
                      {bankRoutingError}
                    </div>
                  )} */}
                </div>
                <div class="w-full">
                  <label
                    htmlFor="bankAccount"
                    class="block mb-2 text-base font-medium text-gray-900 "
                  >
                    Bank Account #
                  </label>
                  <CustomTooltip1
                    content={bankAccountError}
                    show={!!bankAccountError}
                    marginLeft={300}
                  />
                  {invalidBankAccountNumber === "Invalid number" && !bankAccountError &&  (
                  <CustomTooltip1
                    content={"Invalid Number"}
                    show={"Invalid Number"}
                    marginLeft={163}
                  />
                    )} 

                  <input
                    className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                    placeholder="Enter Account Number"
                    type="text"
                    id="bankAccount"
                    name="bankAccount"
                    value={formData.bankAccount}
                    onChange={handleBankAccountChange}
                    required
                  />
                  {/* {bankAccountError && (
                    <div className="text-red-500 text-base mt-1">
                      {bankAccountError}
                    </div>
                  )} */}
                </div>
              </div>
            )}
            <div className="flex justify-end space-x-3">
              {isPaymentInfoModified ? (
                <>
                  <button
                    onClick={handleCancel}
                    type="submit"
                    class="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleEditClick}
                    type="submit"
                    class="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 mt-4 sm:mt-6 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white  rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800"
                    disabled={
                      !formData.businessAccount ||
                      formData.businessAccount.length < 3  ||
                      !formData.businessName ||
                      formData.businessName.length < 3 ||
                      !formData.businessAddress ||
                      formData.businessAddress.length < 3 ||
                      !formData.businessCity ||
                      formData.businessCity.length < 3 ||
                      formData.businessState === "" ||
                      !formData.businessZipCode ||
                      formData.businessZipCode.length < 5 ||
                      !formData.contactPersonEmail ||
                      !formData.contactPersonEmail.includes(".") ||
                      !formData.contactPersonEmail.includes("@") ||
                      emailError ||
                      !formData.contactPersonName ||
                      formData.contactPersonName.length < 3 ||
                      !formData.contactPersonPhoneNumber ||
                      formData.contactPersonPhoneNumber.length < 13 ||
                      !formData.billingAddress ||
                      formData.billingAddress.length < 3 ||
                      !formData.billingCity ||
                      formData.billingCity.length < 3 ||
                      formData.billingState === "" ||
                      !formData.billingZipCode ||
                      formData.billingZipCode.length < 5 || 
                      (formData && formData.bankRouting && formData.bankRouting.length > 0 && formData.bankRouting.length < 9) ||
                      cardCvv !== ""  || cardError !== "" || cardNameError !== "" || invalidBusinessZipCode === "Invalid number"
                      || invalidPhoneNumber === "Invalid number" || invalidBillingZipCode === "Invalid number" || invalidShippingPhoneNumber === "Invalid number"
                      || invalidBankRoutingNumber === "Invalid number" || invalidBankAccountNumber === "Invalid number" ||
                      invalidCardNumber === "Invalid number" || invalidCardCvv === "Invalid number" || invalidCardZipCode === "Invalid number"
                    }
                    
                    
                  >
                    Done
                  </button>
                </>
              ) : null }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCustomer;


