import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DatePicker2 from "./DatePicker2";
import config  from "../../config/config"



function TableMenu({ onStatusChange, onSearchSubmit, onSearch }) {
  const [productOptions, setProductOptions] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;

  const businessId = localStorage.getItem("business_id")
  useEffect(() => {
    async function fetchProductOptions() {
      try {
        const response = await axios.get(
          `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
        );
        setProductOptions(response.data.customers_data);
      } catch (error) {
        console.error("Error fetching product options:", error);
      }
    }

    fetchProductOptions();
  }, []);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);  
    // onStatusChange(event.target.value);
    console.log("status change" , event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log("search change" , event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    onSearchSubmit(searchQuery);
    console.log("search submit change" , event.target.value);
  };

  const handleCustomerChange = (event) => {
    console.log("customer change is ", event.target.value);
    setSelectedCustomerId(event.target.value);
    
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
    onSearch(searchQuery);
    console.log("search query ", searchQuery);
  };


  
  return (
    <div className="flex justify-between gap-2 items-center w-full px-10 mt-10">
      <div className="flex-grow">
        <select
          id="customerId"
          value={selectedCustomerId}
          onChange={handleCustomerChange}
          className=" border border-gray-300 text-sm font-medium rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full text-gray-500 p-2.5"
        >
          <option value="">All Customers</option>
          {productOptions.map((customer) => (
            <option key={customer.customer_id} value={customer.customer_id}>
              {customer.business_name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          id="category"
          value={selectedStatus}
          onChange={handleStatusChange}
          className="cursor-pointer font-medium border-gray-300 h-[41px] text-gray-500 text-sm rounded-lg focus:ring-0 focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-fit p-2"
        >
          <option value="All">All statuses</option>
          <option value="draft">Draft</option>
          <option value="approved">Approved</option>
          {/* <option value="4">Unsent</option> */}
          <option value="sent">Sent</option>
          <option value="partialpaid">Partial Paid</option>
          {/* <option value="5">Overdue</option> */}
        </select>
      </div>
      <div className="flex">
     <DatePicker2/>
       
      </div>
      <div className="flex items-center border border-gray-300 rounded-lg hover:border-[#3479E0]">
        <div className="relative w-full h-full">
          <input
            type="search"
            id="search-dropdown"
            className="block p-2.5 w-full z-20 text-sm text-gray-900 rounded-lg border-0 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700 focus:ring-0 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
            placeholder="Enter invoice #"
            onChange={handleSearch}
            // onChange={handleSearchChange}
          /> 
          <button
            type="submit"
            className="absolute bottom-[2.4px] right-0.5 p-2.5 text-sm font-medium h-[35px] text-white bg-gray-300 rounded-lg border   focus:ring-0 dark:bg-blue-600 dark:hover-bg-blue-700 dark:focus-ring-blue-800"
          >
            <svg
              className="w-4 h-4 text-black "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
            <span className="sr-only">Search</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default TableMenu;
