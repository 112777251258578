// import React, { useState ,useEffect} from "react";
// import { NavLink } from "react-router-dom";
// import logo from "../../assets/images/logo.png";
// import logo2 from "../../assets/images/logo2.png";
// import axios from "axios";
// import { AiOutlineHome, AiFillHome, AiFillPieChart, AiOutlinePieChart, AiFillAccountBook, AiOutlineAccountBook } from "react-icons/ai";
// import { FaBalanceScale } from "react-icons/fa";
// import { useLocation } from "react-router-dom";
// import { FaRegMessage , FaMessage } from "react-icons/fa6";
// import { routes } from "../../components/constants/routes.constants";
// import { menuList } from "../../components/constants/menu.constants";
// import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
// import { BsFileEarmarkRichtext, BsFileEarmarkRichtextFill } from "react-icons/bs";
// import { IoStatsChart } from "react-icons/io5";
// import { IoStatsChartOutline } from "react-icons/io5";
// import { MdOutlineBusinessCenter } from "react-icons/md";
// import { MdBusinessCenter } from "react-icons/md";
// import Shop2OutlinedIcon from '@mui/icons-material/Shop2Outlined';
// import Shop2Icon from '@mui/icons-material/Shop2';
// import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import { MdOutlinePayments } from "react-icons/md";
// import { MdPayments } from "react-icons/md";
// import { RiLuggageDepositFill } from "react-icons/ri";
// import { RiLuggageDepositLine } from "react-icons/ri";
// import DescriptionIcon from '@mui/icons-material/Description';
// import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// import config  from "../../config/config"
// function SideBar({ isOpen }) {
//   const location = useLocation();
//   const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
//   const user_id = localStorage.getItem("user_id")
//   const user_role = localStorage.getItem("user_role")
//   const business_id = localStorage.getItem("business_id")
//   const managerId = localStorage.getItem("user_id")
//   const [imageSource , setImageSource] = useState()

//   const [hoveredMenu, setHoveredMenu] = useState(null);

//   const handleMouseEnter = (index) => {
//     setHoveredMenu(index);
//   };

//   const handleMouseLeave = () => {
//     setHoveredMenu(null);
//   };

//   const isActive = (path) => {
//     if (path === routes.HOME) {
//       return location.pathname === path;
//     } else if(path.includes('invoice')){
//       return location.pathname.includes('invoice');
//     }else {
//       return location.pathname.startsWith(path);
//     }
//   }

//   const [userRole , setUserRole] = useState("")

//   useEffect(()=>{
//     setUserRole(localStorage.getItem("user_role"));
//   },[])

//   const menusForAdmin = [
//     { name: menuList.HOME,
//        path: routes.HOME,
//        icon: hoveredMenu === 0 || isActive(routes.HOME) 
//        ? <AiFillHome size={20} /> 
//        : <AiOutlineHome size={20} />
//     },

//      { name: menuList.BUSINESS,
//        path: routes.ADMIN_BUSINESS_DASHBOARD,
//         icon: hoveredMenu === 2|| isActive(routes.ADMIN_BUSINESS_DASHBOARD) 
//         ? <MdBusinessCenter size={20} /> 
//         : <MdOutlineBusinessCenter size={20} />
//      },
//      { name: menuList.CUSTOMERS,
//         path: routes.USERS_DASHBOARD ,
//         icon: hoveredMenu === 2|| isActive(routes.CUSTOMERS) 
//          ? <HiUserGroup size={20} /> 
//          : <HiOutlineUserGroup size={20} />
//        } ,
       
//       { name: menuList.NOTIFICATIONS,
//         path: routes.NOTIFICATIONS, 
//         icon: hoveredMenu === 3|| isActive(routes.NOTIFICATIONS)
//           ? <FaMessage    size={20} /> 
//           : <FaRegMessage  size={20} />
//       },
  
//       { name: menuList.REPORTS,
//         path: routes.REPORTS, 
//         icon: hoveredMenu === 3|| isActive(routes.REPORTS)
//           ? <AiFillPieChart size={20} /> 
//           : <AiOutlinePieChart size={20} />
//       },
//     { name: menuList.ACCOUNTING,
//        path: routes.ACCOUNTING,
//         icon: hoveredMenu === 4|| isActive(routes.ACCOUNTING) 
//        ? <FaBalanceScale  size={20} />
//         : <FaBalanceScale size={20} />
//     },
//     { name: menuList.ANALYTICS,
//        path: routes.ANALYTICS, 
//        icon: hoveredMenu === 5|| isActive(routes.ANALYTICS) 
//        ? <IoStatsChart size={20} /> 
//        : <IoStatsChartOutline size={20} />
//     },
//     { name: menuList.DOCUMENTS,
//       path: routes.DOCUMENTS, 
//       icon: hoveredMenu === 3|| isActive(routes.DOCUMENTS)
//        ? <DescriptionIcon  size={20} /> 
//        : <DescriptionOutlinedIcon  size={20} />
//    },

//   ];
  
//   const menusForRetail = [
//     { name: menuList.HOME,
//        path: routes.HOME,
//        icon: hoveredMenu === 0 || isActive(routes.HOME) 
//        ? <AiFillHome size={20} /> 
//        : <AiOutlineHome size={20} />
//     },

//      userRole === '3' ? 
//     {
//        name: menuList.INVOICE, 
//       path: routes.INVOICE,
//        icon: hoveredMenu === 1 || isActive(routes.INVOICE)
//        ? <BsFileEarmarkRichtextFill size={20} />
//         : <BsFileEarmarkRichtext size={20} />
//      } : ""
//      ,

//      userRole === '1' ?
//      { name: menuList.BUSINESS,
//        path: routes.ADMIN_BUSINESS_DASHBOARD,
//         icon: hoveredMenu === 2|| isActive(routes.ADMIN_BUSINESS_DASHBOARD) 
//         ? <MdBusinessCenter size={20} /> 
//         : <MdOutlineBusinessCenter size={20} />
//      } : "",

//      userRole !== '2' ?
//     { name: menuList.CUSTOMERS,
//       path: userRole === '1' ? routes.USERS_DASHBOARD : routes.CUSTOMERS,
//        icon: hoveredMenu === 2|| isActive(routes.CUSTOMERS) 
//        ? <HiUserGroup size={20} /> 
//        : <HiOutlineUserGroup size={20} />
//     } : ""
//     ,
//     { name: menuList.REPORTS,
//        path: routes.REPORTS, 
//        icon: hoveredMenu === 3|| isActive(routes.REPORTS)
//         ? <AiFillPieChart size={20} /> 
//         : <AiOutlinePieChart size={20} />
//     },
//     { name: menuList.ACCOUNTING,
//        path: routes.ACCOUNTING,
//         icon: hoveredMenu === 4|| isActive(routes.ACCOUNTING) 
//        ? <FaBalanceScale  size={20} />
//         : <FaBalanceScale size={20} />
//     },
//     { name: menuList.ANALYTICS,
//        path: routes.ANALYTICS, 
//        icon: hoveredMenu === 5|| isActive(routes.ANALYTICS) 
//        ? <IoStatsChart size={20} /> 
//        : <IoStatsChartOutline size={20} />
//     },

//   ];

//   const menusForGas = [
//     { 
//       name: menuList.HOME,
//        path: routes.HOME,
//        icon: hoveredMenu === 0 || isActive(routes.HOME) 
//        ? <AiFillHome size={20} /> 
//        : <AiOutlineHome size={20} />
//     },
//     { name: menuList.PURCHASES,
//       path: routes.PURCHASES,
//       icon: hoveredMenu === 0 || isActive(routes.PURCHASES) 
//       ? <Shop2Icon style={{ fontSize: 20 }} />
//       : <Shop2OutlinedIcon style={{ fontSize: 20 }} />
//    },
//    { name: menuList.EXPENSES,
//     path: routes.EXPENSES,
//     icon: hoveredMenu === 0 || isActive(routes.EXPENSES) 
//     ? <MonetizationOnIcon   size={20} /> 
//     : <MonetizationOnOutlinedIcon  size={20} />
//  },
//     {
//        name: menuList.SALES, 
//       path: routes.INVOICE,
//        icon: hoveredMenu === 1 || isActive(routes.INVOICE) || isActive(routes.VIEW_INVOICE_FORM) || isActive(routes.INVOICE_FORM_TWO) || isActive(routes.VIEW_INVOICE_FORM_TWO)
//        ? <BsFileEarmarkRichtextFill size={20} />
//         : <BsFileEarmarkRichtext size={20} />
//      }
//      ,
//      { name: menuList.PAYMENTS,
//       path: routes.PAYMENTS,
//       icon: hoveredMenu === 0 || isActive(routes.PAYMENTS) 
//       ? <MdPayments  size={20} /> 
//       : <MdOutlinePayments  size={20} />
//    },
//     { name: menuList.DEPOSITS,
//       path: routes.DEPOSITS,
//       icon: hoveredMenu === 0 || isActive(routes.DEPOSITS) 
//       ? <RiLuggageDepositFill    size={20} /> 
//       : <RiLuggageDepositLine   size={20} />
//   },

//     //  { name: menuList.BUSINESS,
//     //    path: routes.ADMIN_BUSINESS_DASHBOARD,
//     //     icon: hoveredMenu === 2|| isActive(routes.ADMIN_BUSINESS_DASHBOARD) 
//     //     ? <MdBusinessCenter size={20} /> 
//     //     : <MdOutlineBusinessCenter size={20} />
//     //  },

//     // { name: menuList.CUSTOMERS,
//     //   path: userRole === '1' ? routes.USERS_DASHBOARD : routes.CUSTOMERS,
//     //    icon: hoveredMenu === 2|| isActive(routes.CUSTOMERS) 
//     //    ? <HiUserGroup size={20} /> 
//     //    : <HiOutlineUserGroup size={20} />
//     // } ,

//     { name: menuList.REPORTS,
//        path: routes.REPORTS, 
//        icon: hoveredMenu === 3|| isActive(routes.REPORTS)
//         ? <AiFillPieChart size={20} /> 
//         : <AiOutlinePieChart size={20} />
//     },
//     { name: menuList.DOCUMENTS,
//       path: routes.DOCUMENTS, 
//       icon: hoveredMenu === 3|| isActive(routes.DOCUMENTS)
//        ? <DescriptionIcon  size={20} /> 
//        : <DescriptionOutlinedIcon  size={20} />
//    },
//   ];

//   const filteredMenus = userRole === "2"
//   ? menusForRetail.filter(menuItem => menuItem !== "" && menuItem !== undefined)
//   : userRole === "3"
//   ? menusForGas.filter(menuItem => menuItem !== "" && menuItem !== undefined)
//   : userRole === "1"
//   ? menusForAdmin.filter(menuItem => menuItem !== "" && menuItem !== undefined)
//   : [];

//   useEffect(() => {
//     axios
//       .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
//       .then((response) => {
//         console.log("response businesstype" , response);
//         setImageSource(response.data.business_data[0].image_file)
//       })
//       .catch((error) => {
//         console.error("Error fetching business data:", error);
       
//       });
//   },[] );


//   return (
//     <div className="flex h-full sticky top-0">
//       <aside
//         aria-hidden={isOpen}
//         className={` bg-[white] shadow-md h-screen ${
//           isOpen ? "w-44" : "w-16"
//         } duration-500 text-black-100 px-4`}
//       >
//         <div className="bg-white h-10 mt-3 mb-7 flex justify-center items-center">
//           <img
//             src={isOpen ? logo : logo2}
//             alt="flownia"
//             className={`${isOpen ? "w-max" : "w-14"}`}
//           />
//         </div>

//         <div className="mt-5 flex flex-col gap-3 relative">
//           {filteredMenus?.map((menu, i) => (
//             <NavLink
//               to={menu?.path}
//               key={i}
//               onMouseEnter={() => handleMouseEnter(i)}
//               onMouseLeave={handleMouseLeave}
//               className={`
//                 group flex items-center text-[15px] gap-3.5 font-semibold p-2 rounded-md hover:bg-gray-100 hover:border  hover:border-gray-500/6 hover:text-[#3479E0] 
//                 ${
//                   isActive(menu.path)
//                     ? "bg-gray-100 border border-gray-500/6 text-[#3479E0] font-semibold"
//                     : "text-black"
//                 }
//               `}
//               activeClassName="bg-red-500 text-white border border-gray-500/6"
//             >
//               <div>{menu.icon}</div>
//               <h2
//                 className={`whitespace-pre ${
//                   !isOpen && "opacity-0 translate-x-28 overflow-hidden"
//                 }`}
//               >
//                 {menu?.name}
//               </h2>
//               <h2
//                 className={`${
//                   isOpen && "hidden"
//                 } absolute left-0 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-12 group-hover:duration-300 group-hover:w-fit`}
//               >
//                 {menu?.name}
//               </h2>
//             </NavLink>
//           ))}
//         </div>

//           {userRole !== "1" && (
//          <div className="mt-5">
//               {imageSource ? (
//                 <img 
//                   className="w-full h-12 pl-2 pr-2"
//                   src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
//               ) : (
//                 <p>No image selected</p>
//               )}
//           </div>
//           )}
             

//       </aside>
//     </div>
//   );
// }

// export default SideBar;











import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoHeader from "../../assets/images/logoHeader.png";
import logo2 from "../../assets/images/logo2.png";
import axios from "axios";
import {
  AiOutlineHome,
  AiFillHome,
  AiFillPieChart,
  AiOutlinePieChart,
  AiFillAccountBook,
  AiOutlineAccountBook
} from "react-icons/ai";
import { FaBalanceScale } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { FaRegMessage, FaMessage } from "react-icons/fa6";
import { routes } from "../../components/constants/routes.constants";
import { menuList } from "../../components/constants/menu.constants";
import { HiOutlineUserGroup, HiUserGroup } from "react-icons/hi2";
import { BsFileEarmarkRichtext, BsFileEarmarkRichtextFill } from "react-icons/bs";
import { IoStatsChart, IoStatsChartOutline } from "react-icons/io5";
import { MdOutlineBusinessCenter, MdBusinessCenter } from "react-icons/md";
import Shop2OutlinedIcon from '@mui/icons-material/Shop2Outlined';
import Shop2Icon from '@mui/icons-material/Shop2';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { MdOutlinePayments, MdPayments } from "react-icons/md";
import { RiLuggageDepositFill, RiLuggageDepositLine } from "react-icons/ri";
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import config from "../../config/config"
import {  MenuItem,  Typography,} from "@material-tailwind/react";
import { MdCalculate, MdAttachMoney } from "react-icons/md"; // Billing icons (outline and fill)
import { MdAccountBalance, MdAccountBalanceWallet } from "react-icons/md"; // Accounting icons (outline and fill)
import { MdInsertChart, MdShowChart } from "react-icons/md"; // Analytics icons (outline and fill)
import { AiFillMessage, AiOutlineMessage } from "react-icons/ai"; // Message icons
import { AiFillFolder, AiOutlineFolder } from "react-icons/ai"; // Folder icons
import { AiFillWallet, AiOutlineWallet } from "react-icons/ai"; // Ant Design icons for accounting


function SideBar({ isOpen, handleSignOut }) {
  const location = useLocation();
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const user_id = localStorage.getItem("user_id");
  const user_role = localStorage.getItem("user_role");
  const business_id = localStorage.getItem("business_id");
  const managerId = localStorage.getItem("user_id");
  const [imageSource, setImageSource] = useState();

  const [hoveredMenu, setHoveredMenu] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredMenu(index);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };

  const ReportArray = ['salesTax']
  const isActive = (path) => {
    const currentPath = location.pathname;
  
    // Check for Home route
    if (path === routes.HOME) {
      return currentPath === path;
    }
    else if (path.includes('Operations')) {
      return currentPath === path || currentPath.includes('purchases');
    }
    
    else if (path.includes('Sales')) {
      return currentPath === path || (currentPath.includes( 'invoice') && !currentPath.includes('invoiceReport'));
    }
    
    else if (path.includes('report')) {
      return currentPath === path || currentPath.includes('SalesReport') ||currentPath.includes('invoiceReport');
    }
    else if (path.includes('MyDocs')) {
      return currentPath === path || currentPath.includes('draftAgreemnets') ||currentPath.includes('approvedContracts') ||currentPath.includes('Licenses');
    }

    
    // Check for Accounting route
    else if (path.includes('Accounting')) {
      return currentPath === path || currentPath.includes('salesTax');
    }

    else if (path === currentPath) {
      return true
    }
    // Default case if none of the above matched
    return false;
  };
  
  
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(localStorage.getItem("user_role"));
  }, []);

  const menusForAdmin = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },

    {
      name: menuList.CUSTOMERS,
      path: routes.ADMIN_BUSINESS_DASHBOARD,
      icon: hoveredMenu === 1 || isActive(routes.ADMIN_BUSINESS_DASHBOARD)
        ? <MdBusinessCenter size={20} />
        : <MdOutlineBusinessCenter size={20} />
    },
    // {
    //   name: menuList.CUSTOMERS,
    //   path: routes.USERS_DASHBOARD,
    //   icon: hoveredMenu === 2 || isActive(routes.CUSTOMERS)
    //     ? <HiUserGroup size={20} />
    //     : <HiOutlineUserGroup size={20} />
    // },

    {
      name: menuList.NOTIFICATIONS,
      path: routes.NOTIFICATIONS,
      icon: hoveredMenu === 3 || isActive(routes.NOTIFICATIONS)
        ? <FaMessage size={18} />
        : <FaRegMessage size={18} />
    },

    {
      name: menuList.REPORTS,
      path: routes.REPORTSADMIN,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
    {
      name: menuList.ACCOUNTING,
      path: routes.ACCOUNTINGADMIN,
      icon: hoveredMenu === 5 || isActive(routes.ACCOUNTING)
        ? <FaBalanceScale size={20} />
        : <FaBalanceScale size={20} />
    },
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICSADMIN,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <IoStatsChart size={20} />
        : <IoStatsChartOutline size={20} />
    },
    {
      name: menuList.DOCUMENTS,
      path: routes.DOCUMENTS,
      icon: hoveredMenu === 7 || isActive(routes.DOCUMENTS)
        ? <DescriptionIcon size={20} />
        : <DescriptionOutlinedIcon size={20} />
    },

  ];

  const menusForRetail = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },

    userRole === '3' ?
      {
        name: menuList.INVOICE,
        path: routes.INVOICE,
        icon: hoveredMenu === 1 || isActive(routes.INVOICE)
          ? <BsFileEarmarkRichtextFill size={20} />
          : <BsFileEarmarkRichtext size={20} />
      } : "",

    userRole === '1' ?
      {
        name: menuList.BUSINESS,
        path: routes.ADMIN_BUSINESS_DASHBOARD,
        icon: hoveredMenu === 2 || isActive(routes.ADMIN_BUSINESS_DASHBOARD)
          ? <MdBusinessCenter size={20} />
          : <MdOutlineBusinessCenter size={20} />
      } : "",

    userRole !== '2' ?
      {
        name: menuList.CUSTOMERS,
        path: userRole === '1' ? routes.USERS_DASHBOARD : routes.CUSTOMERS,
        icon: hoveredMenu === 3 || isActive(routes.CUSTOMERS)
          ? <HiUserGroup size={20} />
          : <HiOutlineUserGroup size={20} />
      } : ""
    ,
    {
      name: menuList.REPORTS,
      path: routes.REPORTS,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
    {
      name: menuList.ACCOUNTING,
      path: routes.ACCOUNTING,
      icon: hoveredMenu === 5 || isActive(routes.ACCOUNTING)
        ? <FaBalanceScale size={20} />
        : <FaBalanceScale size={20} />
    },
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICS,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <IoStatsChart size={20} />
        : <IoStatsChartOutline size={20} />
    },

  ];

  const menusForGas = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },
    {
      name: menuList.OPERATIONS,
      path: routes.OPERATIONS,
      icon: hoveredMenu === 1 || isActive(routes.OPERATIONS)
        ? <MdBusinessCenter size={20} />
        : <MdOutlineBusinessCenter size={20} />
    },
    // {
    //   name: menuList.PURCHASES,
    //   path: routes.PURCHASES,
    //   icon: hoveredMenu === 1 || isActive(routes.PURCHASES)
    //     ? <Shop2Icon style={{ fontSize: 20 }} />
    //     : <Shop2OutlinedIcon style={{ fontSize: 20 }} />
    // },
    // {
    //   name: menuList.EXPENSES,
    //   path: routes.EXPENSES,
    //   icon: hoveredMenu === 2 || isActive(routes.EXPENSES)
    //     ? <MonetizationOnIcon size={20} />
    //     : <MonetizationOnOutlinedIcon size={20} />
    // },
    {
      name: menuList.SALES,
      path: routes.SALESHOME,
      icon: hoveredMenu === 2 || isActive(routes.INVOICE) || isActive(routes.VIEW_INVOICE_FORM) || isActive(routes.INVOICE_FORM_TWO) || isActive(routes.VIEW_INVOICE_FORM_TWO)
        ? <BsFileEarmarkRichtextFill size={20} />
        : <BsFileEarmarkRichtext size={20} />
    }
    ,
    {
      name: menuList.BILLING,
      path: routes.BILLING,
      icon: hoveredMenu === 3 || isActive(routes.BILLING) 
              ? <MdAttachMoney size={20} />
        : <MdAttachMoney size={20} />
    }
    ,
    {
      name: menuList.REPORTS,
      path: routes.REPORTS ,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },

    {
      name: menuList.ACCOUNTING,
      path: routes.ACCOUNTING ,
      icon: hoveredMenu === 5 || isActive(routes.ACCOUNTING)
        ? <MdCalculate size={20} />
        : <MdCalculate size={20} />
    },
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICS ,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
    {
      name: menuList.MESSAGES,
      path: routes.MESSAGES ,
      icon: hoveredMenu === 7 || isActive(routes.MESSAGES)
        ? <AiFillMessage size={20} />
        : <AiOutlineMessage size={20} />
    },
    {
      name: menuList.MYDOCS,
      path: routes.MYDOCS ,
      icon: hoveredMenu === 8 || isActive(routes.MYDOCS)
        ? <AiFillFolder size={20} />
        : <AiOutlineFolder size={20} />
    },
 
  ];

  const menusForCustomer = [
    {
      name: menuList.HOME,
      path: routes.HOME,
      icon: hoveredMenu === 0 || isActive(routes.HOME)
        ? <AiFillHome size={20} />
        : <AiOutlineHome size={20} />
    },
    {
      name: menuList.REPORTS,
      path: routes.CUSTOMERREPORTS,
      icon: hoveredMenu === 4 || isActive(routes.REPORTS)
        ? <AiFillPieChart size={20} />
        : <AiOutlinePieChart size={20} />
    },
 
    {
      name: menuList.ANALYTICS,
      path: routes.ANALYTICS,
      icon: hoveredMenu === 6 || isActive(routes.ANALYTICS)
        ? <IoStatsChart size={20} />
        : <IoStatsChartOutline size={20} />
    },
  

  ];

  // Ensure that the filteredMenus logic includes the menus for the Customer role (user_role = '4')
  const filteredMenus = user_role === "2"
    ? menusForRetail.filter(menuItem => menuItem !== "" && menuItem !== undefined)
    : user_role === "3"
      ? menusForGas.filter(menuItem => menuItem !== "" && menuItem !== undefined)
      : user_role === "1"
        ? menusForAdmin.filter(menuItem => menuItem !== "" && menuItem !== undefined)
        : user_role === "5" // Assuming '5' is the Customer role ID
          ? menusForCustomer.filter(menuItem => menuItem !== "" && menuItem !== undefined)
          : [];


  // const filteredMenus = userRole === "2"
  //   ? menusForRetail.filter(menuItem => menuItem !== "" && menuItem !== undefined)
  //   : userRole === "3"
  //     ? menusForGas.filter(menuItem => menuItem !== "" && menuItem !== undefined)
  //     : userRole === "1"
  //       ? menusForAdmin.filter(menuItem => menuItem !== "" && menuItem !== undefined)
  //       : [];

  useEffect(() => {
    axios
      .get(`${baseURL}/${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
      .then((response) => {
        // console.log("response businesstype", response);
        setImageSource(response.data.business_data[0].image_file)
      })
      .catch((error) => {
        console.error("Error fetching business data:", error);

      });
  }, []);


  return (
    <div className="flex h-full sticky top-0">
      <aside
        aria-hidden={isOpen}
        className={` bg-[white] shadow-md h-screen ${isOpen ? "w-44" : "w-16"
          } duration-500 text-black-100 px-4`}
      >
        <div className="bg-white h-10 mt-3 mb-5 flex justify-center items-center">
          <img
           style={{ width: isOpen && '250px',
            height: isOpen && '70px'}}
            src={isOpen ? logoHeader : logo2}
            alt="flownia"
            className={`${isOpen ? "w-max" : "w-14"}`}
          />
        </div>

        <div className="mt-5 flex flex-col gap-2 relative">
          {filteredMenus?.map((menu, i) => (
            <NavLink
              to={menu?.path}
              key={i}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              className={`
                group flex items-center text-[15px] gap-3.5 font-semibold p-2 rounded-md hover:bg-gray-100 hover:border  hover:border-gray-500/6 hover:text-[#3479E0] 
                ${isActive(menu.path)
                  ? "bg-gray-100 border border-gray-500/6 text-[#3479E0] font-semibold"
                  : "text-black"
                }
              `}
              activeClassName="bg-red-500 text-white border border-gray-500/6 "
            >
              <div>{menu.icon}</div>
              <h2
                className={`whitespace-pre ${!isOpen && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
              >
                {menu?.name}
              </h2>
              <h2
                className={`${isOpen && "hidden"
                  } absolute left-0 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-12 group-hover:duration-300 group-hover:w-fit`}
              >
                {menu?.name}
              </h2>
            </NavLink>
          ))}
  <a
  href="#"
  onClick={(e) => {
    e.preventDefault();
    handleSignOut();
  }}  className="flex items-center gap-2 font-bold text-red-500 border-b-2 border-red-500 pb-1 hover:bg-transparent ml-2 pr-2"
  style={{ position: 'fixed', bottom: '30px', width: 'auto', textDecoration: 'none' }}
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={3}  // Increased stroke width for bold icon
    stroke="currentColor"
    className="h-4 w-4 text-red-500"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
    />
  </svg>
  {isOpen &&
  <Typography variant="small" component="span" className="font-bold text-red-500">
    Sign Out
  </Typography>
}
</a>




        </div>
{/* 
        {userRole !== "1" && (
          <div className="mt-5">
            {imageSource ? (
              <img
                className="w-full h-12 pl-2 pr-2"
                src={`${baseURL}uploads/img/business/${imageSource}`} alt="Selected" />
            ) : (
              <p>No image selected</p>
            )}
          </div>
        )} */}


      </aside>
    </div>
  );
}

export default SideBar;

