import React from 'react'
import moment from 'moment'; 
import { useState , useEffect } from 'react';
import axios from "axios";
import { useRef } from 'react';
import {addInvoice} from "../../actions/invoiceActions";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdCalendarMonth } from "react-icons/md";
import config  from "../../config/config"
import ReactDOM from "react-dom"
import { showToastForDeleteInvoice} from '../toasts/toastForInvoice';
// import { showToastForDeleteInvoice } from '../pages/Invoice';
import { showToastForUpdateInvoice } from '../../components/toasts/toastForInvoice';
// import { showToastForUpdateInvoice } from '../pages/Invoice';
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';


import Checkbox from '@mui/material/Checkbox'
const ViewInvoiceFormTwo = () => {

  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const business_id = localStorage.getItem("business_id")
  const user_id = localStorage.getItem("user_id")
  const user_role = localStorage.getItem("user_role")
  const {invoicenumber} = useParams()
  const [invoiceData, setInvoiceData] = useState([]);
  
    const [isInputFocused, setIsInputFocused] = useState(true);
    const today = new Date().toISOString().split("T")[0];
    const [issuedOn, setIssuedOn] = useState(today);
    const [dueOn, setDueOn] = useState(today);
    const [productOptions, setProductOptions] = useState([]);

    const [vehiclesNames , setVehiclesNames] = useState([])
    const [originTerminal , setOriginTerminal] = useState([])
    const [destinationTerminal , setDestinationTerminal] = useState([])
  
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState("");
    const [feesOrDiscounts, setFeesOrDiscounts] = useState("");
    const [total, setTotal] = useState(0);
  
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [comments, setComments] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState(""); 
  
    const [dueDate, setDueDate] = useState(new Date());
    const [createdDate , setCreatedOnDate ] = useState(new Date()); 
    const businessId = localStorage.getItem("business_id")
    
    const [showDropdown, setShowDropdown] = useState(false);
    const [customerOptions , setCustomerOptions] = useState([])
    const [taxTotal ,setTaxTotal] = useState(0); 

    const [surchargePercentage, setSurchargePercentage] = useState(0);
    const [surchargeAmount, setSurchargeAmount] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [surchargeType, setSurchargeType] = useState("percentage");
    const [discountType, setDiscountType] = useState("percentage");

    const [itemData, setItemData] = useState([]);
    const [newTotal, setNewTotal] = useState(0);
    const [taxValues, setTaxValues] = useState({
        freight : "",
        freightSurcharge : "",
        FederalExciseTax : "",
        FederalOilSpil : "",
        NysPrepaid : "",
        NysExcise : "",
        NysPetroleumBusRcvry : "",
        NysPetroleumTestingFee : "",
        NysSpillRecovery : ""
    });  
  
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { invoice_number } = useSelector((state) => state.invoice);

    const [products, setProducts] = useState([
      { 
        productId : "",
        productCode : "",
        quantity: "",
        unitPrice: "",
        total: 0,
      },
    ]);
    // console.log("products", products);

    const numericTaxValues = Object.values(taxValues).map(value => parseFloat(value));
    const avgUnitPrice = numericTaxValues.reduce((acc, value) => acc + value, 0);
    const subTotal = products.reduce((acc, item) => acc + parseFloat(item.total || 0), 0);
    const grandTotal = parseFloat(taxTotal) + parseFloat(subTotal);
    const [finalInvoiceAmount, setFinalInvoiceAmount] = useState(grandTotal);  // Sum of all the tax values
    // console.log('Sum of all tax values:', avgUnitPrice.toFixed(5));
    const [sellerNames , setSellerNames] = useState([])

    const [taxValuesResults, setTaxValuesResults] = useState([
     {
        freight : "",
        freightSurcharge : "",
        FederalExciseTax : "",
        FederalOilSpil : "",
        NysPrepaid : "",
        NysExcise : "",
        NysPetroleumBusRcvry : "",
        NysPetroleumTestingFee : "",
        NysSpillRecovery : "",
        avgUnitPrice : "",
        subTotal : "",
    }
    ])

   
    

    useEffect(() => {
      const total = taxValuesResults.reduce((acc, item) => {
        const subTotalValue = parseFloat(item.subTotal);
        return isNaN(subTotalValue) ? acc : acc + subTotalValue;
      }, 0);
      setNewTotal(total);
    }, [taxValuesResults]);
    // console.log("new total is ", newTotal);
    // console.log("tax value results :" , taxValuesResults);

    const [formField, setFormField] = useState({
      invoice_number: "",
      bill_from: "",
      bill_to: "",
      vehicleNumber : "",
      sellersId :"",

      originTerminal : "",
      destinationTerminal  : "",
      created_on: "",
      due_on: "",
    });
    

  
    const handleChange = (event) => {
      if (event instanceof Date) {
        setCreatedOnDate(event);
      }
      const { name, value } = event.target;

      if (name === "bill_to") {
        setFormField({
          ...formField,
          [name]: value,
        });
      } else {
        setFormField({
          ...formField,
          [name]: value,
        });
      }
    };

    const handleDeliveryDateChange =(date)=>{
      setCreatedOnDate(date);
      setFormField({
        ...formField,
        created_on: date,
      });
    }

    const handleDueDateChange = (date) => {
      setDueDate(date);
  
      setFormField({
        ...formField,
        due_on: date,
      });
    };
  
    const datePickerRef = useRef(null);
    const deliveryDatePickerRef = useRef(null);

    const showDatePicker = () => {
      if (datePickerRef.current) { 
        datePickerRef.current.setOpen(true);
      }
    };
    const handleOuterDivClick = () => {
      // Simulate a click on the input element of the date picker
      if (datePickerRef.current) {
        datePickerRef.current.querySelector('input').click();
      }
    };
    const handleDeliveryDateClick = () => {
      if (deliveryDatePickerRef.current) {
        deliveryDatePickerRef.current.querySelector('input').click();
      }
    };


    useEffect(()=>{
      const fetchVehiclesNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allvehicles")}`)
          setVehiclesNames(response.data.vehicles_data);
        }catch(error){
          console.log("error fetching vehicles names");
        }
      }
      fetchVehiclesNames()
    },[])
    useEffect(()=>{
      const fetchSellersNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allsellers")}`)
          console.log('response', response);
          setSellerNames(response.data);
        }catch(error){
          console.log("error fetching seller names" , error);
        }
      }
      fetchSellersNames()
      console.log('setSellerNames', sellerNames)
    },[])

    useEffect(()=>{
      const fetchTerminalNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allterminals")}`)
          // console.log("ter" , response)
          setOriginTerminal(response.data.terminals_data);
          setDestinationTerminal(response.data.terminals_data);

        }catch(error){
          console.log("error fetching vehicles names");
        }
      }
      fetchTerminalNames()
    },[])

    useEffect(() => {
      window.scrollTo(0, 0);
      axios
        .get(`${baseURL}/${btoa("product/view")}`)
        .then((response) => {
            // console.log("prod" , response.data.products_data);
          setProductOptions( response.data.products_data);
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
        });
    }, []);

    const calculateFinalInvoiceAmount = (grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount) => {
      // console.log('SURCHARGE %', surchargePercentage);
      // console.log('SURCHARGE AMOUNT', surchargeAmount);
      const validGrandTotal = parseFloat(grandTotal) || 0;
      const validSurchargePercentage = parseFloat(surchargePercentage) || 0;
      const validSurchargeAmount = parseFloat(surchargeAmount) || 0;
      const validDiscountPercentage = parseFloat(discountPercentage) || 0;
      const validDiscountAmount = parseFloat(discountAmount) || 0;
      
      const surchargeFromPercentage = (validSurchargePercentage / 100) * validGrandTotal;
      const discountFromPercentage = (validDiscountPercentage / 100) * validGrandTotal;
      const totalSurcharge = surchargeFromPercentage + validSurchargeAmount;
      const totalDiscount = discountFromPercentage + validDiscountAmount;
      
      return validGrandTotal + totalSurcharge - totalDiscount;
    };
    
    const updateFinalInvoiceAmount = (grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount) => {
      // console.log('S AMT', surchargeAmount);
      const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount);
      setFinalInvoiceAmount(newFinalInvoiceAmount.toFixed(2));
    };
    
    // Handler for surcharge percentage change
    const handleSurchargePercentageChange = (e) => {
      const percentage = parseFloat(e.target.value) || 0;
      setSurchargePercentage(percentage);
      setSurchargeAmount(0); // Reset surcharge amount
      updateFinalInvoiceAmount(grandTotal, percentage, 0, discountPercentage, discountAmount);
    };
    
    const handleSurchargeAmountChange = (e) => {
      const amount = parseFloat(e.target.value) || 0;
      setSurchargeAmount(amount);
      setSurchargePercentage(0); // Reset surcharge percentage
      updateFinalInvoiceAmount(grandTotal, 0, amount, discountPercentage, discountAmount);
    };
    
    const handleDiscountPercentageChange = (e) => {
      const percentage = parseFloat(e.target.value) || 0;
      setDiscountPercentage(percentage);
      setDiscountAmount(0); // Reset discount amount
      updateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, percentage, 0);
    };
    
    const handleDiscountAmountChange = (e) => {
      const amount = parseFloat(e.target.value) || 0;
      setDiscountAmount(amount);
      setDiscountPercentage(0); // Reset discount percentage
      updateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, 0, amount);
    };
    
    // Usage example (assuming grandTotal is defined elsewhere)
    useEffect(() => {
      updateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount);
    }, [grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount]);
      

    const handleSurchargeTypeChange = (type) => {
      setSurchargeType(type);
      if (type === "percentage") {
        setSurchargePercentage(0); // Reset value to 0
        setSurchargeAmount(0);
      } else {
        setSurchargeAmount(0); // Reset value to 0
        setSurchargePercentage(0);
      }
      updateFinalInvoiceAmount(grandTotal, 0, 0, discountPercentage, discountAmount);
    };
    
    const handleDiscountTypeChange = (type) => {
      setDiscountType(type);
      if (type === "percentage") {
        setDiscountPercentage(0); // Reset value to 0
        setDiscountAmount(0);
      } else {
        setDiscountAmount(0); // Reset value to 0
        setDiscountPercentage(0);
      }
      updateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, 0, 0);
    };
    
    
    
  
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // console.log("products submitted " , products);
      // console.log("result tax values submitted", taxValuesResults );

      const formattedCreatedDate = formField.created_on instanceof Date && !isNaN(formField.created_on)
      ? formField.created_on.toLocaleDateString('en-US') // Convert to local date string
      : new Date().toLocaleDateString('en-US');


    
    const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
      ? formField.due_on.toLocaleDateString('en-US') // Convert to local date string
      : new Date().toLocaleDateString('en-US');
    
    // const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
    // ? new Date(formField.due_on.getTime() + (24 * 60 * 60 * 1000)).toISOString() // Subtract one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    // : new Date().toISOString();

    // console.log("format edit date", formattedDueDate);
    
      const surchargeValue = surchargeType === "percentage" ? surchargePercentage : surchargeAmount;
      const discountValue = discountType === "percentage" ? discountPercentage : discountAmount;
      // console.log("sutcharge type", surchargeType);
      // console.log("sutcharge type", surchargeType);

      const productId = [];
      const productCode = [];
      const quantities = [];
      const unitPrices = [];
      const unitTotals = [];
      products.forEach((product, index) => {
        if (index < products.length - 1 || product.productId || product.quantity || product.unitPrice) {
          productId.push(product.productId);
          productCode.push(product.productCode)
          quantities.push(product.quantity);
          unitPrices.push(product.unitPrice);
          unitTotals.push(product.total);
        }
      });
      const payload = {
        invoiceNumber: formField.invoice_number,
        businessId: localStorage.getItem("business_id"),
        managerId : localStorage.getItem("user_id"),
        customerId: formField.bill_to,
        invoiceQuantity: totalQuantity,
        deliveryDate : formattedCreatedDate,
        dueDate : formattedDueDate,
        vehicleNumber : formField.vehicleNumber,
        sellersId: formField.sellersId,
        originTerminal : formField.originTerminal,
        destinationTerminal : formField.destinationTerminal,
        subTotalAmount: subTotal,
        salesTaxAmount: taxTotal,
        discountsAmount: "",
        finalTotalAmount: (subTotal + taxTotal),
        comment: "",
        freight: (parseFloat(totalQuantity) * parseFloat(taxValues.freight)).toFixed(5),
        freightSurcharge: (parseFloat(totalQuantity) * parseFloat(taxValues.freight) * parseFloat(taxValues.freightSurcharge)).toFixed(5),
        federalExciseTax: (parseFloat(totalQuantity) * parseFloat(taxValues.FederalExciseTax)).toFixed(5),
        federalOilSpil: (parseFloat(totalQuantity) * parseFloat(taxValues.FederalOilSpil)).toFixed(5),
        nysPrepaid: (parseFloat(totalQuantity) * parseFloat(taxValues.NysPrepaid)).toFixed(5),
        nysExcise: (parseFloat(totalQuantity) * parseFloat(taxValues.NysExcise)).toFixed(5),
        nysPetroleumBusRcvry: (parseFloat(totalQuantity) * parseFloat(taxValues.NysPetroleumBusRcvry)).toFixed(5),
        nysPetroleumTestingFee: (parseFloat(totalQuantity) * parseFloat(taxValues.NysPetroleumTestingFee)).toFixed(5),
        nysSpillRecovery: (parseFloat(totalQuantity) * parseFloat(taxValues.NysSpillRecovery)).toFixed(5),
        productId: productId,
        productCode : productCode,
        quantities: quantities,
        unitPrices: unitPrices,
        unitTotals: unitTotals,
        surchargeType : surchargeType,
        discountType : discountType,
        surchargeAmount : surchargeValue,
        discountsAmount : discountValue,
        finalInvoiceAmount : finalInvoiceAmount,
        comment : comments ,
        termsCondition : termsAndConditions
      };

    
      async function putData() {
      axios
      .put(`${baseURL}${btoa("invoice/update")}`, payload)
      .then((response) => {
        console.log("update invoice " , response); 
        // console.log("payload" , payload);
        
        // showToastForUpdateInvoice(`Invoice Updated Successfully`, "success");
        dispatch(addToast({ type: 'info', message: `Invoice updated successfully` }));

   
        navigate("/invoice");
      })
      .catch((error) => {
        console.log("update invoice error " , error)
        console.log("payload" , payload);
        // showToastForDeleteInvoice(`Invoice Update failed1`, "error");
        // showToastForUpdateInvoice(`Invoice Updated failed1`, "success");
        dispatch(addToast({ type: 'danger', message: `Invoice Updated Failed` }));

      });
      }
      putData();
    };

    useEffect(() => {
      async function fetchCustomerOptions() {
        try {
          const response = await axios.get(
            `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
          );
          setCustomerOptions(response.data.customers_data);
          // console.log("customer options" , response.data.customers_data);
        } catch (error) {
          console.error("Error fetching product options:", error);
        }
      }
      fetchCustomerOptions();
    }, []);
  
    const isFormValid = Object.entries(formField).filter(([key]) => key !== 'invoice_number').every(([, value])=> value !== "")
    // console.log(isFormValid);
    
    const shouldRenderButtons = products.some(
      item => item.item !== "" && item.unitPrice !== "" && item.unitPrice !== 0 && item.quantity !== "" &&
       item.quantity !== 0 
    );
    // console.log("button ", shouldRenderButtons);
    // console.log("al the products" , products);  
    
    useEffect(() => {
        const fetchTaxValues = async () => {
          try {
            const response = await axios.get(`${baseURL}/${btoa("invoice/gettaxval")}`);
          
            const taxes = response.data.taxvalues_data[0]

            setTaxValues({
                freight : taxes.freight,
                freightSurcharge : taxes.freight_surcharge,
                FederalExciseTax : taxes.federal_excise_tax,
                FederalOilSpil : taxes.federal_oil_spil,
                NysPrepaid : taxes.nys_prepaid,
                NysExcise : taxes.nys_excise,
                NysPetroleumBusRcvry : taxes.nys_petroleum_bus_rcvry,
                NysPetroleumTestingFee : taxes.nys_petroleum_testing_fee,
                NysSpillRecovery : taxes.nys_spill_recovery
            })
          } catch (error) {
            console.error('Error fetching tax values:', error);
          }
        };
    
        fetchTaxValues();
      }, []); 


      const totalQuantity = products.reduce((acc, item) => acc + (parseFloat(item.quantity) || 0), 0);
      const totalPrice = products.reduce((acc, item) => acc + parseFloat(item.unitPrice || 0), 0);
      

    useEffect(() => {
      const sumOfTaxes =
      (parseFloat((totalQuantity * taxValues.freight).toFixed(5))) +
      parseFloat((totalQuantity * taxValues.freight * taxValues.freightSurcharge).toFixed(5)) +
      (parseFloat((totalQuantity * taxValues.FederalExciseTax).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.FederalOilSpil).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPrepaid).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysExcise).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPetroleumBusRcvry).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPetroleumTestingFee).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysSpillRecovery).toFixed(5)));
      setTaxTotal(sumOfTaxes)
    
    }, [products]);

   
    useEffect(() => {
      const fetchCustomerTermsAndConditions = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(formField.bill_to)}/${btoa(businessId)}`);
          // console.log("tandc",response , );
          if(response.data.termsconditions_data?.length === 0){
            setTermsAndConditions("")
          }
          setTermsAndConditions(response.data.termsconditions_data[0].terms_conditions);
        } catch (error) {
          console.error('Error fetching customer terms and conditions', error);
        }
      };
      fetchCustomerTermsAndConditions();
    }, [formField.bill_to]); 
   
    
    useEffect(()=>{
      const fetchDataByInvoice = async () => {
        try {
          const apiUrl = `${baseURL}/${btoa("invoice/fetchinvoicebyinvooiocenumber")}/${btoa(invoicenumber)}/${btoa(businessId)}`;
          const response = await axios.get(apiUrl);
          console.log("invoice on on invoice number: " , response);
        
          setInvoiceData(response.data.customerData[0]);
          const createdOnString = response.data.customerData[0].delivery_date;
          const formattedCreatedOn = moment(createdOnString, 'YYYY/MM/DD').format('MM/DD/YYYY');
          // console.log("formatted created on" , formattedCreatedOn);
          const dueOnString = response.data.customerData[0].due_date;
          const formattedDueOn = moment(dueOnString, 'YYYY/MM/DD').format('MM/DD/YYYY');
          // console.log("formatted due on" , formattedDueOn);
          setFormField({
              invoice_number: response.data.customerData[0].invoice_number,
              bill_from: response.data.customerData[0].bill_from_name,
              bill_to: response.data.customerData[0].customer_id,
              vehicleNumber: response.data.stateTaxData[0].truck_id,
              sellersId:response.data.sellersData[0].id,
              originTerminal: response.data.stateTaxData[0].origin_terminal_id,
              destinationTerminal: response.data.stateTaxData[0].destination_terminal_id,
              created_on:  new Date(formattedCreatedOn), 
              due_on: new Date(formattedDueOn),
          })
          setTaxTotal( response.data.customerData[0].sales_tax_amount)
          const formattedProducts = response.data.invoiceItemsData.map((products) => ({
            productId : products.product_id,
            productCode : products.product_code,
            quantity: products.item_quantity,
            unitPrice: products.item_price,
            total: products.unit_total,
          }));
          setProducts(formattedProducts);
          setSurchargeType(response.data.customerData[0].surcharge_type)
          setDiscountType(response.data.customerData[0].discounts_type)
          // setSurchargeAmount(response.data.customerData[0].surcharge_amount)
          // setDiscountAmount(response.data.customerData[0].discounts_amount)
          setFinalInvoiceAmount(response.data.customerData[0].final_invoice_amount)
          
          if(response.data.customerData[0].surcharge_type === "percentage"){
            setSurchargePercentage(response.data.customerData[0].surcharge_amount)
            // console.log('SURCHARGE TYPE %', response.data.customerData[0].surcharge_type);
          }else if(response.data.customerData[0].surcharge_type === "amount"){
            setSurchargeAmount(response.data.customerData[0].surcharge_amount)
            // console.log('SURCHARGE TYPE $', response.data.customerData[0].surcharge_type);
          }

          if(response.data.customerData[0].discounts_type === "percentage"){
            setDiscountPercentage(response.data.customerData[0].discounts_amount)
          }else if(response.data.customerData[0].discounts_type === "amount"){
            setDiscountAmount(response.data.customerData[0].discounts_amount)
          }
          setComments(response.data.customerData[0].comment)
          setTermsAndConditions(response.data.customerData[0].terms_conditions)

        } catch (error) {
          console.error("Error fetching invoice by invoice number", error);
        }
      };
      fetchDataByInvoice()
    },[])


    useEffect(() => {
      const isLastRowNew = products.length > 0 && products[products.length - 1].No === products.length;
      if (productOptions.length > products.length && !isLastRowNew) {
        setProducts(prevItems => [
          ...prevItems,
          {
            No: prevItems.length + 1,
            productId : "",
            productCode : "",
            quantity: "",
            unitPrice: "",
            total: 0,
          }
        ]);
      }
    }, [products, productOptions]);
    
    const restrictToFiveDecimals = (value) => {
      if (value.includes('.')) {
        const [integerPart, decimalPart] = value.split('.');
        if (decimalPart.length > 5) {
          return `${integerPart}.${decimalPart.slice(0, 5)}`;
        }
      }
      return value;
    };
  
    const handleProductChange = (index, field, value) => {
      const updatedProducts = [...products];
      if (field === "unitPrice") {
        value = restrictToFiveDecimals(value);
      }
      updatedProducts[index][field] = value;
    
      if (field === "productId") {
        const selectedProduct = productOptions.find(product => product.product_id === value);
        if (selectedProduct) {
          updatedProducts[index].productId = selectedProduct.product_id;
          updatedProducts[index].productCode = selectedProduct.product_code; // Ensure productCode is updated
          updatedProducts[index].unitPrice = parseFloat(selectedProduct.item_unit_price);
        } else {
          console.log("Selected item not found in product options");
        }
      }
    
      // Calculate total
      updatedProducts[index].total = updatedProducts[index].quantity * updatedProducts[index].unitPrice;
      
      // If quantity is changed for the last item, add a new empty row
      if (field === "quantity" && index === updatedProducts.length - 1 && value !== "") {
        updatedProducts.push({
          No: updatedProducts.length + 1,
          productId: "",
          productCode: "",
          quantity: "",
          unitPrice: "",
          total: 0,
        });
      }
    
      // Recalculate subtotal and total
      const newSubtotal = updatedProducts.reduce((acc, item) => acc + item.total, 0);
      const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
    
      setProducts(updatedProducts);
      setSubtotal(newSubtotal);
      setTotal(newTotal);
    };
    const handleProductBlur = (index, field, value) => {
      const updatedProducts = [...products];

      if (field === 'unitPrice') {
        // Format the unit price to five decimal places on blur
        const formattedValue = parseFloat(value).toFixed(5);
        updatedProducts[index][field] = formattedValue;
      }

      // Recalculate total with the formatted value
      updatedProducts[index].total = updatedProducts[index].quantity * parseFloat(updatedProducts[index].unitPrice) || 0;

      // Recalculate subtotal and total
      const newSubtotal = updatedProducts.reduce((acc, item) => acc + item.total, 0);
      const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;

      // Update state
      setProducts(updatedProducts);
      setSubtotal(newSubtotal);
      setTotal(newTotal);
    };
  
    
    
    const formatNumber = (number) => {
      if (isNaN(number)) {
        return 0;
      }
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
      return formattedNumber;
    };
  
   
  
    const isDisabled = products.every(
      product =>
        (product.productId === "" && product.unitPrice === "" && (product.quantity === "" || isNaN(product.quantity)))
        ||
        (product.productId !== "" && product.unitPrice !== "" && (product.quantity !== "" && !isNaN(product.quantity)))
    );
    // console.log("disable " , isDisabled);

    const showChildRecord = products.every(
      product =>
        (product.productId === ""  && (product.quantity === "" || isNaN(product.quantity)))
        ||
        (product.productId !== ""  && (product.quantity !== "" && !isNaN(product.quantity)))
    );
    // console.log("chil record" , showChildRecord);

   

  return (
    <div className="pt-8 pb-5">
    <div className="max-w-7xl mx-8 bg-white rounded-box p-10 rounded-xl">
        <div className="flex items-center justify-between w-full">
            <h2 class="mb-10 text-2xl font-bold text-gray-900 ">
              Edit Invoice
            </h2>
        <div className="flex items-center">
          <label
            for="invoice_number"
            className="  text-base font-bold text-gray-900 w-28 mr-2"
          >
            Invoice #
          </label>
          <input
            readOnly
            id="invoice_number"
            name="invoice_number"
            type="text"
            value={formField.invoice_number}
            onChange={handleChange}
            placeholder="Enter Invoice Number"
            className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
          />
        </div>
        </div>

      <form action="#" 
      // onSubmit={handleSubmit}
      >
         <div className="grid gap-4 mb-4 sm:grid-cols-2">
          <div>
            <label
              for="bill_from"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Bill From
            </label>
            <input
              id="bill_from"
              type="text"
              name="bill_from"
              required
              value={formField.bill_from}
              onChange={handleChange}
              placeholder="Enter From details"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              readOnly
            />
          </div>


          <div className="">
            <label
              htmlFor="created_on"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Delivery date
            </label>

        
            <div className="h-11 text-gray-700 font-normal border-gray-300 cursor-pointer border rounded-lg flex items-center justify-between"
                  onClick={handleDeliveryDateClick} // Add event handler to the div
              >
              <div ref={deliveryDatePickerRef}>
                <DatePicker
                    id="created_on"
                    name="created_on"
                    selected={formField.created_on}
                    onChange={handleDeliveryDateChange}
                    minDate={new Date()}
                    dateFormat="MM/dd/yyyy"        
                    placeholderText="From"
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                />
                </div>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
            </div>
            
          </div>

          <div>
            <label
              for="bill_to"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Bill To
            </label>
            <select
              id="bill_to"
              name="bill_to"
              required
              value={formField.bill_to}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Customer</option>
              {customerOptions.map((customer) => (
                <option
                  key={customer.customer_id}
                  value={customer.customer_id}
                >
                  {customer.business_name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="due_on"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Due Date
            </label>

            <div
                className="h-11 font-normal border-gray-300 cursor-pointer border rounded-lg flex items-center justify-between"
                onClick={handleOuterDivClick}
              >
                <div ref={datePickerRef}>
                  <DatePicker
                    name="due_on"
                    id="due_on"
                    dateFormat="MM/dd/yyyy"
                    minDate={new Date()}
                    selected={formField.due_on}
                    onChange={handleDueDateChange}
                    placeholderText="To"
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
            </div>
          </div>

        
          <div>
            <label
              for="vehicleNumber"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Select Truck
            </label>
            <select
              id="vehicleNumber"
              name="vehicleNumber"
              required
              value={formField.vehicleNumber}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Truck</option>
              {vehiclesNames.map((vehicles) => (
                <option
                  key={vehicles.id}
                  value={vehicles.id}
                >
                  {vehicles.truck_company_name}
                </option>
              ))}
             
            </select>
          </div>
   
          <div>
              <label
                  for="originalTerminal"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Origin Terminal
                </label>
                <select
                  id="originTerminal"
                  name="originTerminal"
                  required
                  value={formField.originTerminal}
                  onChange={handleChange}
                  // required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Terminal</option>
                  {originTerminal.map((terminal)=>(
                    <option key={terminal.id} value={terminal.id}>{terminal.terminal_name}</option>
                  ))}
                
                </select>
                </div>

           <div className=''>
               {/* select seller */}
            <label
              for="sellersId"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Select Seller
            </label>
            <select
              id="sellersId"
              name="sellersId"
              required
              value={formField.sellersId}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Seller</option>
              {sellerNames.map((seller) => (
                <option
                  key={seller.id}
                  value={seller.id}
                >
                  {seller.seller_name}
                </option>
              ))}
             
            </select>

          

          </div>         
          
          <div>
              <label
                  for="bill_to"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Destination Terminal
                </label>
                <select
                  id="destinationTerminal"
                  name="destinationTerminal"
                  required
                  value={formField.destinationTerminal}
                  onChange={handleChange}
                  // required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Terminal</option>
                  {destinationTerminal.map((terminal)=>(
                    <option key={terminal.id} value={terminal.id}>{terminal.terminal_name}</option>
                  ))}
                
                </select>
              </div>


        </div>

            {/*From here invoice items*/}

        <div className="mb-4">
          <h3 className="text-lg font-bold underline mb-2 mt-10 text-gray-900 ">
            Invoice Products
          </h3>
   
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th style={{ width: "50px" }} className="pt-2 pb-2 pl-0 font-semibold text-base">#</th>
                  {/* <th style={{ width: "150px" , marginLeft : "" }}  className="p-2 font-semibold pr-5 text-base">Product</th> */}
                  <th style={{ width: "150px" }}  className="p-2 font-semibold pr-2 text-base">Product</th>
                  {/* <th style={{ width: "200px" }}  className="p-2 font-semibold pr-10 text-base">Item Description</th> */}
                  <th style={{ width: "60px" ,marginLeft: "50px" }} className="p-2 font-semibold text-base">Quantity</th>
                  <th style={{ width:"px" , marginLeft: "110px" , position:"absolute"  }} className="p-2 font-semibold text-base">Unit Price</th>
                  <th style={{ width : "70px",  marginRight: "-10px"}} className="p-2 font-semibold text-base">Total</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <tr key={index}>
                    <td className="p-2 pl-4">{index + 1}</td>

                    <td className="p-2">
                        <select
                          onChange={(e) => {
                            handleProductChange(index, "productId", e.target.value);
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                          style={{ width: "270px", marginLeft: "12px" }}
                          value={product.productId} // Pre-select the value
                        >
                          <option value="">Select Product</option>
                          {productOptions.map(option => {
                            // Check if the current option is already selected in any other row but not in the current row
                            const isOptionSelected = products.some((p, i) => i !== index && p.productId === option.product_id);
                            // If the option is not selected in any other row, render it in the dropdown
                            if (!isOptionSelected) {
                              return (
                                <option key={option.product_id} value={option.product_id}>
                                  {option.product_name}
                                </option>
                              );
                            }
                            // If the option is already selected in other rows, don't render it in the dropdown
                            return null;
                          })}
                        </select>
                      </td>




                    <td className="p-2">
                      <input
                        type="text"
                        value={product.quantity}
                        placeholder="Enter Quantity"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const parsedValue = inputValue;

                          if (!isNaN(parsedValue)) {
                            handleProductChange(index, "quantity", parsedValue);
                          } else {
                            handleProductChange(index, "quantity", "");
                          }
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{
                          width: "270px",
                          marginLeft: "10px",
                        }}
                      />
                    </td>

                    <td className="p-2">
                      <input
                        type="number"
                        id="number"
                        name="number"
                        
                        value={product.unitPrice}
                        placeholder="Enter unit price"
                        onChange={(e) =>
                          handleProductChange(index, "unitPrice", e.target.value)
                        }
                        onBlur={(e) =>
                          handleProductBlur(index, "unitPrice", e.target.value)
                        }
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                        style={{
                          width: "270px",
                          marginLeft: "10px",
                        }}
                      />
                    </td>

                    <td className="flex justify-end p-2 mr-1">
                      {/* {isNaN(product.total) ? "0.00" : Number(product.total).toFixed(2)} */}
                      {isNaN(product.total) ? "0.00" : Number(product.total).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}
                    </td>

                  </tr>
                ))}

               

              </tbody>

            </table>

              <hr />
                <div className="total-info flex space-x-8 justify-end font-bold mr-3 mt-2 mb-2">
                  {/* <p>Total Quantity: {totalQuantity}</p> */}
                  <p>Total Quantity: {Number(totalQuantity).toLocaleString()}</p>
                  {/* <p>Total Price: {totalPrice}</p> */}
                  {/* <p>Sub Total: {subTotal.toFixed(2)}</p> */}
                  <p>Sub Total: {subTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</p>
                </div>
              
              <div className="">
                     <div className="p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500">

                      <div className="flex justify-end items-end mr-0.5" style={{fontSize: "15px"}}>
                            <div className='flex flex-col space-y-2 '>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Freight :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Freight surcharge :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Federal excise tax :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Federal oil spil :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS prepaid :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS excise :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS petroleum bus rcvry :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS petroleum testing fee :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS spill recovery:</label>
                            </div>

                            <div className='flex flex-col space-y-2 ml-8 text-right'>
                              {/* <div> {(totalQuantity*taxValues.freight).toFixed(5)}</div>
                              <div> {((totalQuantity*taxValues.freight).toFixed(5)* taxValues.freightSurcharge).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.FederalExciseTax).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.FederalOilSpil).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.NysPrepaid).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.NysExcise).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumBusRcvry).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumTestingFee).toFixed(5)}</div>
                              <div> {(totalQuantity*taxValues.NysSpillRecovery).toFixed(5)}</div> */}

                              <div> {(totalQuantity*taxValues.freight).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {((totalQuantity*taxValues.freight).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })* taxValues.freightSurcharge).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.FederalExciseTax).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.FederalOilSpil).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysPrepaid).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysExcise).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumBusRcvry).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumTestingFee).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysSpillRecovery).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                            </div>
                      </div>

                <hr />

                <div className='flex justify-end space-x-5 pt-1 pb-1 pr-0.5'>
                <b><span>Total tax : $ {Number(taxTotal).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span></b>
                </div>
                <hr></hr>

                <div className='flex justify-end space-x-5 pt-1 pb-1 pr-0.5'>
                  <b><span>Invoice amount: $ {grandTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span></b>
                </div>
                <hr></hr>
                
                <div className="flex flex-col items-end justify-end pt-2 pb-2 space-x-4 space-y-1">
  {/* Surcharge Box */}
  <div className="bg-gray-50 rounded-lg border border-gray-300 p-2 w-72">
    <h3 className="text-base font-semibold text-center mb-1">Surcharge</h3>
    <div className="flex items-center space-x-2 mb-2">
      <div className="flex flex-col space-y-[-10px]">
        <div className="flex items-center">
          <Checkbox
            checked={surchargeType === "percentage"}
            onChange={() => handleSurchargeTypeChange("percentage")}
            id="surchargePercentage"
          />
          <label htmlFor="surchargePercentage" className="ml-2">
            Percentage
          </label>
        </div>
        <div className="flex items-center">
          <Checkbox
            checked={surchargeType === "amount"}
            onChange={() => handleSurchargeTypeChange("amount")}
            id="surchargeAmount"
          />
          <label htmlFor="surchargeAmount" className="ml-2">
            Amount
          </label>
        </div>
      </div>
      <div className="flex items-center">
        <input
          type="number"
          value={surchargeType === "percentage" ? surchargePercentage : surchargeAmount}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 px-2 h-[55px] w-32"
          onChange={surchargeType === "percentage" ? handleSurchargePercentageChange : handleSurchargeAmountChange}
          placeholder={surchargeType === "percentage" ? "Surcharge %" : "Surcharge $"}
        />
      </div>
    </div>
  </div>
                              
  {/* Discount Box */}
  <div className="bg-gray-50 rounded-lg border border-gray-300 p-2 w-72">
    <h3 className="text-bold font-semibold text-center mb-1">Discount</h3>
    <div className="flex items-center space-x-2 mb-2">
      <div className="flex flex-col space-y-[-10px]">
        <div className="flex items-center">
          <Checkbox
            checked={discountType === "percentage"}
            onChange={() => handleDiscountTypeChange("percentage")}
            id="discountPercentage"
          />
          <label htmlFor="discountPercentage" className="ml-2">
            Percentage
          </label>
        </div>
        <div className="flex items-center">
          <Checkbox
            checked={discountType === "amount"}
            onChange={() => handleDiscountTypeChange("amount")}
            id="discountAmount"
          />
          <label htmlFor="discountAmount" className="ml-2">
            Amount
          </label>
        </div>
      </div>
      <div className="flex items-center">
        <input
          type="number"
          value={discountType === "percentage" ? discountPercentage : discountAmount}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 px-2 h-[55px] w-32"
          onChange={discountType === "percentage" ? handleDiscountPercentageChange : handleDiscountAmountChange}
          placeholder={discountType === "percentage" ? "Discount %" : "Discount $"}
        />
      </div>
    </div>
  </div>
</div>


              <hr></hr>
              <div className='flex justify-end space-x-5 pt-1 pb-1 pr-0.5'>
                <b><span>Final invoice Amount: {formatNumber(finalInvoiceAmount)}</span></b>
              </div>

              </div>
              </div>

      </div>



        <div className="sm:col-span-2 mb-8">
          <label
            for="description"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Comments
          </label>
          <textarea
            id="description"
            rows="4"
            name="description"
            value={comments}
            maxLength={100}
            onChange={(e) => setComments(e.target.value)}
            className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
            placeholder="Write your Comments here..."
          ></textarea>
        </div>
        <div className="flex justify-between items-center">
          <div className="border-2 border-[#111FFF] py-2 px-5 w-[550px]">
            <h1 className="font-semibold text-blue-500 underline underline-offset-4">
              Terms & Conditions
            </h1>
            <textarea
              id="termsAndConditions"
              rows="4"
              name="termsAndConditions"
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.value)}
              maxLength={100}
              className="block p-2.5 mt-3 mb-3 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Add your Terms & Conditions here..."
            ></textarea>
          </div>

        
          <div>
            {shouldRenderButtons && isFormValid && (
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => navigate(`/invoice`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                
                >
                  Cancel
                </button>
                <button
                onClick={handleSubmit}
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
    </div>
  )
}

export default ViewInvoiceFormTwo