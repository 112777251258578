import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // Purchase icon
import DescriptionIcon from '@mui/icons-material/Description'; // invoice icon

import StoreIcon from '@mui/icons-material/Store'; // Sales icon
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Price icon
import ReceiptIcon from '@mui/icons-material/Receipt'; // Accounting icon
import PercentIcon from '@mui/icons-material/Percent'; // Sales tax icon

import RocketIcon from '@mui/icons-material/Rocket'; // Using a rocket icon from Material UI

import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';


const Billing = () => {
  const navigate = useNavigate();

  return (
    <>
    <div className='ml-5 pageHeight' >

  <div 
  className="flex justify-between items-center w-full  pt-4 mt-4 mb-5" 
  style={{ 
    maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '20px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}
>
  <h1 className="font-bold text-xl mb-4"> Analytics Dashboard</h1>
</div>
  

    <div className="container mb-5" style={{margin: '0px', marginBottom: '150px'}} >
    
      <Grid container spacing={4} justifyContent="start">
        {/* Purchase Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
       
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',
              }
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <InsightsIcon sx={{ fontSize: 50, color: '#000000' }} />
                <Typography sx={{ color: '#000000', fontSize: '20px' }}>KPIs</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              backgroundColor: '#33adff',

              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
            // onClick={() => navigate('/invoiceReport')}

          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <TrendingUpIcon sx={{ fontSize: 50, color: '#ffffff' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '18px' }}>Trending</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sales Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            // onClick={() => navigate('/Sales')}
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <SpeakerNotesIcon sx={{ fontSize: 50, color: '#000000' }} />
                <Typography sx={{ color: '#000000', fontSize: '20px' }}>TBD</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Price Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              backgroundColor: '#33adff',

              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <SpeakerNotesIcon sx={{ fontSize: 50, color: '#ffffff' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '20px' }}>TBD</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Accounting Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <SpeakerNotesIcon sx={{ fontSize: 50, color: '#000000' }} />
                <Typography sx={{ color: '#000000', fontSize: '20px' }}>TBD</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sales Tax Card */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card
            // onClick={() => navigate('/SalesTax')}
            sx={{
              borderRadius: '10px',
              boxShadow: '0 0 20px 0 rgba(0, 0, 0, .2)',
              backgroundColor: '#33adff',

              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'scale(1.08)',

              },
            }}
            className="cursor-pointer bg-white rectangular-box p-3"
          >
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <SpeakerNotesIcon sx={{ fontSize: 50, color: '#ffffff' }} />
                <Typography sx={{ color: '#ffffff', fontSize: '20px' }}>TBD</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>    
  
      </Grid>
    </div>
    </div>

    </>
  );
};

export default Billing;
