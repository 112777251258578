import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allInvoices: [[]], // Initialize with one empty sub-array
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    addInvoice(state, action) {
      if (Array.isArray(action.payload)) {
        // Only add new invoices that do not already exist in the array
        const newInvoices = action.payload.filter(newInvoice =>
          !state.allInvoices[0].some(existingInvoice => existingInvoice.id === newInvoice.id)
        );
        state.allInvoices[0].push(...newInvoices);
      } else {
        // Add single invoice only if it doesn't already exist
        if (!state.allInvoices[0].some(existingInvoice => existingInvoice.id === action.payload.id)) {
          state.allInvoices[0].push(action.payload);
        }
      }
    },
    deleteInvoice(state, action) {
      if (state.allInvoices[0]) {
        state.allInvoices[0] = state.allInvoices[0].filter(
          invoice => invoice.invoice_number !== action.payload
        );
      }
    },

    deleteAllInvoices(state) {
        state.allInvoices[0] = []; // Clear all invoices in the first array
      }
  }
});

export const { addInvoice, deleteInvoice , deleteAllInvoices  } = invoiceSlice.actions;
export default invoiceSlice.reducer;
