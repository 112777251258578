// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { Provider } from 'react-redux';
// import store from "./store/store";
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter, HashRouter, MemoryRouter } from "react-router-dom";
// import { ThemeProvider } from "@material-tailwind/react";
// import { UserProvider } from './components/pages/UserContext';
// import useHideUrl from './useHideUrl';

// // Check if the current path is '/adminlogin'
// const isAdminLogin = window.location.pathname === "/adminlogin";

// // Determine which Router to use
// const RouterComponent = process.env.NODE_ENV === 'production' && !isAdminLogin ? MemoryRouter : BrowserRouter;

// const AppWrapper = () => {
//   useHideUrl(); // Call the hook here to ensure it runs

//   return <App />;
// };

// const RootComponent = () => (
//   <React.StrictMode>
//     <UserProvider>
//       <Provider store={store}>
//         <ThemeProvider>
//           <RouterComponent>
//             <AppWrapper />
//           </RouterComponent>
//         </ThemeProvider>
//       </Provider>
//     </UserProvider>
//   </React.StrictMode>
// );

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<RootComponent />);

// reportWebVitals();




import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from "./store/store";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter, MemoryRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { UserProvider } from './components/pages/UserContext';
import useHideUrl from './useHideUrl';
import { useNavigate, useLocation } from 'react-router-dom';

// Check if a token exists in local storage
const tokenExists = localStorage.getItem('token');

// Determine which Router to use
const RouterComponent = (process.env.NODE_ENV === 'production' && tokenExists)  ? MemoryRouter : HashRouter;

  
const AppWrapper = () => {
  useHideUrl();

  useEffect(() => {
    const tokenExists = localStorage.getItem('token');
    const isProduction = process.env.NODE_ENV === 'production';

    // if (isProduction && tokenExists) {
    //   console.log(MemoryRouter);
    // } else {
    //   console.log(HashRouter);
    // }

  }, []);

  return <App />;
  
};


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};



const RootComponent = () => (
  <React.StrictMode>
    <UserProvider>
      <Provider store={store}>
        <ThemeProvider>
          <RouterComponent>
         <ScrollToTop/>
            <AppWrapper />
          </RouterComponent>
        </ThemeProvider>
      </Provider>
    </UserProvider>
  </React.StrictMode>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

reportWebVitals();

