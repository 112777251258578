import React from 'react'

import { useState , useEffect } from 'react';
import axios from "axios";
import { useRef } from 'react';
import {addInvoice,} from "../../actions/invoiceActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import config  from "../../config/config"
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import ReactDOM from "react-dom"
import { MdCalendarMonth } from "react-icons/md";
import { DisabledByDefault } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { addToast } from '../pages/ToastBox/ToastBoxMessageSlice';
import { showToastForAddInvoice } from '../toasts/toastForInvoice';

const InvoiceFormTwo = () => {
  const dispatch = useDispatch()
    const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [isInputFocused, setIsInputFocused] = useState(true);
    const today = new Date().toISOString().split("T")[0];
    const [issuedOn, setIssuedOn] = useState(today);
    const [dueOn, setDueOn] = useState(today);
    const [productOptions, setProductOptions] = useState([]);

    const [vehiclesNames , setVehiclesNames] = useState([])
    const [sellerNames , setSellerNames] = useState([])

    const [originTerminal , setOriginTerminal] = useState([])
    const [destinationTerminal , setDestinationTerminal] = useState([])

  
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState("");
    const [feesOrDiscounts, setFeesOrDiscounts] = useState("");
    const [total, setTotal] = useState(0);
  
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [comments, setComments] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState(""); 

    const [latestInvoiceNumber , setLatestInvoiceNumber] = useState("");
  
    const [dueDate, setDueDate] = useState(new Date());
    const [createdDate , setCreatedOnDate ] = useState(new Date()); 
    const businessId = localStorage.getItem("business_id")
  
    
    const [showDropdown, setShowDropdown] = useState(false);
    const [customerOptions , setCustomerOptions] = useState([])
    const [taxTotal ,setTaxTotal] = useState("");
   
    
    const [surchargePercentage, setSurchargePercentage] = useState('');
    const [surchargeAmount, setSurchargeAmount] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [surchargeType, setSurchargeType] = useState('percentage');
    const [discountType, setDiscountType] = useState('percentage');

    const [productData, setProductData] = useState([]);
    const [newTotal, setNewTotal] = useState(0);
    const [taxValues, setTaxValues] = useState({
        freight : "",
        freightSurcharge : "",
        FederalExciseTax : "", 
        FederalOilSpil : "",
        NysPrepaid : "",
        NysExcise : "",
        NysPetroleumBusRcvry : "",
        NysPetroleumTestingFee : "",
        NysSpillRecovery : ""
    });  
  
    const navigate = useNavigate()

    const numericTaxValues = Object.values(taxValues).map(value => parseFloat(value));
    const avgUnitPrice = numericTaxValues.reduce((acc, value) => acc + value, 0);    // Sum of all the tax values
    // console.log('Sum of all tax values:', avgUnitPrice.toFixed(5));

    const [taxValuesResults, setTaxValuesResults] = useState([
     {
        freight : "",
        freightSurcharge : "",
        FederalExciseTax : "",
        FederalOilSpil : "",
        NysPrepaid : "",
        NysExcise : "",
        NysPetroleumBusRcvry : "",
        NysPetroleumTestingFee : "",
        NysSpillRecovery : "",
        avgUnitPrice : "",
        subTotal : "",
    }
    ])

    useEffect(() => {
      const total = taxValuesResults.reduce((acc, product) => {
        const subTotalValue = parseFloat(product.subTotal);
        return isNaN(subTotalValue) ? acc : acc + subTotalValue;
      }, 0);
      setNewTotal(total);
    }, [taxValuesResults]);
    // console.log("new total is ", newTotal);
    
    const [products, setProducts] = useState([
      { 
        product:"",
        productCode : "",
        quantity: "",
        unitPrice: "",
        total: 0,
        editableDescription: false,
      
      },
    ]);

    // console.log("products", products);

    const [formField, setFormField] = useState({
      invoice_number:  "",
      bill_from: "",
      bill_to: "",
      vehicleNumber : "",
      sellersId :"",
      originTerminal : "",
      destinationTerminal  : "",
      created_on: today,
      due_on: today,
    });

    const isFormValid = Object.entries(formField).filter(([key]) => key !== 'invoice_number').every(([, value])=> value !== "")
    // console.log(isFormValid);
    // console.log(formField);

    const totalQuantity = products.reduce((acc, product) => acc + (product.quantity || 0), 0);
    const totalPrice = products.reduce((acc, product) => acc + parseFloat(product.unitPrice || 0), 0);
    const subTotal = products.reduce((acc, product) => acc + parseFloat(product.total || 0), 0);

    const grandTotal = parseFloat(taxTotal) + parseFloat(subTotal);
    const [finalInvoiceAmount, setFinalInvoiceAmount] = useState(grandTotal);

    const handleChange = (event) => {
      console.log(event.target.value)
      if (event instanceof Date) {
        setCreatedOnDate(event);
      } else {
        const { name, value } = event.target;
        setFormField({
          ...formField,
          [name]: value,
        });
      }
    };

    const handleDeliveryDateChange = (date) => {
      setCreatedOnDate(date);
      setFormField({
        ...formField,
        created_on: date,
      });
    };
  
    const handleDueDateChange = (date) => {
      setDueDate(date);
      setFormField({
        ...formField,
        due_on: date,
      });
    };
    
    
    const datePickerRef = useRef(null);
    const deliveryDatePickerRef = useRef(null);
  
    const showDatePicker = () => {
      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true);
      }
    };
    const handleOuterDivClick = () => {
      if (datePickerRef.current) {
        datePickerRef.current.querySelector('input').click();
      }
    };

    const handleDeliveryDateClick = () => {
      if (deliveryDatePickerRef.current) {
        deliveryDatePickerRef.current.querySelector('input').click();
      }
    };

    useEffect(() => {
      const fetchLatestInvoiceNumber = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/latest_invoice_number")}`);
          const latestInvoiceNumber = response.data;
          // console.log("latestInvoiceNumber", latestInvoiceNumber);
          setLatestInvoiceNumber(latestInvoiceNumber)
          // setFormField((prev) => ({ ...prev, invoice_number: latestInvoiceNumber }));
        } catch (error) {
          console.error("Error fetching latest invoice number:", error);
        } 
      };
      fetchLatestInvoiceNumber();
    }, []);

    

    useEffect(()=>{
      const fetchVehiclesNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allvehicles")}`)
          setVehiclesNames(response.data.vehicles_data);
        }catch(error){
          console.log("error fetching vehicles names" , error);
        }
      }
      fetchVehiclesNames()
    },[])
    useEffect(()=>{
      const fetchSellersNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allsellers")}`)
          console.log('response', response);
          setSellerNames(response.data);
        }catch(error){
          console.log("error fetching seller names" , error);
        }
      }
      fetchSellersNames()
      console.log('setSellerNames', sellerNames)
    },[])

    useEffect(()=>{
      const fetchTerminalNames = async ()=> {
        try{
          const response = await axios.get(`${baseURL}${btoa("invoice/allterminals")}`)
          // console.log("ter" , response)
          setOriginTerminal(response.data.terminals_data);
          setDestinationTerminal(response.data.terminals_data);

        }catch(error){
          console.log("error fetching vehicles names");
        }
      }
      fetchTerminalNames()
    },[])


    
  const calculateFinalInvoiceAmount = (grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount) => {
    const surchargeFromPercentage = (surchargePercentage / 100) * grandTotal;
    const discountFromPercentage = (discountPercentage / 100) * grandTotal;
    const totalSurcharge = surchargeFromPercentage + surchargeAmount;
    const totalDiscount = discountFromPercentage + discountAmount;
    return grandTotal + totalSurcharge - totalDiscount;
    
  };

// Handler for surcharge percentage change
const handleSurchargePercentageChange = (e) => {
  const percentage = e.target.value;
  setSurchargePercentage(percentage);
  setSurchargeAmount(0); // Reset surcharge amount
  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, percentage, 0, discountPercentage, discountAmount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Handler for surcharge amount change
const handleSurchargeAmountChange = (e) => {
  const amount = Number(e.target.value);
  setSurchargeAmount(amount);
  setSurchargePercentage(0); // Reset surcharge percentage
  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, 0, amount, discountPercentage, discountAmount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Handler for discount percentage change
const handleDiscountPercentageChange = (e) => {
  const percentage = e.target.value;
  setDiscountPercentage(percentage);
  setDiscountAmount(0); // Reset discount amount

  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, percentage, 0);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Handler for discount amount change
const handleDiscountAmountChange = (e) => {
  const amount = e.target.value;
  setDiscountAmount(amount);
  setDiscountPercentage(0); // Reset discount percentage

  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, 0, amount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
};

// Usage example (assuming grandTotal is defined elsewhere)
useEffect(() => {
  const newFinalInvoiceAmount = calculateFinalInvoiceAmount(grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount);
  setFinalInvoiceAmount(newFinalInvoiceAmount);
}, [grandTotal, surchargePercentage, surchargeAmount, discountPercentage, discountAmount]);
    
    
  
    const handleSurchargeTypeChange = (type) => {
      setSurchargeType(type);
      setSurchargePercentage('');
      setSurchargeAmount('');
    };
  
    const handleDiscountTypeChange = (type) => {
      setDiscountType(type);
      setDiscountPercentage('');
      setDiscountAmount('');
    };
  
    
  
    const handleSubmit = (e) => {
     
      e.preventDefault();

      // console.log("products submitted " , products);
      // console.log("result tax values submitted", taxValuesResults );
  
      const formattedDueDate = formField.due_on instanceof Date && !isNaN(formField.due_on)
      ? formField.due_on.toISOString()
      : new Date().toISOString();

      const formattedDeliveryDate = formField.created_on instanceof Date && !isNaN(formField.created_on)
      ? formField.created_on.toISOString()
      : new Date().toISOString();

      const surchargeValue = surchargeType === "percentage" ? surchargePercentage : surchargeAmount;
      const discountValue = discountType === "percentage" ? discountPercentage : discountAmount;
        
      // console.log("Formatted due date" , formattedDueDate );
      const formData = new FormData();
  
      formData.append("businessId", localStorage.getItem("business_id"));
      formData.append("managerId", localStorage.getItem("user_id"));
      formData.append("billFromName", formField.bill_from);
      formData.append("customerId", formField.bill_to);
      formData.append("deliveryDate", formattedDeliveryDate);
      formData.append("dueDate", formattedDueDate);
      formData.append("vehicleNumber", formField.vehicleNumber);
      formData.append("sellersId", formField.sellersId);
      formData.append("originTerminal", formField.originTerminal);
      formData.append("destinationTerminal", formField.destinationTerminal);
      formData.append("invoiceNumber", latestInvoiceNumber);
      formData.append("subTotalAmount", subTotal);
      formData.append("finalTotalAmount", (subTotal + taxTotal));
      formData.append("surchargeType", surchargeType);
      formData.append("discountType", discountType);
      formData.append("surchargeAmount", surchargeValue);
      formData.append("discountsAmount", discountValue);
      formData.append("finalInvoiceAmount", finalInvoiceAmount);
      
      formData.append("freight", (parseFloat(totalQuantity) * parseFloat(taxValues.freight)).toFixed(5));
      formData.append("freightSurcharge", (parseFloat(totalQuantity) * parseFloat(taxValues.freight) * parseFloat(taxValues.freightSurcharge)).toFixed(5));
      formData.append("federalExciseTax", (parseFloat(totalQuantity) * parseFloat(taxValues.FederalExciseTax)).toFixed(5));
      formData.append("federalOilSpil", (parseFloat(totalQuantity) * parseFloat(taxValues.FederalOilSpil)).toFixed(5));
      formData.append("nysPrepaid", (parseFloat(totalQuantity) * parseFloat(taxValues.NysPrepaid)).toFixed(5));
      formData.append("nysExcise", (parseFloat(totalQuantity) * parseFloat(taxValues.NysExcise)).toFixed(5));
      formData.append("nysPetroleumBusRcvry", (parseFloat(totalQuantity) * parseFloat(taxValues.NysPetroleumBusRcvry)).toFixed(5));
      formData.append("nysPetroleumTestingFee", (parseFloat(totalQuantity) * parseFloat(taxValues.NysPetroleumTestingFee)).toFixed(5));
      formData.append("nysSpillRecovery", (parseFloat(totalQuantity) * parseFloat(taxValues.NysSpillRecovery)).toFixed(5));
      formData.append("salesTaxAmount" , parseFloat(taxTotal).toFixed(5) );

      formData.append("comment", comments);
      formData.append("termsCondition", termsAndConditions);
  
      products.forEach((product, index) => {
        if (product.product && product.quantity && product.total) {
          const [product_id, product_name] = product.product.split("#");
          
          formData.append(`productId[${index}]`, product.product);
          formData.append(`productCode[${index}]`, product.productCode);
          // formData.append(`itemNames[${index}]`, item_name);
          // formData.append(`itemDescriptions[${index}]`, product_id.description);
          formData.append(`quantities[${index}]`, product.quantity);
          formData.append(`unitPrices[${index}]`, product.unitPrice);
          formData.append(`unitTotals[${index}]`, product.total);
        }
      });

      taxValuesResults.forEach((values, index) => {
        Object.entries(values).forEach(([key, value]) => {
          const parsedValue = parseFloat(value);
      
          if (!isNaN(parsedValue) && parsedValue > 0) {
            formData.append(`${key}[${index}]`, parsedValue);
          }
        });
      });
      
      console.log("form data: " , formData);
      // console.log("form data new" ,[...formData.entries()]);
      // showToastForAddInvoice('Invoice added Succesfully', 'success')
      dispatch(addInvoice(formData));
      // dispatch(addToast({ type: 'success', message: `Invoice added successfully` }));

     
      navigate("/invoice"); // Use navigate function to redirect
    };
  
    // useEffect(() => {
    //   async function fetchProductOptions() {
    //     try {
    //       const response = await axios.get(
    //         `${baseURL}/${btoa("product/view")}/${btoa(businessId)}`
    //       );
    //       console.log("response");
    //       // Assuming the response.data is an array of products
    //       setProductOptions(response.data.products_data);
    //       console.log("product options" , productOptions);
    //     } catch (error) {
    //       console.error("Error fetching product options:", error);
    //     }
    //   }
    //   fetchProductOptions();
    // }, []);

    useEffect(() => {
      window.scrollTo(0, 0);
      axios
        .get(`${baseURL}/${btoa("product/view")}`)
        .then((response) => {
            // console.log("prod" , response.data.products_data);
         
          setProductData( response.data.products_data);
        })
        .catch((error) => {
          console.error("Error fetching customer data:", error);
        });
    }, []);
    // console.log(productData)
  
    
    useEffect(() => {
      async function fetchCustomerOptions() {
        try {
          const response = await axios.get(
            `${baseURL}/${btoa("customer/view")}/${btoa(businessId)}`
          );
          setCustomerOptions(response.data.customers_data);
          // console.log("customer options" , response.data.customers_data);
        } catch (error) {
          console.error("Error fetching customer options:", error);
        }
      }
      fetchCustomerOptions();
    }, []);

    useEffect(()=>{
     
    }, [])
   
    
    const business_id = localStorage.getItem("business_id")
    const user_id = localStorage.getItem("user_id")
    const user_role = localStorage.getItem("user_role")

    useEffect(() => {
      const fetchCustomerTermsAndConditions = async () => {
        try {
          const response = await axios.get(`${baseURL}${btoa("invoice/fetchtermsconditions")}/${btoa(formField.bill_to)}/${btoa(businessId)}`);
          // console.log("tandc",response , );
          if(response.data.termsconditions_data?.length === 0){
            setTermsAndConditions("")
          }
          setTermsAndConditions(response.data.termsconditions_data[0].terms_conditions);
        } catch (error) {
          console.error('Error fetching customer terms and conditions in create invoice', error);
        }
      };
      fetchCustomerTermsAndConditions();
    }, [formField.bill_to]); 
  
    useEffect(() => {
      axios
        .get(`${baseURL}${btoa("business/profile")}/${btoa(business_id)}/${btoa(user_id)}/${btoa(user_role)}`)
        .then((response) => {
          // console.log(response);
          const businessData = response.data.business_data[0];
          // console.log(businessData);
          // setCustomerDetails(businessData);
          setFormField({
            ...formField,
            bill_from: businessData.business_name,
            // Other fields as needed
          });
          // setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching business data:", error);
          // setLoading(false);
        });
    }, []);
  
    const shouldRenderButtons = products.some(product => {
      return product.product !== "" && product.unitPrice > 0 && product.quantity > 0 ;
    });
    
    // console.log("button ", shouldRenderButtons);
    // console.log("al the products" , products);
    
    useEffect(() => {
        const fetchTaxValues = async () => {
          try {
            const response = await axios.get(`${baseURL}/${btoa("invoice/gettaxval")}`);
          
            const taxes = response.data.taxvalues_data[0]

            setTaxValues({
                freight : taxes.freight,
                freightSurcharge : taxes.freight_surcharge,
                FederalExciseTax : taxes.federal_excise_tax,
                FederalOilSpil : taxes.federal_oil_spil,
                NysPrepaid : taxes.nys_prepaid,
                NysExcise : taxes.nys_excise,
                NysPetroleumBusRcvry : taxes.nys_petroleum_bus_rcvry,
                NysPetroleumTestingFee : taxes.nys_petroleum_testing_fee,
                NysSpillRecovery : taxes.nys_spill_recovery
            })
          } catch (error) {
            console.error('Error fetching tax values:', error);
          }
        };
    
        fetchTaxValues();
      }, []); 


    useEffect(() => {
      // const sumOfTaxes =
      // (parseFloat((totalQuantity * taxValues.freight).toFixed(2))) +
      // parseFloat((totalQuantity * taxValues.freight * taxValues.freightSurcharge).toFixed(2)) +
      // (parseFloat((totalQuantity * taxValues.FederalExciseTax).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.FederalOilSpil).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysPrepaid).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysExcise).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysPetroleumBusRcvry).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysPetroleumTestingFee).toFixed(2))) +
      // (parseFloat((totalQuantity * taxValues.NysSpillRecovery).toFixed(2)));
      // setTaxTotal(sumOfTaxes)

      const sumOfTaxes =
      (parseFloat((totalQuantity * taxValues.freight).toFixed(5))) +
      parseFloat((totalQuantity * taxValues.freight * taxValues.freightSurcharge).toFixed(5)) +
      (parseFloat((totalQuantity * taxValues.FederalExciseTax).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.FederalOilSpil).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPrepaid).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysExcise).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPetroleumBusRcvry).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysPetroleumTestingFee).toFixed(5))) +
      (parseFloat((totalQuantity * taxValues.NysSpillRecovery).toFixed(5)));
      setTaxTotal(sumOfTaxes)
    
    }, [taxTotal]);

    const restrictToFiveDecimals = (value) => {
      if (value.includes('.')) {
        const [integerPart, decimalPart] = value.split('.');
        if (decimalPart.length > 5) {
          return `${integerPart}.${decimalPart.slice(0, 5)}`;
        }
      }
      return value;
    };
    
    const handleProductChange = (index, field, value) => {
      const updatedProducts = [...products];
      if (field === 'unitPrice') {
        value = restrictToFiveDecimals(value);
      }
    
      updatedProducts[index][field] = value;
      const isEnterDescriptionMode = value === "Enter Description" && field === "description";
      const sumOfTaxes = Object.keys(taxValues).reduce((acc, taxKey) => {
        const taxValue = parseFloat((totalQuantity * taxValues[taxKey]).toFixed(2));
        return acc + taxValue;
      }, 0);
      setTaxTotal(sumOfTaxes.toFixed(2));
      if (isEnterDescriptionMode) {
        updatedProducts[index].editableDescription = true;
        setShowDropdown(true); 
        updatedProducts[index].description = "";
      } else {
        if (field === "product") {
          const selectedProduct = productData.find(product => product.product_id === value);
    
          if (selectedProduct) {
            updatedProducts[index].productCode = selectedProduct.product_code;
            updatedProducts[index].unitPrice = selectedProduct.product_unit_price; // Keep it as string
            setProducts(updatedProducts);
            // console.log("selected product", products);
    
            setShowDropdown(false);
          } else {
            console.log("Selected Item not found in available products");
          }
        }
      }
      updatedProducts[index].quantity = parseFloat(updatedProducts[index].quantity) || 0;
      updatedProducts[index].total = updatedProducts[index].quantity * parseFloat(updatedProducts[index].unitPrice) || 0;
      if (
        field === "quantity" &&
        index === updatedProducts.length - 1 &&
        value !== ""
      ) {
        updatedProducts.push({
          No: updatedProducts.length + 1,
          product : "",
          productCode : "",
          quantity: "",
          unitPrice: "",
          total: 0,
          editableDescription: false,
        });
    
        setShowDropdown(false); // Close dropdown for the new row
      }
      const newSubtotal = updatedProducts.reduce((acc, item) => acc + item.total, 0);
      const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;
      // console.log("updated Products", updatedProducts);
      setProducts(updatedProducts);
      setSubtotal(newSubtotal);
      setTotal(newTotal);
    };

      const handleProductBlur = (index, field, value) => {
        const updatedProducts = [...products];

        if (field === 'unitPrice') {
          // Format the unit price to five decimal places on blur
          const formattedValue = parseFloat(value).toFixed(5);
          updatedProducts[index][field] = formattedValue;
        }

        // Recalculate total with the formatted value
        updatedProducts[index].total = updatedProducts[index].quantity * parseFloat(updatedProducts[index].unitPrice) || 0;

        // Recalculate subtotal and total
        const newSubtotal = updatedProducts.reduce((acc, item) => acc + item.total, 0);
        const newTotal = newSubtotal + newSubtotal * (tax / 100) + feesOrDiscounts;

        // Update state
        setProducts(updatedProducts);
        setSubtotal(newSubtotal);
        setTotal(newTotal);
      };
    
    
  
    const formatNumber = (number) => {
      if (isNaN(number)) {
        return 0;
      }
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      }).format(number);
      return formattedNumber;
    };
  
   
  
    const render = products.every(
      product =>
        (product.product === "" && product.unitPrice === "" && (product.quantity === "" || isNaN(product.quantity)) && product.product === "")
        ||
        (product.product !== "" && product.unitPrice !== "" && (product.quantity !== "" && !isNaN(product.quantity)) && product.product !== "")
    );
    // console.log("render " , render);

    const showChildRecord = products.every(
      product =>
        (product.product === ""  && (product.quantity === "" || isNaN(product.quantity)) && product.product === "")
        ||
        (product.product !== ""  && (product.quantity !== "" && !isNaN(product.quantity)) && product.product !== "")
    );
    // console.log("chil record" , showChildRecord);

  return (
    <div className="pt-8 pb-5">
    <div className="max-w-7xl mx-8 bg-white rounded-box p-10 rounded-xl">
        <div className="flex products-center justify-between w-full">
            <h2 class="mb-10 text-2xl font-bold text-gray-900 ">
              Create New Invoice
            </h2>

        <div className="flex items-center">
            <label
              for="invoice_number"
              className="  text-base font-bold text-gray-900 w-28 mr-2"
            >
              Invoice #
            </label>
            <input
              readOnly
              id="invoice_number"
              name="invoice_number"
              type="text"
              value={latestInvoiceNumber}
              onChange={(e)=>{setLatestInvoiceNumber(e.target.value)}}
              placeholder="Enter Invoice Number"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            />
        </div>

        </div>

      <form action="#" 
      // onSubmit={handleSubmit}
      >
         <div className="grid gap-4 mb-4 sm:grid-cols-2">

          <div>
            <label
              for="bill_from"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Bill From
            </label>
            <input
              id="bill_from"
              type="text"
              name="bill_from"
              required
              value={formField.bill_from}
              onChange={handleChange}
              placeholder="Enter From details"
              className="bg-gray-200 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              readOnly
            />
          </div>

          <div className="">
          <label
            htmlFor="created_on"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Delivery date 
          </label>

          <div 
            className="h-11 text-gray-700 font-normal border-gray-300 cursor-pointer border rounded-lg flex products-center justify-between"
            onClick={handleDeliveryDateClick} // Add event handler to the div
          >
          <div ref={deliveryDatePickerRef}>
            <DatePicker
              id="created_on"
              name="created_on"
              selected={createdDate}
              required
              minDate={new Date()}
              onChange={handleDeliveryDateChange}
              dateFormat="MM/dd/yyyy"        
              placeholderText="From"
              className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
              onKeyDown={(e) => {e.preventDefault();}}
            />
            </ div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 mr-2 mt-3"
            >
              <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
              <path
                fillRule="evenodd"
                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          </div>

          <div>
            <label
              for="bill_to"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Bill To
            </label>
            <select
              id="bill_to"
              name="bill_to"
              required
              value={formField.bill_to}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Customer</option>
              {customerOptions.map((customer) => (
                <option
                  key={customer.customer_id}
                  value={customer.customer_id}
                >
                  {customer.business_name}
                </option>
              ))}
            </select>
          </div>
          

          <div>
            <label
              htmlFor="due_on"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Due Date
            </label>

            <div
                className="h-11 font-normal border-gray-300 cursor-pointer border rounded-lg flex products-center justify-between"
                onClick={handleOuterDivClick}
              >
                <div ref={datePickerRef}>
                  <DatePicker
                    name="due_on"
                    id="due_on"
                    dateFormat="MM/dd/yyyy"
                    required
                    minDate={new Date()}
                    onKeyDown={(e) => {e.preventDefault();}}
                    selected={dueDate}
                    onChange={handleDueDateChange} 
                    placeholderText="To"
                    className="w-40 outline-none border-none rounded-r-lg cursor-pointer focus:outline-none focus:ring-0 bg-transparent"
                  />
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5 mr-2 mt-3"
                >
                  <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                  <path
                    fillRule="evenodd"
                    d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
            </div>

          </div>


          <div>
            <label
              for="vehicleNumber"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Select Truck
            </label>
            <select
              id="vehicleNumber"
              name="vehicleNumber"
              required
              value={formField.vehicleNumber}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Truck</option>
              {vehiclesNames.map((vehicles) => (
                <option
                  key={vehicles.id}
                  value={vehicles.id}
                >
                  {vehicles.truck_company_name}
                </option>
              ))}
             
            </select>
          </div>

          {/* <div className='grid gap-4 mb-4 sm:grid-cols-2'> */}
          <div className=''>

              <div>
              <label
                  for="originalTerminal"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Origin Terminal
                </label>
                <select
                  id="originTerminal"
                  name="originTerminal"
                  required
                  value={formField.originTerminal}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "

                  // required
                  // className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Terminal</option>
                  {originTerminal.map((terminal)=>(
                    <option key={terminal.id} value={terminal.id}>{terminal.terminal_name}</option>
                  ))}
                
                </select>
              </div>

             

          </div>
          <div>
            <label
              for="sellersId"
              className="block mb-2 text-base font-medium text-gray-900 "
            >
              Select Seller
            </label>
            <select
              id="sellersId"
              name="sellersId"
              required
              value={formField.sellersId}
              onChange={handleChange}
              // required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
            >
              <option value="">Select Seller</option>
              {sellerNames.map((seller) => (
                <option
                  key={seller.id}
                  value={seller.id}
                >
                  {seller.seller_name}
                </option>
              ))}
             
            </select>
          </div>
           <div>
              <label
                  for="bill_to"
                  className="block mb-2 text-base font-medium text-gray-900 "
                >
                  Destination Terminal
                </label>
                <select
                  id="destinationTerminal"
                  name="destinationTerminal"
                  required
                  value={formField.destinationTerminal}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                >
                  <option value="">Select Terminal</option>
                  {destinationTerminal.map((terminal)=>(
                    <option key={terminal.id} value={terminal.id}>{terminal.terminal_name}</option>
                  ))}
                
                </select>
              </div>
        
        </div>

            {/*From here invoice products*/}

        <div className="mb-4">
          <h3 className="text-lg font-bold underline mb-2 mt-10 text-gray-900 ">
            Invoice Products
          </h3>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th style={{ width: "50px" }} className="pt-2 pb-2 pl-0 font-semibold text-base">#</th>
                  {/* <th style={{ width: "150px" , marginLeft : "" }}  className="p-2 font-semibold pr-5 text-base">Product</th> */}
                  <th style={{ width: "150px" }}  className="p-2 font-semibold pr-2 text-base">Product</th>
                  {/* <th style={{ width: "200px" }}  className="p-2 font-semibold pr-10 text-base">Item Description</th> */}
                  <th style={{ width: "60px" ,marginLeft: "50px" }} className="p-2 font-semibold text-base">Quantity</th>
                  <th style={{ width:"px" , marginLeft: "110px" , position:"absolute"  }} className="p-2 font-semibold text-base">Unit Price</th>
                  <th style={{ width : "70px",  marginRight: "-10px"}} className="p-2 font-semibold text-base">Total</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <>
                  <tr key={index}>
                    <td className="p-2 pl-4">{index + 1}</td>

                    <td className="p-2">
  {/* Product Selection Dropdown */}
  <select
    onChange={(e) => handleProductChange(index, "product", e.target.value)}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
    style={{ width: "270px", marginLeft: "12px" }}
    value={products[index]?.product}
  >
    <option value="">Select Product</option>
    {productData?.map((selectedProduct) => {
      const isSelected = products.some(
        (product) => product.product === selectedProduct.product_id
      );

      return (
        (!isSelected || products[index]?.product === selectedProduct.product_id) && (
          <option key={selectedProduct.product_id} value={selectedProduct.product_id}>
            {selectedProduct.product_name}
          </option>
        )
      );
    })}
  </select>
</td>

<td className="p-2">
  {/* Quantity Input */}
  <input
    type="number"
    value={products[index]?.quantity || ""}
    placeholder="Enter Quantity"
    onChange={(e) => {
      const inputValue = e.target.value;
      const parsedValue = inputValue;

      if (!isNaN(parsedValue)) {
        handleProductChange(index, "quantity", parsedValue);
      } else {
        handleProductChange(index, "quantity", "");
      }
    }}
    className={`border border-gray-300 text-gray-900 text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1 ${
      products[index]?.product ? "bg-white" : "bg-gray-200"
    }`}
    style={{ width: "270px", marginLeft: "10px" }}
    disabled={!products[index]?.product} // Disable if no product is selected
  />
</td>

<td className="p-2">
  {/* Unit Price Input */}
  <div className="relative">
    <span
      className="absolute top-1/2 transform -translate-y-1/2 text-gray-500 p-1 pointer-events-none"
      style={{
        left: "11px",
        width: "20%",
        backgroundColor: "#e5e7eb", // Gray background if disabled
      }}
    >
      0.00
    </span>
    <input
  type="number"
  id="number"
  name="number"
  value={products[index]?.unitPrice || ""}
  placeholder="Enter Unit Price"
  onChange={(e) => handleProductChange(index, "unitPrice", e.target.value)}
  onBlur={(e) => handleProductBlur(index, "unitPrice", e.target.value)}
  className={`pl-12 border border-gray-300 text-gray-900 text-base text-right rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full p-1 ${
    products[index]?.quantity && products[index]?.product ? "bg-white" : "bg-gray-200"
  }`}
  style={{ width: "270px", marginLeft: "10px" }}
  disabled={!products[index]?.quantity || !products[index]?.product}
/>

  </div>
</td>

                      {/* <td className="p-2">  
                        <input
                          type="number"
                          id="number"
                          name="number"
                          value={product.unitPrice}
                          readOnly
                          placeholder="Enter unit price"
                          onChange={(e) =>
                            handleProductChange(index, "unitPrice", e.target.value)
                          }
                          onBlur={(e) =>
                            handleProductBlur(index, "unitPrice", e.target.value)
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 block p-1"
                          style={{
                            width: "270px",
                            marginLeft: "10px"
                          }}
                        />
                      </td> */}

                    <td className="flex justify-end p-2 mr-1" style={{ marginLeft: "" }}>
                        {/* {isNaN(product.total) ? "0.00" : product.total.toFixed(2)} */}
                        {isNaN(product.total) ? "0.00000" : product.total.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}
                    </td>
                  </tr>
                  </>
                ))}
              </tbody>
            </table>

              <hr />
                <div className="total-info flex space-x-8 justify-end font-bold mr-3 mt-2 mb-2">
                  {/* <p>Total Quantity: {totalQuantity.toFixed(2)}</p> */}
                  <p>Total Quantity: {Number(totalQuantity).toLocaleString()}</p>
                  {/* <p>Total Price: {totalPrice}</p> */}
                  {/* <p>Sub Total: {subTotal.toFixed(2)}</p> */}
                  <p>Sub Total: {subTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}
                  </p>
                </div>
              
                {products.some(item => item.quantity !== "" && !isNaN(item.quantity)) && (
                   <div className="">
                     <div className="p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500">

                      <div className="flex justify-end items-end mr-0.5" style={{fontSize: "15px"}}>
                            <div className='flex flex-col space-y-2 '>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Freight :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Freight surcharge :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Federal excise tax :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">Federal oil spil :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS prepaid :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS excise :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS petroleum bus rcvry :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS petroleum testing fee :</label>
                                <label htmlFor="tax1" className="font-medium text-gray-700">NYS spill recovery:</label>
                            </div>

                            <div className='flex flex-col space-y-2 ml-8 text-right'>
                              {/* <div> {(totalQuantity*taxValues.freight).toFixed(2)}</div>
                              <div> {((totalQuantity*taxValues.freight).toFixed(2)* taxValues.freightSurcharge).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.FederalExciseTax).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.FederalOilSpil).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.NysPrepaid).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.NysExcise).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumBusRcvry).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumTestingFee).toFixed(2)}</div>
                              <div> {(totalQuantity*taxValues.NysSpillRecovery).toFixed(2)}</div> */}
                              <div>{(totalQuantity * taxValues.freight).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}
                              </div>
                              <div> {((totalQuantity*taxValues.freight).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })* taxValues.freightSurcharge).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.FederalExciseTax).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.FederalOilSpil).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysPrepaid).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysExcise).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumBusRcvry).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysPetroleumTestingFee).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                              <div> {(totalQuantity*taxValues.NysSpillRecovery).toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</div>
                            </div>
                      </div>
                  

                <hr />

                <div className='flex justify-end space-x-5 pt-1 pb-1 pr-0.5'>
                    <b><span>Total tax : { formatNumber(taxTotal)}</span></b>
                </div>
                <hr></hr>

                <div className='flex justify-end space-x-5 pt-1 pb-1 pr-0.5'>
                  {/* <b><span>Invoice Amount: ${grandTotal.toFixed(2)}</span></b> */}
                  <b><span>Invoice Amount: ${grandTotal.toLocaleString(undefined, { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</span></b>
                </div>
                <hr></hr>

         <div className="flex flex-col items-end justify-end pt-2 pb-2 space-x-4 space-y-1">
      {/* Surcharge Box */}
      <div className="bg-gray-50 rounded-lg border border-gray-300 p-2 w-72">
        <h3 className="text-base font-semibold text-center mb-1">Surcharge</h3>
        <div className="flex items-center space-x-2 mb-2">
          <div className="flex flex-col space-y-[-10px]">
            <div className="flex items-center">
              <Checkbox
                checked={surchargeType === "percentage"}
                onChange={() => handleSurchargeTypeChange("percentage")}
                id="surchargePercentage"
              />
              <label htmlFor="surchargePercentage" className="ml-2">
                Percentage
              </label>
            </div>
            <div className="flex items-center">
              <Checkbox
                checked={surchargeType === "amount"}
                onChange={() => handleSurchargeTypeChange("amount")}
                id="surchargeAmount"
              />
              <label htmlFor="surchargeAmount" className="ml-2">
                Amount
              </label>
            </div>
          </div>
          <div className="flex items-center">
            <input
              type="number"
              value={surchargeType === "percentage" ? surchargePercentage : surchargeAmount}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 px-2 h-[55px] w-32"
              onChange={surchargeType === "percentage" ? handleSurchargePercentageChange : handleSurchargeAmountChange}
              placeholder={surchargeType === "percentage" ? "Surcharge %" : "Surcharge $"}
            />
          </div>
        </div>
      </div>

      {/* Discount Box */}
      <div className="bg-gray-50 rounded-lg border border-gray-300 p-2 w-72">
        <h3 className="text-bold font-semibold text-center mb-">Discount</h3>
        <div className="flex items-center space-x-2 mb-2">
          <div className="flex flex-col space-y-[-10px]">
            <div className="flex items-center">
              <Checkbox
                checked={discountType === "percentage"}
                onChange={() => handleDiscountTypeChange("percentage")}
                id="discountPercentage"
              />
              <label htmlFor="discountPercentage" className="ml-2">
                Percentage
              </label>
            </div>
            <div className="flex items-center">
              <Checkbox
                checked={discountType === "amount"}
                onChange={() => handleDiscountTypeChange("amount")}
                id="discountAmount"
              />
              <label htmlFor="discountAmount" className="ml-2">
                Amount
              </label>
            </div>
          </div>
          <div className="flex items-center">
            <input
              type="number"
              value={discountType === "percentage" ? discountPercentage : discountAmount}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-right text-base rounded-md focus:ring-primary-600 focus:border-primary-600 px-2 h-[55px] w-32"
              onChange={discountType === "percentage" ? handleDiscountPercentageChange : handleDiscountAmountChange}
              placeholder={discountType === "percentage" ? "Discount %" : "Discount $"}
            />
          </div>
        </div>
      </div>
    </div>



              <hr></hr>
              <div className='flex justify-end space-x-5 pt-1 pb-1 pr-0.5'>
                {/* <b><span>Final invoice Amount: ${finalInvoiceAmount.toFixed(2)}</span></b> */}
                <b><span>Final invoice Amount: ${finalInvoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></b>
              </div>

              </div>
                   </div>
                 )}
            </div>



       
        <div className="sm:col-span-2 mb-8">
          <label
            for="description"
            className="block mb-2 text-base font-medium text-gray-900 "
          >
            Comments
          </label>
          <textarea
            id="description"
            rows="4"
            name="description"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
            placeholder="Write your Comments here..."
            maxLength={100}
          ></textarea>
        </div>
        <div className="flex justify-between items-center">
          <div className="border-2 border-[#111FFF] py-2 px-5 w-[550px]">
            <h1 className="font-semibold text-blue-500 underline underline-offset-4">
              Terms & Conditions
            </h1>
            <textarea
              id="termsAndConditions"
              rows="4"
              name="termsAndConditions"
              value={termsAndConditions}
              onChange={(e) => setTermsAndConditions(e.target.value)}
              className="block p-2.5 mt-3 mb-3 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Add your Terms & Conditions here..."
              maxLength={100}
            ></textarea>
          </div>

        




          
              <div>
  {/* Your code for displaying each item */}
  
  {/* Conditionally render the buttons if any item meets the conditions */}
            {shouldRenderButtons && isFormValid && (
              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => navigate(`/invoice`)}
                  type="button"
                  className="hover:bg-red-500 border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-red-500/60 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                
                >
                  Cancel
                </button>
                <button
                 onClick={handleSubmit}
                  type="submit"
                  className="hover:bg-[#0044AB] border-[#3479E0] border-2 text-black px-6 py-1.5 text-base font-semibold hover:border-transparent hover:shadow-lg hover:shadow-[#0044AB]/50 text-center hover:text-white rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800"
                  // disabled={!isFormValid}
                >
                  Done
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
    </div>
  )
}

export default InvoiceFormTwo