import React from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  createColumnHelper,
  getFilteredRowModel
} from "@tanstack/react-table";
import config from "../../config/config";
import { FaEye, FaTrashAlt, FaDownload } from 'react-icons/fa';
import { useState, useMemo, useEffect, useRef } from 'react';
import axios from "axios";
import { message } from "antd";
import { Modal, Button } from 'antd'; // Using Ant Design Modal
import { WrapText } from "@mui/icons-material";
import './AuditLog.css'
// import FullScreenLoader from './FullScreenLoader'
import { AiOutlineSearch } from "react-icons/ai";


import FullScreenLoader from "../pages/FullScreenLoader";
const AuditLogTable = () => {
  const [AuditResponse, setAuditResponse] = useState([]);
  const businessId = localStorage.getItem("business_id")
  const managerId = localStorage.getItem("user_id")
  const baseURL = process.env.NODE_ENV === "production" ? config.production.baseURL : config.development.baseURL;
  const [filtering , setFiltering] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}/${btoa("audit/auditlogget")}/${btoa(businessId)}/${btoa(managerId)}`);
        // Assuming the response contains the owner details in the 'owners_list' property
        setAuditResponse(response);
        console.log("Audit details:", response.data);
      } catch (error) {
        console.error('Error fetching owner details', error);
      }
    finally {
    setLoading(false);
  }
};

    fetchData();
  }, []); 

const columnHelper = createColumnHelper();
const [isModalOpen, setIsModalOpen] = useState(false);
const [loading, setLoading] = useState(false);
const [fullMessage, setFullMessage] = useState('');

const showModal = (message) => {
  console.log("showModal triggered with message:", message);  // Log message for debugging

  setFullMessage(message); // Set the full message in state
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};
function formatDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear().toString(); // Extract last two digits of year
  return `${month}-${day}-${year}`;
}


const breakText = (text, maxLength) => {
  const brokenText = [];
  for (let i = 0; i < text.length; i += maxLength) {
    brokenText.push(text.slice(i, i + maxLength));
  }
  return brokenText;
};

const columnDef = [
  {
    accessorFn: (row, index) => index + 1,  // This returns the serial number based on the index
    header: 'Sl No',
    cell: ({ row }) => (
      <div style={{ width: '40px', textAlign: 'center' }}>
        {row.index + 1}  {/* Directly access row index to display serial number */}
      </div>
    ),
  },
  
  {
    accessorKey: "entry_date",
    header: "Date",
    cell: (props) => {
      const indianDate = props.row.original.entry_date;
      const [day, month, year] = indianDate.split('/');      
      const date = new Date(`${year}-${month}-${day}`);      
  const formattedDate = `${date.toLocaleDateString("en-US", { month: 'short' })}-${date.toLocaleDateString("en-US", { day: '2-digit' })}-${date.toLocaleDateString("en-US", { year: 'numeric' })}`;

return (
        <div>{formattedDate}</div>
      );

    },
    enableColumnFilter: true, // Disable filter for checkbox column
    enableSorting: true, // Disable sorting for checkbox column
  },
  {
    accessorKey: "entry_date",
    header: "Time",
    cell: ({ row }) => {
      const entryDate = row.getValue("entry_date");
      const timePart = new Date(entryDate).toLocaleTimeString();
      return <div style={{ width: '80px', textAlign: 'left' }}>{timePart}</div>;
    }, // 15% width for Time column
  },
  {
    accessorKey: "user_name",
    header: "User Name",
    cell: ( props ) => {
   
      return <div style={{ width: '150px', textAlign: 'left' }}>{props.row.original.user_name}</div>;
    }, // 15% width for Time column
  },
  {
    accessorKey: "message",
    header: "Message",
    cell: ({ row }) => {
      const message = row.getValue("message");
    
      // Break text into 150-character chunks
      const breakText = (text, maxLength) => {
        const brokenText = [];
        for (let i = 0; i < text.length; i += maxLength) {
          brokenText.push(text.slice(i, i + maxLength));
        }
        return brokenText;
      };
    
      const brokenMessage = breakText(message, 150);
    
      return (
        <div style={{ width: '100%', textAlign: 'left', whiteSpace: 'normal', wordWrap: 'break-word' }}>
          {brokenMessage.slice(0, 2).map((line, index) => ( // Display the first 2 lines (300 characters)
            <div key={index} style={{ marginBottom: '4px' }}>
              <span>{line}</span>
            </div>
          ))}
          {message.length > 300 && ( // Show "Show More" if message length > 300
            <Button type="link" onClick={() => showModal(message)} style={{ paddingLeft: 0 }}>
              Show More
            </Button>
          )}
        </div>
      );
    },
     
  },

];




const finalData = React.useMemo(() => {
  return AuditResponse.data && AuditResponse.data.audit_data ? AuditResponse.data.audit_data : [];
}, [AuditResponse]); 

 const finalColumnDef = React.useMemo(() => columnDef, []);

  const tableInstance = useReactTable({
    columns: finalColumnDef,
    data: finalData,
    state: {  
      globalFilter: filtering,   
    },
    onGlobalFilterChange: setFiltering,
    getFilteredRowModel: getFilteredRowModel(),

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: true,
  enableColumnFilter: true,
    initialState: {
      pagination: {
        pageSize: 25,  // Set the initial page size to 25
      },
    },
  });

  const currentPage = tableInstance.options.state.pagination.pageIndex;
  const totalPages = tableInstance.getPageCount();
  
  // Determine start and end page for pagination display
  const startPage = Math.max(0, Math.min(currentPage - 1, totalPages - 3));
  const endPage = Math.min(startPage + 3, totalPages);
  const alignLeftHeaders = ["Date", "Time", "Message", "User Name"];

  //   console.log("test", tableInstance.getHeaderGroups());

  return (
    <>
  <div className="">
  {loading && <FullScreenLoader/> }
  <div 
  className="flex justify-between items-center w-full  pt-4 mt-4 mb-5 ml-5" 
  style={{ 
    // maxWidth: '1200px', 
    boxSizing: 'border-box', 
    marginLeft: '45px', 
    fontFamily: 'Poppins, sans-serif', // Use Poppins font
    color: '#444444' // Lighter black color
  }}
>
  <h1 className="font-bold text-xl mb-4"> Audit Log</h1>
</div>

<div>
<div className="flex w-full justify-end items-center space-x-4 px-12 mt-10">

<div className="relative w-44 focus-within:w-full  transition-all duration-300 max-w-full ml-auto pr-4">
              <AiOutlineSearch
                size={18}
                style={{left:'16px'}}
                className="absolute top-1/2 transform -translate-y-1/2 text-gray-700"
              />
              <input
                type="text"
                placeholder="Search..."
                className="pl-10 px-6 py-1 border-2 rounded-lg border-gray-300 outline-none block w-full focus:ring-3"
                value={filtering}
                onChange={e => setFiltering(e.target.value)}
              />
          </div>{" "}
          </div>
  {AuditResponse?.data?.audit_data?.length >0 &&

<div className="table-container w-full">
  <table
    className="table w-full bg-transparent rounded-xl overflow-hidden mt-4"
    style={{ maxWidth: '2000px', marginLeft: '25px' }}
  >
<thead className="bg-gray-500/20 text-gray-500">
  {tableInstance.getHeaderGroups().map((headerEl) => (
    <tr key={headerEl.id}>
      {headerEl.headers.map((columnEl) => {
        // Define specific width and alignment for each column
        const columnStyles = {
          'Sl No': { width: '40px', textAlign: 'left' },
          Date: { width: '100px', textAlign: 'left' },
          Time: { width: '80px', textAlign: 'left' },
          Message: { width: '500px', textAlign: 'left' },
          'User Name': { width: '150px', textAlign: 'left' },
        };

        const headerStyle = columnStyles[columnEl.column.columnDef.header];

        return (
          <th
            key={columnEl.id}
            className="p-3 text-xs font-bold uppercase"
            style={headerStyle}
          >
            {columnEl.isPlaceholder ? null : flexRender(columnEl.column.columnDef.header, columnEl.getContext())}
          </th>
        );
      })}
    </tr>
  ))}
</thead>
<tbody>
  {tableInstance.getRowModel().rows.map((rowEl) => (
    <tr key={rowEl.id} className="cursor-pointer hover:bg-gray-500/10">
      {rowEl.getVisibleCells().map((cellEl) => {
        // Reuse column styles for body cells
        const columnStyles = {
          'Sl No': { width: '40px', textAlign: 'left' },
          Date: { width: '100px', textAlign: 'left' },
          Time: { width: '80px', textAlign: 'left' },
          Message: { width: '500px', textAlign: 'left' },
          'User Name': { width: '150px', textAlign: 'left' },
        };

        const cellStyle = columnStyles[cellEl.column.columnDef.header];

        return (
          <td
            key={cellEl.id}
            className="p-4 font-semibold text-[14px] text-[#081159]"
            style={cellStyle}
          >
            {flexRender(cellEl.column.columnDef.cell, cellEl.getContext())}
          </td>
        );
      })}
    </tr>
  ))}
</tbody>

  </table>
</div>


// :(
//   <p style={{ color: 'red', fontSize: '18px', fontWeight: 'bold', textAlign: 'center', paddingTop:'20px', marginRight:'90px' }}>
//   No Data Available
// </p>)
    }
</div>
{AuditResponse?.data?.audit_data?.length > 25 && (
  <div style={paginationContainerStyle}>
    <div style={selectContainerStyle}>
      <label style={labelStyle}>Rows per page:</label>
      <select
        value={tableInstance.options.state.pagination.pageSize}
        onChange={(e) => tableInstance.setPageSize(Number(e.target.value))}
        style={selectStyle}
      >
        {[25, 50, 100].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>

    <div style={paginationControlsStyle}>
      {/* First Page Button */}
      <button
        onClick={() => tableInstance.setPageIndex(0)}
        disabled={!tableInstance.getCanPreviousPage()}
        style={buttonStyle(tableInstance.getCanPreviousPage())}
      >
        {"<<"}
      </button>

      <button
        onClick={() => tableInstance.previousPage()}
        disabled={!tableInstance.getCanPreviousPage()}
        style={buttonStyle(tableInstance.getCanPreviousPage())}
      >
        Previous
      </button>

      {/* Page Numbers */}
      <div style={pageNumbersStyle}>
        {Array.from({ length: endPage - startPage }, (_, i) => (
          <button
            key={startPage + i}
            onClick={() => tableInstance.setPageIndex(startPage + i)}
            style={pageButtonStyle(startPage + i, currentPage)}
          >
            {startPage + i + 1}
          </button>
        ))}
      </div>

      <button
        onClick={() => tableInstance.nextPage()}
        disabled={!tableInstance.getCanNextPage()}
        style={buttonStyle(tableInstance.getCanNextPage())}
      >
        Next
      </button>

      {/* Last Page Button */}
      <button
        onClick={() => tableInstance.setPageIndex(totalPages - 1)}
        disabled={!tableInstance.getCanNextPage()}
        style={buttonStyle(tableInstance.getCanNextPage())}
      >
        {">>"}
      </button>
    </div>
  </div>
)}

<Modal
        title="Full Message"
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>Close</Button>
        ]}
      >
        <div>{fullMessage}</div>
      </Modal>

      </div>     
 
    </>
  );
};

export default AuditLogTable;
const paginationContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  // alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f9f9f9', // Light background
  borderRadius: '8px', // Rounded corners
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow
};

const selectContainerStyle = {
  display: 'flex',
  // alignItems: 'center',
  gap: '10px', // Space between label and dropdown
  marginLeft:'40px',

};

const labelStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#333', // Dark text
};

const selectStyle = {
  padding: '5px 10px',
  borderRadius: '4px',
  width:'60px',
  border: '1px solid #ddd', // Light border
  cursor: 'pointer',
};

const paginationControlsStyle = {
  display: 'flex',
  alignItems: 'center',
  // gap: '8px', // Space between buttons
  marginRight:'40px'
};

const buttonStyle = (isEnabled) => ({
  backgroundColor: '#FFFFFF',
  color: '#3479E0',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 8px',
  cursor: isEnabled ? 'pointer' : 'not-allowed',
  opacity: isEnabled ? '1' : '0.6',
  transition: 'background-color 0.3s ease', // Transition for hover effect
  '&:hover': isEnabled ? { backgroundColor: '#245ea6' } : {},
});

// Page button styles based on active state
const pageButtonStyle = (index, currentIndex) => ({
  backgroundColor: index === currentIndex ? '#3479E0' : '#FFFFFF',
  color: index === currentIndex ? '#FFFFFF' : '#3479E0',
  border: '1px solid #3479E0',
  borderRadius: '50%',
  padding: '8px 16px',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease', // Transition for hover effect
  '&:hover': {
    backgroundColor: index === currentIndex ? '#245ea6' : '#f0f0f0', // Change color on hover
  },
});

const pageNumbersStyle = {
  display: 'flex',
  gap: '8px',
};
